// Sidebar Default
@import "./default_sidebar";
@import "./default_sidebar_main-content";
@import "./default_style";

// nav Rounded Active Menu Style
@import "./active_style_nav_rounded";

// Nav Rounded All Active Menu Style
@import "./active_style_nav_rounded_all";

// Nav Pill Active Menu Style
@import "./active_style_nav_pill";

// Nav Pill All Active Menu Style
@import "./active_style_nav_pill_all";

// Dark Color Style
@import "./color_style_dark";

// Primary Color Style
@import "./color_style_primary";

// Transparent Color Style
@import "./color_style_transparent";

// Sidebar Hover Style
@import "./sidebar_style_hover";

// Sidebar Mini Style
@import "./sidebar_style_mini";

// Sidebar Soft Style
@import "./sidebar_style_boxed";

// Sidebar Rounded Toggle Button
@import "./sidebar_toggle_button";

// Sidebar Responsive
@import "./default_sidebar_responsive";

.nav-pills .nav-link {
    background: none;
    border: 0;
    border-radius: 0px !important;
}
.card{
    box-shadow: none !important;
}
.card.border-bottom {
    border-bottom: 1px solid #000 !important;
    border-radius: 0px !important;
}
    #profile-pills-tab.nav-pills .nav-link{
        color: #000;
    background-color: #d9d9d9;
    padding:10px 30px;
    margin: 0px 20px;
    }
    #profile-pills-tab.nav-pills .nav-link.active{
        color: var(--bs-white);
    //background-color: var(--bs-primary);
    background: linear-gradient(70deg,var(--bs-primary) 0%, var(--bs-primary) 94%, #FFFFFF 94%, #FFFFFF 100%)
    }
    .profile-content h2.text-center {
        color: #0fa1e4 !important;
    }
    .border_add{
        border:1px solid #ccc;
    }
    .tab-pane label.form-label {
        
    }
    .form-select {
        border: 1px solid #dee2e6 !important;
    }
