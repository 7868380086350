.color_pink{
  color: #e01d8d;
}
.iq-timeline0 li .timeline-dots{
  top:0px;
  border-color: #e01d8d !important;
  background: rgb(224,29,141) !important;
  background: radial-gradient(circle, rgb(224, 29, 141) 0%, rgba(224, 29, 112) 100%) !important;
  /* height: 25px !important; */
  /* width: 25px !important; */
  border-radius: 50% !important;
  left: 2px !important;
}

.card.border_add {
  border-radius: 0px !important;
  .btn.btn-info {
      color: var(--bs-white);
      background-color: #385723 !important;
      border-color: #385723 !important;
      border-radius: 0px !important;
  }
  .btn.btn-info1 {
      color: var(--bs-white);
      font-size: 40px;
      background: rgb(224,29,141) !important;
      background-image: linear-gradient(to right top, #fc0792, #e30583, #cb0375, #b40167, #9d0059) !important;
      border-radius: 0px !important;
      font-weight: bold !important;
      width: 100%;
  }
  .text-size-h2{
      font-size: 20px;
  }
}
.gap-1{
  gap:10px;
}
.checkin .card-header {
  background-color: #385723 !important;
  border-radius: 0px !important;
 
  h5.card-title.text-center, h6.p-2.text-center {
       color:#fff !important;
      }
      h6.p-2.text-center{
          border-top:3px solid #64894a;
          border-bottom: 3px solid #64894a;
      }
     
}
.checkin_body p{
  color:#64894a !important;
  line-height: 16px;
  font-size: 14px !important;
}
.card.make_demo {
  .btn.btn-info1 {
      color: var(--bs-white);
      font-size: 25px;
      background-color: #e30583 !important;
      border-radius: 0px !important;
      width: 100%;
      border: 1px solid #000;
  }
 
}
.bg-soft-info1 {
  color: #fff;
  background-color: #385723 !important;
  border: 1px solid #000;
  border-radius: 0px !important;
  font-size: 25px !important;
  font-weight: bold;
  min-height: 256px;
}
.card.remarks{
  background-color: #0fa1e4;
  color: #fff;
  .form-label{
      color: #fff;
  }
}
.btn.btn-info2 {
  color: #385723 !important;
  border: 3px solid #385723;
  font-size: 20px;
  background: transparent !important;
  border-radius: 0px !important;
  font-weight: bold !important;
  width: 100%;
}
.btn.btn-info3 {
  color: #5d4fb3 !important;
  border: 3px solid #5d4fb3;
  font-size: 30px;
  background: transparent !important;
  border-radius: 0px !important;
  font-weight: bold !important;
  width: 100%;
  margin-top: -29px;
}
  .card.distributor.bg-soft-danger1 {
      color: #000;
      background-color: #b5b4b4!important;
      border-radius: 0px !important; 
      h6.bold {
          font-weight: bold;
      }
      .d-flex.justify-content-between.align-items-center {
          font-size: 12px;
      }
  }  
  .card.distributor.bg-soft-danger1 {
      margin-bottom: 0px;
  }
  #basic-table th {
      font-weight: bold;
      color: #000;
  }
  .table > :not(caption) > * > * {
      padding: 10px;
      font-size: 14px !important;
  }
  .table {
      overflow: hidden;
      // border: 1px solid #ccc;
  }
  li#overall a{
      color:#fff;
      background-color: #548235 !important;
  }
  #overall.nav-pills .nav-link{
      color:#fff !important;
      background-color: #548235 !important;
  }
  li.orderbooks_nav a {
      color: #fff !important;
      background-color: #548235 !important;
  }
  .card.order_book #profile-pills-tab.nav-pills .nav-link {
      background-color: #fff !important;
      border: 2px solid #548235 !important;
  }
  #orderbooks-pills-tab.nav-pills .nav-link{
      color: #000;
  background-color: #d9d9d9;
  padding:10px 30px;
  margin: 0px 20px;
  }
  #orderbooks-pills-tab.nav-pills .nav-link.active{
      background-color: #548235 !important;
color: #fff!important;
  }
  .card.order_book #orderbooks-pills-tab.nav-pills .nav-link {
      background-color: #fff;
      border: 2px solid #548235 !important;
  }
  .card.orderbook.bg-soft-danger2{
      color: #fff;
      background-color: #548235 !important;
      border-radius: 0px !important;
  }
  .card.orderbooks-today {
      background-color: #548235 !important;
      border-radius: 0px !important;
      color:#fff !important;
    h2.counter span {
      color:#fff !important;
      }
      h4.counter span {
          color:#fff !important;
          }
          .progress-bar.bg-info4 {
              background-color: #92d050 !important;
              border-radius: 0px !important;
          }
  }
  .col-md-8.target {
      background-color: #fff;
      color: #000;
      padding: 10px;
      margin: 0px 10px;
  }
  .card
  {
      .order-invoice {
      border: 4px solid #548235;
      border-radius: 0px !important;
      padding: 15px 0px;
      .table > tbody{
          border: 1px solid #000 !important;
margin-bottom: 10px !important;
      }
      .table thead tr th{
          color: #000;
          font-weight: bold;
      }
      .table thead {
          white-space: nowrap;
          border: 1px solid #000 !important;
          margin-bottom: 10px !important;
      }
      .btn.btn-primary1{
background-color: #FFBF00 !important;
color:#fff;
      }
      .invoice_address p{
          color: #000;
          line-height: 20px;
       }
       p.to_add {
          line-height: 20px !important;
      }
      h5 span{
          color: #0fa1e4 !important;
      }
      .invoice_green h6 span{
          color: #548235 !important; 
      }
      .order {
          border-bottom: 3px solid #548235;
      }
      .bold{
          font-weight: bold;
      }
      .order_to{
          border-bottom: 3px solid #548235;
      }
      .button_add {
          color: #548235 !important;
          font-size: 25px;
          padding-left: 10px;
        }
      .button_minus {
        color: #ccc !important;
        font-size: 25px;
        padding-right: 10px;
      }
  }
  }
  .invoice_green .primary_green {
      color: #548235 !important; 
      font-size: 1rem !important;
  }

.side-navigation-panel
  .side-navigation-panel-select
  .side-navigation-panel-select-wrap
  .side-navigation-panel-select-option:hover {
  background-color: #fff !important;
  border-color: #fff !important;
  color: #e01d8d !important;
}
.side-navigation-panel
  .side-navigation-panel-select
  .side-navigation-panel-select-wrap
  .side-navigation-panel-select-option-selected {
  background-color: #fff !important;
  border-color: #fff !important;
  color: #e01d8d !important;
}
.side-navigation-panel *,
.side-navigation-panel:after,
.side-navigation-panel:before {
  border-color: #e2e8f0;
  border-style: solid;
  font-weight: 600 !important;
  border-width: 0;
  box-sizing: border-box;
}
#profile-pills-tab.nav-pills .nav-link.active {
  color: #fff !important;
  background: #e01d8d !important;
}
#profile-pills-tab.nav-pills .nav-link:hover {
  color: #fff !important;
  background: #e01d8d !important;
}
#profile-pills-tab.nav-pills .nav-link :focus {
  color: #fff !important;
  background: #e01d8d !important;
}
.card-header,
.card-title {
  text-transform: capitalize !important;
  color: rgba(18, 21, 78, 0.7);
  font-weight: bold;
  font-size: 0.88rem;
}
small.float-right.mt-1 {
  text-align: left !important;
  float: left;
}
.checkin-tab tbody tr {
  background-color: #fff;
  // border: 1px solid #ccc !important;
  margin: 9px !important;
}
.checkin-tab .table-striped > tbody > tr:nth-of-type(even) > * {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: #000;
}
.checkin-tab .table thead tr {
  background-color: #fff !important;
}
#basic-table th {
  font-weight: bold;
  color: #000;
  padding: 20px 10px;
}
.sidebar {
  width: 180px;
}

@media (min-width: 768px) {
  .main {
    padding-right: 40px;
    padding-left: 220px; /* 180 + 40 */
  }
  
}
.main-content .content-inner {
  min-height: calc(100vh - 6.6rem);
}
.content-inner {
  padding: calc(var(--bs-gutter-x, 0.75rem) * 1);
}
.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.checkin .card-header {
  background-color: #385723 !important;
  border-radius: 0px !important;
  height: auto;
  h5.card-title {
    font-size: 20px;
  }
  h5.card-title.text-center,
  h6.p-2.text-center {
    color: #fff !important;
  }
  h6.p-2.text-center {
    border-top: 3px solid #64894a;
    border-bottom: 3px solid #64894a;
  }
}
.checkin_body p {
  color: #64894a !important;
  line-height: 16px;
  font-size: 14px !important;
}
.card.make_demo {
  .btn.btn-info1 {
    color: var(--bs-white);
    font-size: 25px;
    background-color: #e30583 !important;
    border-radius: 0px !important;
    width: 100%;
    border: 1px solid #000;
  }
}
.bg-soft-info1 {
  color: #fff;
  background-color: #385723 !important;
  border: 1px solid #000;
  border-radius: 0px !important;
  font-size: 25px !important;
  font-weight: bold;
  min-height: 256px;
}
.card.remarks {
  background-color: #0fa1e4;
  color: #fff;
  .form-label {
    color: #fff;
  }
}
.btn.btn-info2 {
  color: #385723 !important;
  border: 3px solid #385723;
  font-size: 20px;
  background: transparent !important;
  border-radius: 0px !important;
  font-weight: bold !important;
  width: 100%;
}
.btn.btn-info3 {
  color: #5d4fb3 !important;
  border: 3px solid #5d4fb3;
  font-size: 30px;
  background: transparent !important;
  border-radius: 0px !important;
  font-weight: bold !important;
  width: 100%;
  margin-top: -29px;
}
.card.distributor.bg-soft-danger1 {
  color: #000;
  background-color: #b5b4b4 !important;
  border-radius: 0px !important;
  h6.bold {
    font-weight: bold;
  }
  .d-flex.justify-content-between.align-items-center {
    font-size: 12px;
  }
}
.card.distributor.bg-soft-danger1 {
  margin-bottom: 0px;
}
#basic-table th {
  font-weight: bold;
  color: #000;
}
.table > :not(caption) > * > * {
  padding: 10px;
  font-size: 14px !important;
}
.table {
  overflow: hidden;
  // border: 1px solid #ccc;
}
li#overall a {
  color: #fff;
  background-color: #548235 !important;
}
#overall.nav-pills .nav-link {
  color: #fff !important;
  background-color: #548235 !important;
}
li.orderbooks_nav a {
  color: #fff !important;
  background-color: #548235 !important;
}
.card.order_book #profile-pills-tab.nav-pills .nav-link {
  background-color: #fff !important;
  border: 2px solid #548235 !important;
}
#orderbooks-pills-tab.nav-pills .nav-link {
  color: #000;
  background-color: #d9d9d9;
  padding: 10px 30px;
  margin: 0px 20px;
}
#orderbooks-pills-tab.nav-pills .nav-link.active {
  background-color: #548235 !important;
  color: #fff !important;
}
.card.order_book #orderbooks-pills-tab.nav-pills .nav-link {
  background-color: #fff;
  border: 2px solid #548235 !important;
}
.card.orderbook.bg-soft-danger2 {
  color: #fff;
  background-color: #548235 !important;
  border-radius: 0px !important;
}
.card.orderbooks-today {
  background-color: #548235 !important;
  border-radius: 0px !important;
  color: #fff !important;
  h2.counter span {
    color: #fff !important;
  }
  h4.counter span {
    color: #fff !important;
  }
  .progress-bar.bg-info4 {
    background-color: #92d050 !important;
    border-radius: 0px !important;
  }
}
.col-md-8.target {
  background-color: #fff;
  color: #000;
  padding: 10px;
  margin: 0px 10px;
}

.card {
  .order-category {
    .category-info {
      color: black !important;
      font-weight: 700;
    }
    .category-data {
      color: #0fa1e4 !important;
      font-weight: 700;
    }
  }
}
.card {
  .order-invoice-footer {
    color: #000;
  }
}
.card {
  .order-invoice {
    border: 4px solid #548235;
    border-radius: 0px !important;
    padding: 15px 0px;
    .table > tbody {
      border: 1px solid #000 !important;
      margin-bottom: 10px !important;
    }
    .table thead tr th {
      color: #000;
      font-weight: bold;
    }
    .table thead {
      white-space: nowrap;
      border: 1px solid #000 !important;
      margin-bottom: 10px !important;
    }
    .btn.btn-primary1 {
      background-color: #FFBF00 !important;
      color: black;
      font-weight: 700;
      border-radius: 8px;
    }
    .btn.btn-danger {
      font-weight: 700;
      border-radius: 8px;
    }
    .invoice_address p {
      color: #000;
      line-height: 20px;
    }
    p.to_add {
      line-height: 20px !important;
    }
    h5 span {
      color: #0fa1e4 !important;
    }
    .invoice_green h6 span {
      color: #548235 !important;
      font-weight: 600;
    }
    .invoice_address p b {
      color: black !important;
      font-weight: 700;
    }
    .order {
      border-bottom: 3px solid #548235;
    }
    .order_to {
      border-bottom: 3px solid #548235;
    }
    .button_add {
      color: #548235 !important;
      font-size: 25px;
      padding-left: 10px;
    }
    .button_minus {
    color: #ccc !important;
    font-size: 25px;
    padding-right: 10px;
    }
  }
}
.invoice_green .primary_green {
  color: #548235 !important;
  font-size: 1rem !important;
  font-weight: 600;
}
// button.btn.btn-secondary {
//   background-color: green;
//   border: 1px solid green;
//   font-weight: 700;
//   border-radius: 8px;
// }
table.invoice.table thead tr {
 
  border: 1px solid #ccc !important;
  margin: 9px !important;
}
td.text-center.prod-wid {
  width: 180px !important;
  text-wrap: wrap;
}
td.text-left.prod-wid {
  width: 180px !important;
  text-wrap: wrap;
}
.btn-primary-green {
  color: var(--bs-white);
  background-color: #18aa00 !important;
  border: 1px solid #18aa00;
}
.btn-primary-green:hover {
  color: #fff;
}
.border_add {
  border: 5px solid #002060;
  border-radius: 0px !important;
}
.border_add1 {
  border: 1px solid #ccc;
  border-radius: 0px !important;
}
.update_bg {
  background-color: #0fa1e4;
  padding: 10px;
  border-radius: 0px !important;
  .form-label {
    color: #fff !important;
  }
}
.new_task_bg {
  background-color: #002060;
  padding: 10px;
  border-radius: 0px !important;
  .form-label {
    color: #fff !important;
  }
}
.no-bold{
  font-weight: 100 !important;
  color:#000 !important
}
.card_height {
}
.card_height1 {
  overflow-y: scroll;
}
.card_height2 {
   
  }

.btn.btn-info4 {
  color: #5d4fb3 !important;
  border: 3px solid #5d4fb3;
  font-size: 18px;
  background: transparent !important;
  border-radius: 0px !important;
  font-weight: bold !important;
  width: 100%;
}
.card.border_add .btn.btn-info1 {

}
.card.border_add .text-size-h2 {
  font-size: 10px;
}
label.control-label.align-self-center.mb-0.form-label.col-form-label.col-sm-5 {
  font-size: 14px !important;
  color: #000;
  padding-top: 3px;
  padding-bottom: 3px;
}
.place h3.text-center {
  color: #0fa1e4;
  font-size: 24px;
  margin: 10px;
}
.light_blue {
  color: #0fa1e4;
}
.dark_blue {
  color: #002060;
}
.color_orange {
  color: #c45911;
}
.c_red {
  color: #843c0c;
}
.card.border_add .btn.bg_pink {
  color: var(--bs-white);
  font-size: 27px;
  background-color: #e01d8d;
  border-radius: 0px !important;
  width: 100%;
}
.btn.btn-checkout {
  color: #fff !important;
  border: 1px solid #843c0c;
  font-size: 20px;
  background: #843c0c !important;
  border-radius: 0px !important;
  font-weight: bold !important;
  width: 100%;
}
.btn.btn-visit {
  color: #fff !important;
  border: 1px solid #2e75b6;
  font-size: 16px;
  background: #2e75b6 !important;
  border-radius: 0px !important;
  font-weight: bold !important;
  width: 100%;
}
.border_image {
  border: 1px solid #ccc;
}
form.top-view .form-label {
  color: #e01d8d;
  font-weight: 700;
  font-size: 16px;
}
.btn.btn-green1 {
  width: 150px !important;
  background-color: #548235 !important;
  border-radius: 0px !important;
  border: 1px solid #548235;
}

.btn.btn-pending {
  width: 200px !important;
  box-shadow: inset 0px 20px 6px rgba(255, 191, 0, 0.1607843137);
  background: #FF000038;
  border: 1px solid #FF0000;
  border-radius: 17px;
  opacity: 0.63;
  color: black;
}

.btn.btn-completed {
  width: 200px !important;
  box-shadow: inset 0px 20px 6px rgba(255, 191, 0, 0.1607843137);
  background: rgb(127 233 55 / 56%);
  border: 1px solid #548235;
  border-radius: 17px;
  opacity: 0.63;
  color: black;
}

.btn.btn-saved {
  width: 200px !important;
  box-shadow: inset 0px 20px 6px rgba(255, 191, 0, 0.1607843137);
  background: #FFBF0029;
  border: 1px solid #FFBF00;
  border-radius: 17px;
  opacity: 0.63;
  color: black;
}
.home.card_height {
  height: 700px;
}
.border-bottom {
  border-bottom: 1px solid #000 !important;
}
.sidebar {
  position: relative;
  top: 0;
  width: 250px;
  height: 100%;
  box-sizing: border-box;
  padding: 10px;
  background: white;
}
.sidebar .menu a {
  display: block;
  margin-bottom: 10px;
  font-size: 1.2rem;
  text-decoration: none;
  color: black;
}
.close-icon {
  position: absolute;
  right: 15px;
  top: 10px;
  text-decoration: none;
  font-size: 1.8rem;
  color: #000;
}
.open-icon {
  position: absolute;
  left: 0px;
  top: 10px;
  text-decoration: none;
  font-size: 1.8rem;
  font-weight: 800;
  padding: 2px 5px;
  color: #000;
}
.hide {
  transition: left 0s linear;
  // left: -260px;
  left: 0px;
  
}
.show {
  transition: left 0s linear;
  left: 0px;
}
  .side-navigation-panel .side-navigation-panel-select .side-navigation-panel-select-wrap .side-navigation-panel-select-option {
  --tw-text-opacity: 1;
  align-items: center;
  border-left-width: 0px !important;
}
nav {
  margin-top: 1.5rem;
}
.round {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: green;
  border: none;
  cursor: pointer;
  outline: none;
  color: #fff;
  font-size: 14px;
}
.sidebar.hide {
  width: 65px !important;
  transition: right 0.8s linear !important;
  span.side-navigation-panel-select-option-text 
  {
    display: none !important;
}
.close-icon{
  display: none !important;
}
.hello {
  display: none !important;
}
.usrname{
  display: none !important; 
}
.menu_logo {
  display: none !important;
}
.open-icon{
  margin-top: -4px !important;
  margin-left: 13px !important;
}
nav.side-navigation-panel {
  margin-top: 0px;
}
}
.sidebar.show {
  .open-icon{
    display: none !important;
  }
}
.side-navigation-panel .side-navigation-panel-select .side-navigation-panel-select-wrap .side-navigation-panel-select-option {
  --tw-text-opacity: 1;
  align-items: center;
  border-left-width: 2px;
  color: rgba(55,65,81,var(--tw-text-opacity));
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: 10px;
  width: 100%;
}
a.open-icon.round:hover {
  color: #fff !important;
}
.side-navigation-panel .side-navigation-panel-select .side-navigation-panel-select-wrap .side-navigation-panel-select-option .side-navigation-panel-select-option-wrap .side-navigation-panel-select-option-text {
  font-weight: bold;
  margin-left: 1rem;
  margin-right: 1rem;
  font-size: 17px;
}
.open-icon:hover {
  color: #000;
}
.side-navigation-panel .side-navigation-panel-select .side-navigation-panel-select-wrap .side-navigation-panel-select-option .side-navigation-panel-select-option-icon, .side-navigation-panel .side-navigation-panel-select .side-navigation-panel-select-wrap .side-navigation-panel-select-option svg {
  display: block;
  font-size: 1.5rem;
  font-weight: bold !important;
}
.round svg {
  font-size: 24px;
  font-weight: bold;
}
.close-icon svg {
  font-size: 24px;
  font-weight: bold;
}
.side-navigation-panel .side-navigation-panel-select .side-navigation-panel-select-wrap .side-navigation-panel-select-option {
  --tw-text-opacity: 1;
  align-items: center;
  border-left-width: 2px;
  color: rgba(55,65,81,var(--tw-text-opacity));
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: 8px 10px!important;
  width: 100%;
}
.p-datatable.p-datatable-gridlines .p-datatable-thead > tr > th {
  border-width: 1px solid #ccc !important
}
.data_table table thead, tbody, tfoot, tr, td, th {
  white-space: nowrap;
  border: 1px solid #ccc !important;
  box-shadow: rgba(0, 0, 0, -0.96) 0px 3px 8px;
}
select#sel_pincode {
  line-height: 1.5 !important;
}
.table-header-center {
  text-align: center !important;
}
.table-text-center {
  text-align: center !important;
}
.table-text-right {
  text-align: right !important;
}
  @media only screen and (max-width: 576px) {
  #profile-pills-tab.nav-pills .nav-link {
    color: #000;
    background-color: #d9d9d9;
    display: table-cell;
    width: auto;     
    padding: 10px 10px;
    margin: 0px 10px;
    border: 1px solid #d9d9d9;               
  }
  }
  @media only screen and (max-width: 767px) {
    #profile-pills-tab.nav-pills .nav-link {
      color: #000;
      background-color: #d9d9d9;
      display: table-cell;
      width: 220px;     
      padding: 10px 30px;
      margin: 0px 20px;
      border: 1px solid #d9d9d9;               
    }
  }
  @media (min-width: 992px){
  #profile-pills-tab.nav-pills .nav-link {
    color: #000;
    background-color: #d9d9d9;
    display: block;   
    padding: 10px 30px;
    margin: 0px 20px;
    border: 1px solid #d9d9d9;               
  }
}
@media only screen and (min-device-width: 820px) and (max-device-width: 1180px) {
#profile-pills-tab.nav-pills .nav-link {
  color: #000;
  background-color: #d9d9d9;
  display: inline;    
  padding: 10px 30px;
  margin: 0px 20px;
  border: 1px solid #d9d9d9;               
}
.side-navigation-panel .side-navigation-panel-select .side-navigation-panel-select-wrap .side-navigation-panel-select-option .side-navigation-panel-select-option-wrap .side-navigation-panel-select-option-text {
  font-weight: bold;
  margin-left: 1rem;
  margin-right: 1rem;
  font-size: 16px;
}
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  #profile-pills-tab.nav-pills .nav-link {
    color: #000;
    background-color: #d9d9d9;
    padding: 10px 10px;
    margin: 1px 9px;
    width: auto;
}
.side-navigation-panel .side-navigation-panel-select .side-navigation-panel-select-wrap .side-navigation-panel-select-option .side-navigation-panel-select-option-wrap .side-navigation-panel-select-option-text {
  font-weight: bold;
  margin-left: 1rem;
  margin-right: 1rem;
  font-size: 14px;
}
  }
.p-0.m-2.position-item-center.col-lg-11{
  position: relative !important;
  margin: 0 auto !important;
}

@media screen and (max-width: 600px) {
  select#sel_pincode_1 > option{
    font-size: 10px !important;
  }
}
