

.ease-in-animation {
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
  transition-duration: 500ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.appearance-none {
  -webkit-appearance: none;
          appearance: none;
}

.bg-fixed {
  background-attachment: fixed;
}

.bg-local {
  background-attachment: local;
}

.bg-scroll {
  background-attachment: scroll;
}

.bg-clip-border {
  background-clip: border-box;
}

.bg-clip-padding {
  background-clip: padding-box;
}

.bg-clip-content {
  background-clip: content-box;
}

.bg-clip-text {
  -webkit-background-clip: text;
          background-clip: text;
}

.bg-transparent {
  background-color: transparent;
}

.bg-current {
  background-color: currentColor;
}

.bg-black {
  --bg-opacity: 1;
  background-color: #000;
  background-color: rgba(0, 0, 0, var(--bg-opacity));
}

.bg-white {
  --bg-opacity: 1;
  background-color: #fff;
  background-color: rgba(255, 255, 255, var(--bg-opacity));
}

.bg-gray-100 {
  --bg-opacity: 1;
  background-color: #f7fafc;
  background-color: rgba(247, 250, 252, var(--bg-opacity));
}

.bg-gray-200 {
  --bg-opacity: 1;
  background-color: #edf2f7;
  background-color: rgba(237, 242, 247, var(--bg-opacity));
}

.bg-gray-300 {
  --bg-opacity: 1;
  background-color: #e2e8f0;
  background-color: rgba(226, 232, 240, var(--bg-opacity));
}

.bg-gray-400 {
  --bg-opacity: 1;
  background-color: #cbd5e0;
  background-color: rgba(203, 213, 224, var(--bg-opacity));
}

.bg-gray-500 {
  --bg-opacity: 1;
  background-color: #a0aec0;
  background-color: rgba(160, 174, 192, var(--bg-opacity));
}

.bg-gray-600 {
  --bg-opacity: 1;
  background-color: #718096;
  background-color: rgba(113, 128, 150, var(--bg-opacity));
}

.bg-gray-700 {
  --bg-opacity: 1;
  background-color: #4a5568;
  background-color: rgba(74, 85, 104, var(--bg-opacity));
}

.bg-gray-800 {
  --bg-opacity: 1;
  background-color: #2d3748;
  background-color: rgba(45, 55, 72, var(--bg-opacity));
}

.bg-gray-900 {
  --bg-opacity: 1;
  background-color: #1a202c;
  background-color: rgba(26, 32, 44, var(--bg-opacity));
}

.bg-red-100 {
  --bg-opacity: 1;
  background-color: #fff5f5;
  background-color: rgba(255, 245, 245, var(--bg-opacity));
}

.bg-red-200 {
  --bg-opacity: 1;
  background-color: #fed7d7;
  background-color: rgba(254, 215, 215, var(--bg-opacity));
}

.bg-red-300 {
  --bg-opacity: 1;
  background-color: #feb2b2;
  background-color: rgba(254, 178, 178, var(--bg-opacity));
}

.bg-red-400 {
  --bg-opacity: 1;
  background-color: #fc8181;
  background-color: rgba(252, 129, 129, var(--bg-opacity));
}

.bg-red-500 {
  --bg-opacity: 1;
  background-color: #f56565;
  background-color: rgba(245, 101, 101, var(--bg-opacity));
}

.bg-red-600 {
  --bg-opacity: 1;
  background-color: #e53e3e;
  background-color: rgba(229, 62, 62, var(--bg-opacity));
}

.bg-red-700 {
  --bg-opacity: 1;
  background-color: #c53030;
  background-color: rgba(197, 48, 48, var(--bg-opacity));
}

.bg-red-800 {
  --bg-opacity: 1;
  background-color: #9b2c2c;
  background-color: rgba(155, 44, 44, var(--bg-opacity));
}

.bg-red-900 {
  --bg-opacity: 1;
  background-color: #742a2a;
  background-color: rgba(116, 42, 42, var(--bg-opacity));
}

.bg-orange-100 {
  --bg-opacity: 1;
  background-color: #fffaf0;
  background-color: rgba(255, 250, 240, var(--bg-opacity));
}

.bg-orange-200 {
  --bg-opacity: 1;
  background-color: #feebc8;
  background-color: rgba(254, 235, 200, var(--bg-opacity));
}

.bg-orange-300 {
  --bg-opacity: 1;
  background-color: #fbd38d;
  background-color: rgba(251, 211, 141, var(--bg-opacity));
}

.bg-orange-400 {
  --bg-opacity: 1;
  background-color: #f6ad55;
  background-color: rgba(246, 173, 85, var(--bg-opacity));
}

.bg-orange-500 {
  --bg-opacity: 1;
  background-color: #ed8936;
  background-color: rgba(237, 137, 54, var(--bg-opacity));
}

.bg-orange-600 {
  --bg-opacity: 1;
  background-color: #dd6b20;
  background-color: rgba(221, 107, 32, var(--bg-opacity));
}

.bg-orange-700 {
  --bg-opacity: 1;
  background-color: #c05621;
  background-color: rgba(192, 86, 33, var(--bg-opacity));
}

.bg-orange-800 {
  --bg-opacity: 1;
  background-color: #9c4221;
  background-color: rgba(156, 66, 33, var(--bg-opacity));
}

.bg-orange-900 {
  --bg-opacity: 1;
  background-color: #7b341e;
  background-color: rgba(123, 52, 30, var(--bg-opacity));
}

.bg-yellow-100 {
  --bg-opacity: 1;
  background-color: #fffff0;
  background-color: rgba(255, 255, 240, var(--bg-opacity));
}

.bg-yellow-200 {
  --bg-opacity: 1;
  background-color: #fefcbf !important;
  background-color: rgba(254, 252, 191, var(--bg-opacity));
}

.bg-yellow-300 {
  --bg-opacity: 1;
  background-color: #ffc000;
  background-color: rgba(250, 240, 137, var(--bg-opacity));
}

.bg-yellow-400 {
  --bg-opacity: 1;
  background-color: #f6e05e;
  background-color: rgba(246, 224, 94, var(--bg-opacity));
}

.bg-yellow-500 {
  --bg-opacity: 1;
  background-color: #ecc94b;
  background-color: rgba(236, 201, 75, var(--bg-opacity));
}

.bg-yellow-600 {
  --bg-opacity: 1;
  background-color: #d69e2e;
  background-color: rgba(214, 158, 46, var(--bg-opacity));
}

.bg-yellow-700 {
  --bg-opacity: 1;
  background-color: #b7791f;
  background-color: rgba(183, 121, 31, var(--bg-opacity));
}

.bg-yellow-800 {
  --bg-opacity: 1;
  background-color: #975a16;
  background-color: rgba(151, 90, 22, var(--bg-opacity));
}

.bg-yellow-900 {
  --bg-opacity: 1;
  background-color: #744210;
  background-color: rgba(116, 66, 16, var(--bg-opacity));
}

.bg-green-100 {
  --bg-opacity: 1;
  background-color: #f0fff4;
  background-color: rgba(240, 255, 244, var(--bg-opacity));
}

.bg-green-200 {
  --bg-opacity: 1;
  background-color: #c6f6d5;
  background-color: rgba(198, 246, 213, var(--bg-opacity));
}

.bg-green-300 {
  --bg-opacity: 1;
  background-color: #9ae6b4;
  background-color: rgba(154, 230, 180, var(--bg-opacity));
}

.bg-green-400 {
  --bg-opacity: 1;
  background-color: #68d391;
  background-color: rgba(104, 211, 145, var(--bg-opacity));
}

.bg-green-500 {
  --bg-opacity: 1;
  background-color: #48bb78;
  background-color: rgba(72, 187, 120, var(--bg-opacity));
}

.bg-green-600 {
  --bg-opacity: 1;
  background-color: #38a169;
  background-color: rgba(56, 161, 105, var(--bg-opacity));
}

.bg-green-700 {
  --bg-opacity: 1;
  background-color: #2f855a;
  background-color: rgba(47, 133, 90, var(--bg-opacity));
}

.bg-green-800 {
  --bg-opacity: 1;
  background-color: #276749;
  background-color: rgba(39, 103, 73, var(--bg-opacity));
}

.bg-green-900 {
  --bg-opacity: 1;
  background-color: #22543d;
  background-color: rgba(34, 84, 61, var(--bg-opacity));
}

.bg-teal-100 {
  --bg-opacity: 1;
  background-color: #e6fffa;
  background-color: rgba(230, 255, 250, var(--bg-opacity));
}

.bg-teal-200 {
  --bg-opacity: 1;
  background-color: #b2f5ea;
  background-color: rgba(178, 245, 234, var(--bg-opacity));
}

.bg-teal-300 {
  --bg-opacity: 1;
  background-color: #81e6d9;
  background-color: rgba(129, 230, 217, var(--bg-opacity));
}

.bg-teal-400 {
  --bg-opacity: 1;
  background-color: #4fd1c5;
  background-color: rgba(79, 209, 197, var(--bg-opacity));
}

.bg-teal-500 {
  --bg-opacity: 1;
  background-color: #38b2ac;
  background-color: rgba(56, 178, 172, var(--bg-opacity));
}

.bg-teal-600 {
  --bg-opacity: 1;
  background-color: #319795;
  background-color: rgba(49, 151, 149, var(--bg-opacity));
}

.bg-teal-700 {
  --bg-opacity: 1;
  background-color: #2c7a7b;
  background-color: rgba(44, 122, 123, var(--bg-opacity));
}

.bg-teal-800 {
  --bg-opacity: 1;
  background-color: #285e61;
  background-color: rgba(40, 94, 97, var(--bg-opacity));
}

.bg-teal-900 {
  --bg-opacity: 1;
  background-color: #234e52;
  background-color: rgba(35, 78, 82, var(--bg-opacity));
}

.bg-blue-100 {
  --bg-opacity: 1;
  background-color: #ebf8ff;
  background-color: rgba(235, 248, 255, var(--bg-opacity));
}

.bg-blue-200 {
  --bg-opacity: 1;
  background-color: #bee3f8;
  background-color: rgba(190, 227, 248, var(--bg-opacity));
}

.bg-blue-300 {
  --bg-opacity: 1;
  background-color: #90cdf4;
  background-color: rgba(144, 205, 244, var(--bg-opacity));
}

.bg-blue-400 {
  --bg-opacity: 1;
  background-color: #63b3ed;
  background-color: rgba(99, 179, 237, var(--bg-opacity));
}

.bg-blue-500 {
  --bg-opacity: 1;
  background-color: #4299e1;
  background-color: rgba(66, 153, 225, var(--bg-opacity));
}

.bg-blue-600 {
  --bg-opacity: 1;
  background-color: #3182ce;
  background-color: rgba(49, 130, 206, var(--bg-opacity));
}

.bg-blue-700 {
  --bg-opacity: 1;
  background-color: #2b6cb0;
  background-color: rgba(43, 108, 176, var(--bg-opacity));
}

.bg-blue-800 {
  --bg-opacity: 1;
  background-color: #2c5282;
  background-color: rgba(44, 82, 130, var(--bg-opacity));
}

.bg-blue-900 {
  --bg-opacity: 1;
  background-color: #2a4365;
  background-color: rgba(42, 67, 101, var(--bg-opacity));
}

.bg-indigo-100 {
  --bg-opacity: 1;
  background-color: #ebf4ff;
  background-color: rgba(235, 244, 255, var(--bg-opacity));
}

.bg-indigo-200 {
  --bg-opacity: 1;
  background-color: #c3dafe;
  background-color: rgba(195, 218, 254, var(--bg-opacity));
}

.bg-indigo-300 {
  --bg-opacity: 1;
  background-color: #a3bffa;
  background-color: rgba(163, 191, 250, var(--bg-opacity));
}

.bg-indigo-400 {
  --bg-opacity: 1;
  background-color: #7f9cf5;
  background-color: rgba(127, 156, 245, var(--bg-opacity));
}

.bg-indigo-500 {
  --bg-opacity: 1;
  background-color: #667eea;
  background-color: rgba(102, 126, 234, var(--bg-opacity));
}

.bg-indigo-600 {
  --bg-opacity: 1;
  background-color: #5a67d8;
  background-color: rgba(90, 103, 216, var(--bg-opacity));
}

.bg-indigo-700 {
  --bg-opacity: 1;
  background-color: #4c51bf;
  background-color: rgba(76, 81, 191, var(--bg-opacity));
}

.bg-indigo-800 {
  --bg-opacity: 1;
  background-color: #434190;
  background-color: rgba(67, 65, 144, var(--bg-opacity));
}

.bg-indigo-900 {
  --bg-opacity: 1;
  background-color: #3c366b;
  background-color: rgba(60, 54, 107, var(--bg-opacity));
}

.bg-purple-100 {
  --bg-opacity: 1;
  background-color: #faf5ff;
  background-color: rgba(250, 245, 255, var(--bg-opacity));
}

.bg-purple-200 {
  --bg-opacity: 1;
  background-color: #e9d8fd;
  background-color: rgba(233, 216, 253, var(--bg-opacity));
}

.bg-purple-300 {
  --bg-opacity: 1;
  background-color: #d6bcfa;
  background-color: rgba(214, 188, 250, var(--bg-opacity));
}

.bg-purple-400 {
  --bg-opacity: 1;
  background-color: #b794f4;
  background-color: rgba(183, 148, 244, var(--bg-opacity));
}

.bg-purple-500 {
  --bg-opacity: 1;
  background-color: #9f7aea;
  background-color: rgba(159, 122, 234, var(--bg-opacity));
}

.bg-purple-600 {
  --bg-opacity: 1;
  background-color: #805ad5;
  background-color: rgba(128, 90, 213, var(--bg-opacity));
}

.bg-purple-700 {
  --bg-opacity: 1;
  background-color: #6b46c1;
  background-color: rgba(107, 70, 193, var(--bg-opacity));
}

.bg-purple-800 {
  --bg-opacity: 1;
  background-color: #553c9a;
  background-color: rgba(85, 60, 154, var(--bg-opacity));
}

.bg-purple-900 {
  --bg-opacity: 1;
  background-color: #44337a;
  background-color: rgba(68, 51, 122, var(--bg-opacity));
}

.bg-pink-100 {
  --bg-opacity: 1;
  background-color: #fff5f7;
  background-color: rgba(255, 245, 247, var(--bg-opacity));
}

.bg-pink-200 {
  --bg-opacity: 1;
  background-color: #fed7e2;
  background-color: rgba(254, 215, 226, var(--bg-opacity));
}

.bg-pink-300 {
  --bg-opacity: 1;
  background-color: #fbb6ce;
  background-color: rgba(251, 182, 206, var(--bg-opacity));
}

.bg-pink-400 {
  --bg-opacity: 1;
  background-color: #f687b3;
  background-color: rgba(246, 135, 179, var(--bg-opacity));
}

.bg-pink-500 {
  --bg-opacity: 1;
  background-color: #ed64a6;
  background-color: rgba(237, 100, 166, var(--bg-opacity));
}

.bg-pink-600 {
  --bg-opacity: 1;
  background-color: #d53f8c;
  background-color: rgba(213, 63, 140, var(--bg-opacity));
}

.bg-pink-700 {
  --bg-opacity: 1;
  background-color: #b83280;
  background-color: rgba(184, 50, 128, var(--bg-opacity));
}

.bg-pink-800 {
  --bg-opacity: 1;
  background-color: #97266d;
  background-color: rgba(151, 38, 109, var(--bg-opacity));
}

.bg-pink-900 {
  --bg-opacity: 1;
  background-color: #702459;
  background-color: rgba(112, 36, 89, var(--bg-opacity));
}

.hover\:bg-transparent:hover {
  background-color: transparent;
}

.hover\:bg-current:hover {
  background-color: currentColor;
}
.focus\:to-pink-900:focus {
  --gradient-to-color: #702459;
}

.bg-opacity-0 {
  --bg-opacity: 0;
}

.bg-opacity-25 {
  --bg-opacity: 0.25;
}

.bg-opacity-50 {
  --bg-opacity: 0.5;
}

.bg-opacity-75 {
  --bg-opacity: 0.75;
}

.bg-opacity-100 {
  --bg-opacity: 1;
}

.hover\:bg-opacity-0:hover {
  --bg-opacity: 0;
}

.hover\:bg-opacity-25:hover {
  --bg-opacity: 0.25;
}

.hover\:bg-opacity-50:hover {
  --bg-opacity: 0.5;
}

.hover\:bg-opacity-75:hover {
  --bg-opacity: 0.75;
}

.hover\:bg-opacity-100:hover {
  --bg-opacity: 1;
}

.focus\:bg-opacity-0:focus {
  --bg-opacity: 0;
}

.focus\:bg-opacity-25:focus {
  --bg-opacity: 0.25;
}

.focus\:bg-opacity-50:focus {
  --bg-opacity: 0.5;
}

.focus\:bg-opacity-75:focus {
  --bg-opacity: 0.75;
}

.focus\:bg-opacity-100:focus {
  --bg-opacity: 1;
}

.bg-bottom {
  background-position: bottom;
}

.bg-center {
  background-position: center;
}

.bg-left {
  background-position: left;
}

.bg-left-bottom {
  background-position: left bottom;
}

.bg-left-top {
  background-position: left top;
}

.bg-right {
  background-position: right;
}

.bg-right-bottom {
  background-position: right bottom;
}

.bg-right-top {
  background-position: right top;
}

.bg-top {
  background-position: top;
}

.bg-repeat {
  background-repeat: repeat;
}

.bg-no-repeat {
  background-repeat: no-repeat;
}

.bg-repeat-x {
  background-repeat: repeat-x;
}

.bg-repeat-y {
  background-repeat: repeat-y;
}

.bg-repeat-round {
  background-repeat: round;
}

.bg-repeat-space {
  background-repeat: space;
}

.bg-auto {
  background-size: auto;
}

.bg-cover {
  background-size: cover;
}

.bg-contain {
  background-size: contain;
}

.border-collapse {
  border-collapse: collapse;
}

.border-separate {
  border-collapse: separate;
}

.border-transparent {
  border-color: transparent;
}

.border-current {
  border-color: currentColor;
}

.border-black {
  --border-opacity: 1;
  border-color: #000;
  border-color: rgba(0, 0, 0, var(--border-opacity));
}

.border-white {
  --border-opacity: 1;
  border-color: #fff;
  border-color: rgba(255, 255, 255, var(--border-opacity));
}

.border-gray-100 {
  --border-opacity: 1;
  border-color: #f7fafc;
  border-color: rgba(247, 250, 252, var(--border-opacity));
}

.border-gray-200 {
  --border-opacity: 1;
  border-color: #edf2f7;
  border-color: rgba(237, 242, 247, var(--border-opacity));
}

.border-gray-300 {
  --border-opacity: 1;
  border-color: #e2e8f0;
  border-color: rgba(226, 232, 240, var(--border-opacity));
}

.border-gray-400 {
  --border-opacity: 1;
  border-color: #cbd5e0;
  border-color: rgba(203, 213, 224, var(--border-opacity));
}

.border-gray-500 {
  --border-opacity: 1;
  border-color: #a0aec0;
  border-color: rgba(160, 174, 192, var(--border-opacity));
}

.border-gray-600 {
  --border-opacity: 1;
  border-color: #718096;
  border-color: rgba(113, 128, 150, var(--border-opacity));
}

.border-gray-700 {
  --border-opacity: 1;
  border-color: #4a5568;
  border-color: rgba(74, 85, 104, var(--border-opacity));
}

.border-gray-800 {
  --border-opacity: 1;
  border-color: #2d3748;
  border-color: rgba(45, 55, 72, var(--border-opacity));
}

.border-gray-900 {
  --border-opacity: 1;
  border-color: #1a202c;
  border-color: rgba(26, 32, 44, var(--border-opacity));
}

.border-red-100 {
  --border-opacity: 1;
  border-color: #fff5f5;
  border-color: rgba(255, 245, 245, var(--border-opacity));
}

.border-red-200 {
  --border-opacity: 1;
  border-color: #fed7d7;
  border-color: rgba(254, 215, 215, var(--border-opacity));
}

.border-red-300 {
  --border-opacity: 1;
  border-color: #feb2b2;
  border-color: rgba(254, 178, 178, var(--border-opacity));
}

.border-red-400 {
  --border-opacity: 1;
  border-color: #fc8181;
  border-color: rgba(252, 129, 129, var(--border-opacity));
}

.border-red-500 {
  --border-opacity: 1;
  border-color: #f56565;
  border-color: rgba(245, 101, 101, var(--border-opacity));
}

.border-red-600 {
  --border-opacity: 1;
  border-color: #e53e3e;
  border-color: rgba(229, 62, 62, var(--border-opacity));
}

.border-red-700 {
  --border-opacity: 1;
  border-color: #c53030;
  border-color: rgba(197, 48, 48, var(--border-opacity));
}

.border-red-800 {
  --border-opacity: 1;
  border-color: #9b2c2c;
  border-color: rgba(155, 44, 44, var(--border-opacity));
}

.border-red-900 {
  --border-opacity: 1;
  border-color: #742a2a;
  border-color: rgba(116, 42, 42, var(--border-opacity));
}

.border-orange-100 {
  --border-opacity: 1;
  border-color: #fffaf0;
  border-color: rgba(255, 250, 240, var(--border-opacity));
}

.border-orange-200 {
  --border-opacity: 1;
  border-color: #feebc8;
  border-color: rgba(254, 235, 200, var(--border-opacity));
}

.border-orange-300 {
  --border-opacity: 1;
  border-color: #fbd38d;
  border-color: rgba(251, 211, 141, var(--border-opacity));
}

.border-orange-400 {
  --border-opacity: 1;
  border-color: #f6ad55;
  border-color: rgba(246, 173, 85, var(--border-opacity));
}

.border-orange-500 {
  --border-opacity: 1;
  border-color: #ed8936;
  border-color: rgba(237, 137, 54, var(--border-opacity));
}

.border-orange-600 {
  --border-opacity: 1;
  border-color: #dd6b20;
  border-color: rgba(221, 107, 32, var(--border-opacity));
}

.border-orange-700 {
  --border-opacity: 1;
  border-color: #c05621;
  border-color: rgba(192, 86, 33, var(--border-opacity));
}

.border-orange-800 {
  --border-opacity: 1;
  border-color: #9c4221;
  border-color: rgba(156, 66, 33, var(--border-opacity));
}

.border-orange-900 {
  --border-opacity: 1;
  border-color: #7b341e;
  border-color: rgba(123, 52, 30, var(--border-opacity));
}

.border-yellow-100 {
  --border-opacity: 1;
  border-color: #fffff0;
  border-color: rgba(255, 255, 240, var(--border-opacity));
}

.border-yellow-200 {
  --border-opacity: 1;
  border-color: #fefcbf;
  border-color: rgba(254, 252, 191, var(--border-opacity));
}

.border-yellow-300 {
  --border-opacity: 1;
  border-color: #faf089;
  border-color: rgba(250, 240, 137, var(--border-opacity));
}

.border-yellow-400 {
  --border-opacity: 1;
  border-color: #f6e05e;
  border-color: rgba(246, 224, 94, var(--border-opacity));
}

.border-yellow-500 {
  --border-opacity: 1;
  border-color: #ecc94b;
  border-color: rgba(236, 201, 75, var(--border-opacity));
}

.border-yellow-600 {
  --border-opacity: 1;
  border-color: #d69e2e;
  border-color: rgba(214, 158, 46, var(--border-opacity));
}

.border-yellow-700 {
  --border-opacity: 1;
  border-color: #b7791f;
  border-color: rgba(183, 121, 31, var(--border-opacity));
}

.border-yellow-800 {
  --border-opacity: 1;
  border-color: #975a16;
  border-color: rgba(151, 90, 22, var(--border-opacity));
}

.border-yellow-900 {
  --border-opacity: 1;
  border-color: #744210;
  border-color: rgba(116, 66, 16, var(--border-opacity));
}

.border-green-100 {
  --border-opacity: 1;
  border-color: #f0fff4;
  border-color: rgba(240, 255, 244, var(--border-opacity));
}

.border-green-200 {
  --border-opacity: 1;
  border-color: #c6f6d5;
  border-color: rgba(198, 246, 213, var(--border-opacity));
}

.border-green-300 {
  --border-opacity: 1;
  border-color: #9ae6b4;
  border-color: rgba(154, 230, 180, var(--border-opacity));
}

.border-green-400 {
  --border-opacity: 1;
  border-color: #68d391;
  border-color: rgba(104, 211, 145, var(--border-opacity));
}

.border-green-500 {
  --border-opacity: 1;
  border-color: #48bb78;
  border-color: rgba(72, 187, 120, var(--border-opacity));
}

.border-green-600 {
  --border-opacity: 1;
  border-color: #38a169;
  border-color: rgba(56, 161, 105, var(--border-opacity));
}

.border-green-700 {
  --border-opacity: 1;
  border-color: #2f855a;
  border-color: rgba(47, 133, 90, var(--border-opacity));
}

.border-green-800 {
  --border-opacity: 1;
  border-color: #276749;
  border-color: rgba(39, 103, 73, var(--border-opacity));
}

.border-green-900 {
  --border-opacity: 1;
  border-color: #22543d;
  border-color: rgba(34, 84, 61, var(--border-opacity));
}

.border-teal-100 {
  --border-opacity: 1;
  border-color: #e6fffa;
  border-color: rgba(230, 255, 250, var(--border-opacity));
}

.border-teal-200 {
  --border-opacity: 1;
  border-color: #b2f5ea;
  border-color: rgba(178, 245, 234, var(--border-opacity));
}

.border-teal-300 {
  --border-opacity: 1;
  border-color: #81e6d9;
  border-color: rgba(129, 230, 217, var(--border-opacity));
}

.border-teal-400 {
  --border-opacity: 1;
  border-color: #4fd1c5;
  border-color: rgba(79, 209, 197, var(--border-opacity));
}

.border-teal-500 {
  --border-opacity: 1;
  border-color: #38b2ac;
  border-color: rgba(56, 178, 172, var(--border-opacity));
}

.border-teal-600 {
  --border-opacity: 1;
  border-color: #319795;
  border-color: rgba(49, 151, 149, var(--border-opacity));
}

.border-teal-700 {
  --border-opacity: 1;
  border-color: #2c7a7b;
  border-color: rgba(44, 122, 123, var(--border-opacity));
}

.border-teal-800 {
  --border-opacity: 1;
  border-color: #285e61;
  border-color: rgba(40, 94, 97, var(--border-opacity));
}

.border-teal-900 {
  --border-opacity: 1;
  border-color: #234e52;
  border-color: rgba(35, 78, 82, var(--border-opacity));
}

.border-blue-100 {
  --border-opacity: 1;
  border-color: #ebf8ff;
  border-color: rgba(235, 248, 255, var(--border-opacity));
}

.border-blue-200 {
  --border-opacity: 1;
  border-color: #bee3f8;
  border-color: rgba(190, 227, 248, var(--border-opacity));
}

.border-blue-300 {
  --border-opacity: 1;
  border-color: #90cdf4;
  border-color: rgba(144, 205, 244, var(--border-opacity));
}

.border-blue-400 {
  --border-opacity: 1;
  border-color: #63b3ed;
  border-color: rgba(99, 179, 237, var(--border-opacity));
}

.border-blue-500 {
  --border-opacity: 1;
  border-color: #4299e1;
  border-color: rgba(66, 153, 225, var(--border-opacity));
}

.border-blue-600 {
  --border-opacity: 1;
  border-color: #3182ce;
  border-color: rgba(49, 130, 206, var(--border-opacity));
}

.border-blue-700 {
  --border-opacity: 1;
  border-color: #2b6cb0;
  border-color: rgba(43, 108, 176, var(--border-opacity));
}

.border-blue-800 {
  --border-opacity: 1;
  border-color: #2c5282;
  border-color: rgba(44, 82, 130, var(--border-opacity));
}

.border-blue-900 {
  --border-opacity: 1;
  border-color: #2a4365;
  border-color: rgba(42, 67, 101, var(--border-opacity));
}

.border-indigo-100 {
  --border-opacity: 1;
  border-color: #ebf4ff;
  border-color: rgba(235, 244, 255, var(--border-opacity));
}

.border-indigo-200 {
  --border-opacity: 1;
  border-color: #c3dafe;
  border-color: rgba(195, 218, 254, var(--border-opacity));
}

.border-indigo-300 {
  --border-opacity: 1;
  border-color: #a3bffa;
  border-color: rgba(163, 191, 250, var(--border-opacity));
}

.border-indigo-400 {
  --border-opacity: 1;
  border-color: #7f9cf5;
  border-color: rgba(127, 156, 245, var(--border-opacity));
}

.border-indigo-500 {
  --border-opacity: 1;
  border-color: #667eea;
  border-color: rgba(102, 126, 234, var(--border-opacity));
}

.border-indigo-600 {
  --border-opacity: 1;
  border-color: #5a67d8;
  border-color: rgba(90, 103, 216, var(--border-opacity));
}

.border-indigo-700 {
  --border-opacity: 1;
  border-color: #4c51bf;
  border-color: rgba(76, 81, 191, var(--border-opacity));
}

.border-indigo-800 {
  --border-opacity: 1;
  border-color: #434190;
  border-color: rgba(67, 65, 144, var(--border-opacity));
}

.border-indigo-900 {
  --border-opacity: 1;
  border-color: #3c366b;
  border-color: rgba(60, 54, 107, var(--border-opacity));
}

.border-purple-100 {
  --border-opacity: 1;
  border-color: #faf5ff;
  border-color: rgba(250, 245, 255, var(--border-opacity));
}

.border-purple-200 {
  --border-opacity: 1;
  border-color: #e9d8fd;
  border-color: rgba(233, 216, 253, var(--border-opacity));
}

.border-purple-300 {
  --border-opacity: 1;
  border-color: #d6bcfa;
  border-color: rgba(214, 188, 250, var(--border-opacity));
}

.border-purple-400 {
  --border-opacity: 1;
  border-color: #b794f4;
  border-color: rgba(183, 148, 244, var(--border-opacity));
}

.border-purple-500 {
  --border-opacity: 1;
  border-color: #9f7aea;
  border-color: rgba(159, 122, 234, var(--border-opacity));
}

.border-purple-600 {
  --border-opacity: 1;
  border-color: #805ad5;
  border-color: rgba(128, 90, 213, var(--border-opacity));
}

.border-purple-700 {
  --border-opacity: 1;
  border-color: #6b46c1;
  border-color: rgba(107, 70, 193, var(--border-opacity));
}

.border-purple-800 {
  --border-opacity: 1;
  border-color: #553c9a;
  border-color: rgba(85, 60, 154, var(--border-opacity));
}

.border-purple-900 {
  --border-opacity: 1;
  border-color: #44337a;
  border-color: rgba(68, 51, 122, var(--border-opacity));
}

.border-pink-100 {
  --border-opacity: 1;
  border-color: #fff5f7;
  border-color: rgba(255, 245, 247, var(--border-opacity));
}

.border-pink-200 {
  --border-opacity: 1;
  border-color: #fed7e2;
  border-color: rgba(254, 215, 226, var(--border-opacity));
}

.border-pink-300 {
  --border-opacity: 1;
  border-color: #fbb6ce;
  border-color: rgba(251, 182, 206, var(--border-opacity));
}

.border-pink-400 {
  --border-opacity: 1;
  border-color: #f687b3;
  border-color: rgba(246, 135, 179, var(--border-opacity));
}

.border-pink-500 {
  --border-opacity: 1;
  border-color: #ed64a6;
  border-color: rgba(237, 100, 166, var(--border-opacity));
}

.border-pink-600 {
  --border-opacity: 1;
  border-color: #d53f8c;
  border-color: rgba(213, 63, 140, var(--border-opacity));
}

.border-pink-700 {
  --border-opacity: 1;
  border-color: #b83280;
  border-color: rgba(184, 50, 128, var(--border-opacity));
}

.border-pink-800 {
  --border-opacity: 1;
  border-color: #97266d;
  border-color: rgba(151, 38, 109, var(--border-opacity));
}

.border-pink-900 {
  --border-opacity: 1;
  border-color: #702459;
  border-color: rgba(112, 36, 89, var(--border-opacity));
}

.rounded-none {
  border-radius: 0;
}

.rounded-sm {
  border-radius: 0.125rem;
}

.rounded {
  border-radius: 0.25rem;
}

.rounded-md {
  border-radius: 0.375rem;
}

.rounded-lg {
  border-radius: 0.5rem;
}

.rounded-xl {
  border-radius: 0.75rem;
}

.rounded-2xl {
  border-radius: 1rem;
}

.rounded-3xl {
  border-radius: 1.5rem;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-t-none {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.rounded-r-none {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.rounded-b-none {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.rounded-l-none {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.rounded-t-sm {
  border-top-left-radius: 0.125rem;
  border-top-right-radius: 0.125rem;
}

.rounded-r-sm {
  border-top-right-radius: 0.125rem;
  border-bottom-right-radius: 0.125rem;
}

.rounded-b-sm {
  border-bottom-right-radius: 0.125rem;
  border-bottom-left-radius: 0.125rem;
}

.rounded-l-sm {
  border-top-left-radius: 0.125rem;
  border-bottom-left-radius: 0.125rem;
}

.rounded-t {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.rounded-r {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.rounded-b {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.rounded-l {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.rounded-t-md {
  border-top-left-radius: 0.375rem;
  border-top-right-radius: 0.375rem;
}

.rounded-r-md {
  border-top-right-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
}

.rounded-b-md {
  border-bottom-right-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
}

.rounded-l-md {
  border-top-left-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
}

.rounded-t-lg {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}

.rounded-r-lg {
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}

.rounded-b-lg {
  border-bottom-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}

.rounded-l-lg {
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}

.rounded-t-xl {
  border-top-left-radius: 0.75rem;
  border-top-right-radius: 0.75rem;
}

.rounded-r-xl {
  border-top-right-radius: 0.75rem;
  border-bottom-right-radius: 0.75rem;
}

.rounded-b-xl {
  border-bottom-right-radius: 0.75rem;
  border-bottom-left-radius: 0.75rem;
}

.rounded-l-xl {
  border-top-left-radius: 0.75rem;
  border-bottom-left-radius: 0.75rem;
}

.rounded-t-2xl {
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
}

.rounded-r-2xl {
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
}

.rounded-b-2xl {
  border-bottom-right-radius: 1rem;
  border-bottom-left-radius: 1rem;
}

.rounded-l-2xl {
  border-top-left-radius: 1rem;
  border-bottom-left-radius: 1rem;
}

.rounded-t-3xl {
  border-top-left-radius: 1.5rem;
  border-top-right-radius: 1.5rem;
}

.rounded-r-3xl {
  border-top-right-radius: 1.5rem;
  border-bottom-right-radius: 1.5rem;
}

.rounded-b-3xl {
  border-bottom-right-radius: 1.5rem;
  border-bottom-left-radius: 1.5rem;
}

.rounded-l-3xl {
  border-top-left-radius: 1.5rem;
  border-bottom-left-radius: 1.5rem;
}

.rounded-t-full {
  border-top-left-radius: 9999px;
  border-top-right-radius: 9999px;
}

.rounded-r-full {
  border-top-right-radius: 9999px;
  border-bottom-right-radius: 9999px;
}

.rounded-b-full {
  border-bottom-right-radius: 9999px;
  border-bottom-left-radius: 9999px;
}

.rounded-l-full {
  border-top-left-radius: 9999px;
  border-bottom-left-radius: 9999px;
}

.rounded-tl-none {
  border-top-left-radius: 0;
}

.rounded-tr-none {
  border-top-right-radius: 0;
}

.rounded-br-none {
  border-bottom-right-radius: 0;
}

.rounded-bl-none {
  border-bottom-left-radius: 0;
}

.rounded-tl-sm {
  border-top-left-radius: 0.125rem;
}

.rounded-tr-sm {
  border-top-right-radius: 0.125rem;
}

.rounded-br-sm {
  border-bottom-right-radius: 0.125rem;
}

.rounded-bl-sm {
  border-bottom-left-radius: 0.125rem;
}

.rounded-tl {
  border-top-left-radius: 0.25rem;
}

.rounded-tr {
  border-top-right-radius: 0.25rem;
}

.rounded-br {
  border-bottom-right-radius: 0.25rem;
}

.rounded-bl {
  border-bottom-left-radius: 0.25rem;
}

.rounded-tl-md {
  border-top-left-radius: 0.375rem;
}

.rounded-tr-md {
  border-top-right-radius: 0.375rem;
}

.rounded-br-md {
  border-bottom-right-radius: 0.375rem;
}

.rounded-bl-md {
  border-bottom-left-radius: 0.375rem;
}

.rounded-tl-lg {
  border-top-left-radius: 0.5rem;
}

.rounded-tr-lg {
  border-top-right-radius: 0.5rem;
}

.rounded-br-lg {
  border-bottom-right-radius: 0.5rem;
}

.rounded-bl-lg {
  border-bottom-left-radius: 0.5rem;
}

.rounded-tl-xl {
  border-top-left-radius: 0.75rem;
}

.rounded-tr-xl {
  border-top-right-radius: 0.75rem;
}

.rounded-br-xl {
  border-bottom-right-radius: 0.75rem;
}

.rounded-bl-xl {
  border-bottom-left-radius: 0.75rem;
}

.rounded-tl-2xl {
  border-top-left-radius: 1rem;
}

.rounded-tr-2xl {
  border-top-right-radius: 1rem;
}

.rounded-br-2xl {
  border-bottom-right-radius: 1rem;
}

.rounded-bl-2xl {
  border-bottom-left-radius: 1rem;
}

.rounded-tl-3xl {
  border-top-left-radius: 1.5rem;
}

.rounded-tr-3xl {
  border-top-right-radius: 1.5rem;
}

.rounded-br-3xl {
  border-bottom-right-radius: 1.5rem;
}

.rounded-bl-3xl {
  border-bottom-left-radius: 1.5rem;
}

.rounded-tl-full {
  border-top-left-radius: 9999px;
}

.rounded-tr-full {
  border-top-right-radius: 9999px;
}

.rounded-br-full {
  border-bottom-right-radius: 9999px;
}

.rounded-bl-full {
  border-bottom-left-radius: 9999px;
}

.border-solid {
  border-style: solid;
}

.border-dashed {
  border-style: dashed;
}

.border-dotted {
  border-style: dotted;
}

.border-double {
  border-style: double;
}

.border-none {
  border-style: none;
}

.border-0 {
  border-width: 0;
}

.border-2 {
  border-width: 2px;
}

.border-4 {
  border-width: 4px;
}

.border-8 {
  border-width: 8px;
}

.border {
  border-width: 1px;
}

.border-t-0 {
  border-top-width: 0;
}

.border-r-0 {
  border-right-width: 0;
}

.border-b-0 {
  border-bottom-width: 0;
}

.border-l-0 {
  border-left-width: 0;
}

.border-t-2 {
  border-top-width: 2px;
}

.border-r-2 {
  border-right-width: 2px;
}

.border-b-2 {
  border-bottom-width: 2px;
}

.border-l-2 {
  border-left-width: 2px;
}

.border-t-4 {
  border-top-width: 4px;
}

.border-r-4 {
  border-right-width: 4px;
}

.border-b-4 {
  border-bottom-width: 4px;
}

.border-l-4 {
  border-left-width: 4px;
}

.border-t-8 {
  border-top-width: 8px;
}

.border-r-8 {
  border-right-width: 8px;
}

.border-b-8 {
  border-bottom-width: 8px;
}

.border-l-8 {
  border-left-width: 8px;
}

.border-t {
  border-top-width: 1px;
}

.border-r {
  border-right-width: 1px;
}

.border-b {
  border-bottom-width: 1px;
}

.border-l {
  border-left-width: 1px;
}

.box-border {
  box-sizing: border-box;
}

.box-content {
  box-sizing: content-box;
}

.cursor-auto {
  cursor: auto;
}

.cursor-default {
  cursor: default;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-wait {
  cursor: wait;
}

.cursor-text {
  cursor: text;
}

.cursor-move {
  cursor: move;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.inline {
  display: inline;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.table {
  display: table;
}

.table-caption {
  display: table-caption;
}

.table-cell {
  display: table-cell;
}

.table-column {
  display: table-column;
}

.table-column-group {
  display: table-column-group;
}

.table-footer-group {
  display: table-footer-group;
}

.table-header-group {
  display: table-header-group;
}

.table-row-group {
  display: table-row-group;
}

.table-row {
  display: table-row;
}

.flow-root {
  display: flow-root;
}

.grid {
  display: grid;
}

.inline-grid {
  display: inline-grid;
}

.contents {
  display: contents;
}

.hidden {
  display: none;
}

.flex-row {
  flex-direction: row;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.flex-col {
  flex-direction: column;
}

.flex-col-reverse {
  flex-direction: column-reverse;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse;
}

.flex-no-wrap {
  flex-wrap: nowrap;
}

.place-items-auto {
  place-items: auto;
}

.place-items-start {
  place-items: start;
}

.place-items-end {
  place-items: end;
}

.place-items-center {
  place-items: center;
}

.place-items-stretch {
  place-items: stretch;
}

.place-content-center {
  place-content: center;
}

.place-content-start {
  place-content: start;
}

.place-content-end {
  place-content: end;
}

.place-content-between {
  place-content: space-between;
}

.place-content-around {
  place-content: space-around;
}

.place-content-evenly {
  place-content: space-evenly;
}

.place-content-stretch {
  place-content: stretch;
}

.place-self-auto {
  place-self: auto;
}

.place-self-start {
  place-self: start;
}

.place-self-end {
  place-self: end;
}

.place-self-center {
  place-self: center;
}

.place-self-stretch {
  place-self: stretch;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.items-baseline {
  align-items: baseline;
}

.items-stretch {
  align-items: stretch;
}

.content-center {
  align-content: center;
}

.content-start {
  align-content: flex-start;
}

.content-end {
  align-content: flex-end;
}

.content-between {
  align-content: space-between;
}

.content-around {
  align-content: space-around;
}

.content-evenly {
  align-content: space-evenly;
}

.self-auto {
  align-self: auto;
}

.self-start {
  align-self: flex-start;
}

.self-end {
  align-self: flex-end;
}

.self-center {
  align-self: center;
}

.self-stretch {
  align-self: stretch;
}

.justify-items-auto {
  justify-items: auto;
}

.justify-items-start {
  justify-items: start;
}

.justify-items-end {
  justify-items: end;
}

.justify-items-center {
  justify-items: center;
}

.justify-items-stretch {
  justify-items: stretch;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.justify-evenly {
  justify-content: space-evenly;
}

.justify-self-auto {
  justify-self: auto;
}

.justify-self-start {
  justify-self: start;
}

.justify-self-end {
  justify-self: end;
}

.justify-self-center {
  justify-self: center;
}

.justify-self-stretch {
  justify-self: stretch;
}

.flex-1 {
  flex: 1 1 0%;
}

.flex-auto {
  flex: 1 1 auto;
}

.flex-initial {
  flex: 0 1 auto;
}

.flex-none {
  flex: none;
}

.flex-grow-0 {
  flex-grow: 0;
}

.flex-grow {
  flex-grow: 1;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.flex-shrink {
  flex-shrink: 1;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.order-first {
  order: -9999;
}

.order-last {
  order: 9999;
}

.order-none {
  order: 0;
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}

.float-none {
  float: none;
}

.clearfix:after {
  content: "";
  display: table;
  clear: both;
}

.clear-left {
  clear: left;
}

.clear-right {
  clear: right;
}

.clear-both {
  clear: both;
}

.clear-none {
  clear: none;
}

.font-sans {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.font-serif {
  font-family: Georgia, Cambria, "Times New Roman", Times, serif;
}

.font-mono {
  font-family: Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

.font-hairline {
  font-weight: 100;
}

.font-thin {
  font-weight: 200;
}

.font-light {
  font-weight: 300;
}

.font-normal {
  font-weight: 400;
}

.font-medium {
  font-weight: 500;
}

.font-semibold {
  font-weight: 600;
}

.font-bold {
  font-weight: 700;
}

.font-extrabold {
  font-weight: 800;
}

.font-black {
  font-weight: 900;
}

.hover\:font-hairline:hover {
  font-weight: 100;
}

.hover\:font-thin:hover {
  font-weight: 200;
}

.hover\:font-light:hover {
  font-weight: 300;
}

.hover\:font-normal:hover {
  font-weight: 400;
}

.hover\:font-medium:hover {
  font-weight: 500;
}

.hover\:font-semibold:hover {
  font-weight: 600;
}

.hover\:font-bold:hover {
  font-weight: 700;
}

.hover\:font-extrabold:hover {
  font-weight: 800;
}

.hover\:font-black:hover {
  font-weight: 900;
}

.focus\:font-hairline:focus {
  font-weight: 100;
}

.focus\:font-thin:focus {
  font-weight: 200;
}

.focus\:font-light:focus {
  font-weight: 300;
}

.focus\:font-normal:focus {
  font-weight: 400;
}

.focus\:font-medium:focus {
  font-weight: 500;
}

.focus\:font-semibold:focus {
  font-weight: 600;
}

.focus\:font-bold:focus {
  font-weight: 700;
}

.focus\:font-extrabold:focus {
  font-weight: 800;
}

.focus\:font-black:focus {
  font-weight: 900;
}

.h-0 {
  height: 0;
}

.h-1 {
  height: 0.25rem;
}

.h-2 {
  height: 0.5rem;
}

.h-3 {
  height: 0.75rem;
}

.h-4 {
  height: 1rem;
}

.h-5 {
  height: 1.25rem;
}

.h-6 {
  height: 1.5rem;
}

.h-8 {
  height: 2rem;
}

.h-10 {
  height: 2.5rem;
}

.h-12 {
  height: 3rem;
}

.h-16 {
  height: 4rem;
}

.h-20 {
  height: 5rem;
}

.h-24 {
  height: 6rem;
}

.h-32 {
  height: 8rem;
}

.h-40 {
  height: 10rem;
}

.h-48 {
  height: 12rem;
}

.h-56 {
  height: 14rem;
}

.h-64 {
  height: 16rem;
}

.h-auto {
  height: auto;
}

.h-px {
  height: 1px;
}

.h-full {
  height: 100%;
}

.h-screen {
  height: 100vh;
}

.text-xs {
  font-size: 0.75rem;
}

.text-sm {
  font-size: 0.875rem;
}

.text-base {
  font-size: 1rem;
}

.text-lg {
  font-size: 1.125rem;
}

.text-xl {
  font-size: 1.25rem;
}

.text-2xl {
  font-size: 1.5rem;
}

.text-3xl {
  font-size: 1.875rem;
}

.text-4xl {
  font-size: 2.25rem;
}

.text-5xl {
  font-size: 3rem;
}

.text-6xl {
  font-size: 4rem;
}
.leading-6 {
  line-height: 1.5rem;
}

.leading-7 {
  line-height: 1.75rem;
}

.leading-8 {
  line-height: 2rem;
}

.leading-9 {
  line-height: 2.25rem;
}

.leading-10 {
  line-height: 2.5rem;
}

.leading-none {
  line-height: 1;
}

.leading-tight {
  line-height: 1.25;
}

.leading-snug {
  line-height: 1.375;
}

.leading-normal {
  line-height: 1.5;
}

.leading-relaxed {
  line-height: 1.625;
}

.leading-loose {
  line-height: 2;
}

.list-inside {
  list-style-position: inside;
}

.list-outside {
  list-style-position: outside;
}

.list-none {
  list-style-type: none;
}

.list-disc {
  list-style-type: disc;
}

.list-decimal {
  list-style-type: decimal;
}

.m-0 {
  margin: 0;
}

.m-1 {
  margin: 0.25rem;
}

.m-2 {
  margin: 0.5rem;
}

.m-3 {
  margin: 0.75rem;
}

.m-4 {
  margin: 1rem;
}

.m-5 {
  margin: 1.25rem;
}

.m-6 {
  margin: 1.5rem;
}

.m-8 {
  margin: 2rem;
}

.m-10 {
  margin: 2.5rem;
}

.m-12 {
  margin: 3rem;
}

.m-16 {
  margin: 4rem;
}

.m-20 {
  margin: 5rem;
}

.m-24 {
  margin: 6rem;
}

.m-32 {
  margin: 8rem;
}

.m-40 {
  margin: 10rem;
}

.m-48 {
  margin: 12rem;
}

.m-56 {
  margin: 14rem;
}

.m-64 {
  margin: 16rem;
}

.m-auto {
  margin: auto;
}

.m-px {
  margin: 1px;
}

.-m-1 {
  margin: -0.25rem;
}

.-m-2 {
  margin: -0.5rem;
}

.-m-3 {
  margin: -0.75rem;
}

.-m-4 {
  margin: -1rem;
}

.-m-5 {
  margin: -1.25rem;
}

.-m-6 {
  margin: -1.5rem;
}

.-m-8 {
  margin: -2rem;
}

.-m-10 {
  margin: -2.5rem;
}

.-m-12 {
  margin: -3rem;
}

.-m-16 {
  margin: -4rem;
}

.-m-20 {
  margin: -5rem;
}

.-m-24 {
  margin: -6rem;
}

.-m-32 {
  margin: -8rem;
}

.-m-40 {
  margin: -10rem;
}

.-m-48 {
  margin: -12rem;
}

.-m-56 {
  margin: -14rem;
}

.-m-64 {
  margin: -16rem;
}

.-m-px {
  margin: -1px;
}

.my-0 {
  margin-top: 0;
  margin-bottom: 0;
}

.mx-0 {
  margin-left: 0;
  margin-right: 0;
}

.my-1 {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}

.mx-1 {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}

.my-2 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.mx-2 {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.my-3 {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
}

.mx-3 {
  margin-left: 0.75rem;
  margin-right: 0.75rem;
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.mx-4 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.my-5 {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}

.mx-5 {
  margin-left: 1.25rem;
  margin-right: 1.25rem;
}

.my-6 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.mx-6 {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

.my-8 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.mx-8 {
  margin-left: 2rem;
  margin-right: 2rem;
}

.my-10 {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}

.mx-10 {
  margin-left: 2.5rem;
  margin-right: 2.5rem;
}

.my-12 {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.mx-12 {
  margin-left: 3rem;
  margin-right: 3rem;
}

.my-16 {
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.mx-16 {
  margin-left: 4rem;
  margin-right: 4rem;
}

.my-20 {
  margin-top: 5rem;
  margin-bottom: 5rem;
}

.mx-20 {
  margin-left: 5rem;
  margin-right: 5rem;
}

.my-24 {
  margin-top: 6rem;
  margin-bottom: 6rem;
}

.mx-24 {
  margin-left: 6rem;
  margin-right: 6rem;
}

.my-32 {
  margin-top: 8rem;
  margin-bottom: 8rem;
}

.mx-32 {
  margin-left: 8rem;
  margin-right: 8rem;
}

.my-40 {
  margin-top: 10rem;
  margin-bottom: 10rem;
}

.mx-40 {
  margin-left: 10rem;
  margin-right: 10rem;
}

.my-48 {
  margin-top: 12rem;
  margin-bottom: 12rem;
}

.mx-48 {
  margin-left: 12rem;
  margin-right: 12rem;
}

.my-56 {
  margin-top: 14rem;
  margin-bottom: 14rem;
}

.mx-56 {
  margin-left: 14rem;
  margin-right: 14rem;
}

.my-64 {
  margin-top: 16rem;
  margin-bottom: 16rem;
}

.mx-64 {
  margin-left: 16rem;
  margin-right: 16rem;
}

.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-px {
  margin-top: 1px;
  margin-bottom: 1px;
}

.mx-px {
  margin-left: 1px;
  margin-right: 1px;
}

.-my-1 {
  margin-top: -0.25rem;
  margin-bottom: -0.25rem;
}

.-mx-1 {
  margin-left: -0.25rem;
  margin-right: -0.25rem;
}

.-my-2 {
  margin-top: -0.5rem;
  margin-bottom: -0.5rem;
}

.-mx-2 {
  margin-left: -0.5rem;
  margin-right: -0.5rem;
}

.-my-3 {
  margin-top: -0.75rem;
  margin-bottom: -0.75rem;
}

.-mx-3 {
  margin-left: -0.75rem;
  margin-right: -0.75rem;
}

.-my-4 {
  margin-top: -1rem;
  margin-bottom: -1rem;
}

.-mx-4 {
  margin-left: -1rem;
  margin-right: -1rem;
}

.-my-5 {
  margin-top: -1.25rem;
  margin-bottom: -1.25rem;
}

.-mx-5 {
  margin-left: -1.25rem;
  margin-right: -1.25rem;
}

.-my-6 {
  margin-top: -1.5rem;
  margin-bottom: -1.5rem;
}

.-mx-6 {
  margin-left: -1.5rem;
  margin-right: -1.5rem;
}

.-my-8 {
  margin-top: -2rem;
  margin-bottom: -2rem;
}

.-mx-8 {
  margin-left: -2rem;
  margin-right: -2rem;
}

.-my-10 {
  margin-top: -2.5rem;
  margin-bottom: -2.5rem;
}

.-mx-10 {
  margin-left: -2.5rem;
  margin-right: -2.5rem;
}

.-my-12 {
  margin-top: -3rem;
  margin-bottom: -3rem;
}

.-mx-12 {
  margin-left: -3rem;
  margin-right: -3rem;
}

.-my-16 {
  margin-top: -4rem;
  margin-bottom: -4rem;
}

.-mx-16 {
  margin-left: -4rem;
  margin-right: -4rem;
}

.-my-20 {
  margin-top: -5rem;
  margin-bottom: -5rem;
}

.-mx-20 {
  margin-left: -5rem;
  margin-right: -5rem;
}

.-my-24 {
  margin-top: -6rem;
  margin-bottom: -6rem;
}

.-mx-24 {
  margin-left: -6rem;
  margin-right: -6rem;
}

.-my-32 {
  margin-top: -8rem;
  margin-bottom: -8rem;
}

.-mx-32 {
  margin-left: -8rem;
  margin-right: -8rem;
}

.-my-40 {
  margin-top: -10rem;
  margin-bottom: -10rem;
}

.-mx-40 {
  margin-left: -10rem;
  margin-right: -10rem;
}

.-my-48 {
  margin-top: -12rem;
  margin-bottom: -12rem;
}

.-mx-48 {
  margin-left: -12rem;
  margin-right: -12rem;
}

.-my-56 {
  margin-top: -14rem;
  margin-bottom: -14rem;
}

.-mx-56 {
  margin-left: -14rem;
  margin-right: -14rem;
}

.-my-64 {
  margin-top: -16rem;
  margin-bottom: -16rem;
}

.-mx-64 {
  margin-left: -16rem;
  margin-right: -16rem;
}

.-my-px {
  margin-top: -1px;
  margin-bottom: -1px;
}

.-mx-px {
  margin-left: -1px;
  margin-right: -1px;
}

.mt-0 {
  margin-top: 0;
}

.mr-0 {
  margin-right: 0;
}

.mb-0 {
  margin-bottom: 0;
}

.ml-0 {
  margin-left: 0;
}

.mt-1 {
  margin-top: 0.25rem;
}

.mr-1 {
  margin-right: 0.25rem;
}

.mb-1 {
  margin-bottom: 0.25rem;
}

.ml-1 {
  margin-left: 0.25rem;
}

.mt-2 {
  margin-top: 0.5rem;
}

.mr-2 {
  margin-right: 0.5rem;
}

.mb-2 {
  margin-bottom: 0.5rem;
}

.ml-2 {
  margin-left: 0.5rem;
}

.mt-3 {
  margin-top: 0.75rem;
}

.mr-3 {
  margin-right: 0.75rem;
}

.mb-3 {
  margin-bottom: 0.75rem;
}

.ml-3 {
  margin-left: 0.75rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mr-4 {
  margin-right: 1rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.ml-4 {
  margin-left: 1rem;
}

.mt-5 {
  margin-top: 1.25rem;
}

.mr-5 {
  margin-right: 1.25rem;
}

.mb-5 {
  margin-bottom: 1.25rem;
}

.ml-5 {
  margin-left: 1.25rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.mr-6 {
  margin-right: 1.5rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.ml-6 {
  margin-left: 1.5rem;
}

.mt-8 {
  margin-top: 2rem;
}

.mr-8 {
  margin-right: 2rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.ml-8 {
  margin-left: 2rem;
}

.mt-10 {
  margin-top: 2.5rem;
}

.mr-10 {
  margin-right: 2.5rem;
}

.mb-10 {
  margin-bottom: 2.5rem;
}

.ml-10 {
  margin-left: 2.5rem;
}

.mt-12 {
  margin-top: 3rem;
}

.mr-12 {
  margin-right: 3rem;
}

.mb-12 {
  margin-bottom: 3rem;
}

.ml-12 {
  margin-left: 3rem;
}

.mt-16 {
  margin-top: 4rem;
}

.mr-16 {
  margin-right: 4rem;
}

.mb-16 {
  margin-bottom: 4rem;
}

.ml-16 {
  margin-left: 4rem;
}

.mt-20 {
  margin-top: 5rem;
}

.mr-20 {
  margin-right: 5rem;
}

.mb-20 {
  margin-bottom: 5rem;
}

.ml-20 {
  margin-left: 5rem;
}

.mt-24 {
  margin-top: 6rem;
}

.mr-24 {
  margin-right: 6rem;
}

.mb-24 {
  margin-bottom: 6rem;
}

.ml-24 {
  margin-left: 6rem;
}

.mt-32 {
  margin-top: 8rem;
}

.mr-32 {
  margin-right: 8rem;
}

.mb-32 {
  margin-bottom: 8rem;
}

.ml-32 {
  margin-left: 8rem;
}

.mt-40 {
  margin-top: 10rem;
}

.mr-40 {
  margin-right: 10rem;
}

.mb-40 {
  margin-bottom: 10rem;
}

.ml-40 {
  margin-left: 10rem;
}

.mt-48 {
  margin-top: 12rem;
}

.mr-48 {
  margin-right: 12rem;
}

.mb-48 {
  margin-bottom: 12rem;
}

.ml-48 {
  margin-left: 12rem;
}

.mt-56 {
  margin-top: 14rem;
}

.mr-56 {
  margin-right: 14rem;
}

.mb-56 {
  margin-bottom: 14rem;
}

.ml-56 {
  margin-left: 14rem;
}

.mt-64 {
  margin-top: 16rem;
}

.mr-64 {
  margin-right: 16rem;
}

.mb-64 {
  margin-bottom: 16rem;
}

.ml-64 {
  margin-left: 16rem;
}

.mt-auto {
  margin-top: auto;
}

.mr-auto {
  margin-right: auto;
}

.mb-auto {
  margin-bottom: auto;
}

.ml-auto {
  margin-left: auto;
}

.mt-px {
  margin-top: 1px;
}

.mr-px {
  margin-right: 1px;
}

.mb-px {
  margin-bottom: 1px;
}

.ml-px {
  margin-left: 1px;
}

.-mt-1 {
  margin-top: -0.25rem;
}

.-mr-1 {
  margin-right: -0.25rem;
}

.-mb-1 {
  margin-bottom: -0.25rem;
}

.-ml-1 {
  margin-left: -0.25rem;
}

.-mt-2 {
  margin-top: -0.5rem;
}

.-mr-2 {
  margin-right: -0.5rem;
}

.-mb-2 {
  margin-bottom: -0.5rem;
}

.-ml-2 {
  margin-left: -0.5rem;
}

.-mt-3 {
  margin-top: -0.75rem;
}

.-mr-3 {
  margin-right: -0.75rem;
}

.-mb-3 {
  margin-bottom: -0.75rem;
}

.-ml-3 {
  margin-left: -0.75rem;
}

.-mt-4 {
  margin-top: -1rem;
}

.-mr-4 {
  margin-right: -1rem;
}

.-mb-4 {
  margin-bottom: -1rem;
}

.-ml-4 {
  margin-left: -1rem;
}

.-mt-5 {
  margin-top: -1.25rem;
}

.-mr-5 {
  margin-right: -1.25rem;
}

.-mb-5 {
  margin-bottom: -1.25rem;
}

.-ml-5 {
  margin-left: -1.25rem;
}

.-mt-6 {
  margin-top: -1.5rem;
}

.-mr-6 {
  margin-right: -1.5rem;
}

.-mb-6 {
  margin-bottom: -1.5rem;
}

.-ml-6 {
  margin-left: -1.5rem;
}

.-mt-8 {
  margin-top: -2rem;
}

.-mr-8 {
  margin-right: -2rem;
}

.-mb-8 {
  margin-bottom: -2rem;
}

.-ml-8 {
  margin-left: -2rem;
}

.-mt-10 {
  margin-top: -2.5rem;
}

.-mr-10 {
  margin-right: -2.5rem;
}

.-mb-10 {
  margin-bottom: -2.5rem;
}

.-ml-10 {
  margin-left: -2.5rem;
}

.-mt-12 {
  margin-top: -3rem;
}

.-mr-12 {
  margin-right: -3rem;
}

.-mb-12 {
  margin-bottom: -3rem;
}

.-ml-12 {
  margin-left: -3rem;
}

.-mt-16 {
  margin-top: -4rem;
}

.-mr-16 {
  margin-right: -4rem;
}

.-mb-16 {
  margin-bottom: -4rem;
}

.-ml-16 {
  margin-left: -4rem;
}

.-mt-20 {
  margin-top: -5rem;
}

.-mr-20 {
  margin-right: -5rem;
}

.-mb-20 {
  margin-bottom: -5rem;
}

.-ml-20 {
  margin-left: -5rem;
}

.-mt-24 {
  margin-top: -6rem;
}

.-mr-24 {
  margin-right: -6rem;
}

.-mb-24 {
  margin-bottom: -6rem;
}

.-ml-24 {
  margin-left: -6rem;
}

.-mt-32 {
  margin-top: -8rem;
}

.-mr-32 {
  margin-right: -8rem;
}

.-mb-32 {
  margin-bottom: -8rem;
}

.-ml-32 {
  margin-left: -8rem;
}

.-mt-40 {
  margin-top: -10rem;
}

.-mr-40 {
  margin-right: -10rem;
}

.-mb-40 {
  margin-bottom: -10rem;
}

.-ml-40 {
  margin-left: -10rem;
}

.-mt-48 {
  margin-top: -12rem;
}

.-mr-48 {
  margin-right: -12rem;
}

.-mb-48 {
  margin-bottom: -12rem;
}

.-ml-48 {
  margin-left: -12rem;
}

.-mt-56 {
  margin-top: -14rem;
}

.-mr-56 {
  margin-right: -14rem;
}

.-mb-56 {
  margin-bottom: -14rem;
}

.-ml-56 {
  margin-left: -14rem;
}

.-mt-64 {
  margin-top: -16rem;
}

.-mr-64 {
  margin-right: -16rem;
}

.-mb-64 {
  margin-bottom: -16rem;
}

.-ml-64 {
  margin-left: -16rem;
}

.-mt-px {
  margin-top: -1px;
}

.-mr-px {
  margin-right: -1px;
}

.-mb-px {
  margin-bottom: -1px;
}

.-ml-px {
  margin-left: -1px;
}

.max-h-full {
  max-height: 100%;
}

.max-h-screen {
  max-height: 100vh;
}

.outline-none {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.outline-white {
  outline: 2px dotted white;
  outline-offset: 2px;
}

.outline-black {
  outline: 2px dotted black;
  outline-offset: 2px;
}

.focus\:outline-none:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.focus\:outline-white:focus {
  outline: 2px dotted white;
  outline-offset: 2px;
}

.focus\:outline-black:focus {
  outline: 2px dotted black;
  outline-offset: 2px;
}

.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-visible {
  overflow: visible;
}

.overflow-scroll {
  overflow: scroll;
}

.overflow-x-auto {
  overflow-x: auto;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.overflow-y-hidden {
  overflow-y: hidden;
}

.overflow-x-visible {
  overflow-x: visible;
}

.overflow-y-visible {
  overflow-y: visible;
}

.overflow-x-scroll {
  overflow-x: scroll;
}

.overflow-y-scroll {
  overflow-y: scroll;
}

.scrolling-touch {
  -webkit-overflow-scrolling: touch;
}

.scrolling-auto {
  -webkit-overflow-scrolling: auto;
}

.overscroll-auto {
  overscroll-behavior: auto;
}

.overscroll-contain {
  overscroll-behavior: contain;
}

.overscroll-none {
  overscroll-behavior: none;
}

.overscroll-y-auto {
  overscroll-behavior-y: auto;
}

.overscroll-y-contain {
  overscroll-behavior-y: contain;
}

.overscroll-y-none {
  overscroll-behavior-y: none;
}

.overscroll-x-auto {
  overscroll-behavior-x: auto;
}

.overscroll-x-contain {
  overscroll-behavior-x: contain;
}

.overscroll-x-none {
  overscroll-behavior-x: none;
}

.p-0 {
  padding: 0;
}

.p-1 {
  padding: 0.25rem;
}

.p-2 {
  padding: 0.5rem;
}

.p-3 {
  padding: 0.75rem;
}

.p-4 {
  padding: 1rem;
}

.p-5 {
  padding: 1.25rem;
}

.p-6 {
  padding: 1.5rem;
}

.p-8 {
  padding: 2rem;
}

.p-10 {
  padding: 2.5rem;
}

.p-12 {
  padding: 3rem;
}

.p-16 {
  padding: 4rem;
}

.p-20 {
  padding: 5rem;
}

.p-24 {
  padding: 6rem;
}

.p-32 {
  padding: 8rem;
}

.p-40 {
  padding: 10rem;
}

.p-48 {
  padding: 12rem;
}

.p-56 {
  padding: 14rem;
}

.p-64 {
  padding: 16rem;
}

.p-px {
  padding: 1px;
}

.py-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.px-0 {
  padding-left: 0;
  padding-right: 0;
}

.py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.px-1 {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}

.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.py-3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.px-10 {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.py-12 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.px-12 {
  padding-left: 3rem;
  padding-right: 3rem;
}

.py-16 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.px-16 {
  padding-left: 4rem;
  padding-right: 4rem;
}

.py-20 {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.px-20 {
  padding-left: 5rem;
  padding-right: 5rem;
}

.py-24 {
  padding-top: 6rem;
  padding-bottom: 6rem;
}

.px-24 {
  padding-left: 6rem;
  padding-right: 6rem;
}

.py-32 {
  padding-top: 8rem;
  padding-bottom: 8rem;
}

.px-32 {
  padding-left: 8rem;
  padding-right: 8rem;
}

.py-40 {
  padding-top: 10rem;
  padding-bottom: 10rem;
}

.px-40 {
  padding-left: 10rem;
  padding-right: 10rem;
}

.py-48 {
  padding-top: 12rem;
  padding-bottom: 12rem;
}

.px-48 {
  padding-left: 12rem;
  padding-right: 12rem;
}

.py-56 {
  padding-top: 14rem;
  padding-bottom: 14rem;
}

.px-56 {
  padding-left: 14rem;
  padding-right: 14rem;
}

.py-64 {
  padding-top: 16rem;
  padding-bottom: 16rem;
}

.px-64 {
  padding-left: 16rem;
  padding-right: 16rem;
}

.py-px {
  padding-top: 1px;
  padding-bottom: 1px;
}

.px-px {
  padding-left: 1px;
  padding-right: 1px;
}

.pt-0 {
  padding-top: 0;
}

.pr-0 {
  padding-right: 0;
}

.pb-0 {
  padding-bottom: 0;
}

.pl-0 {
  padding-left: 0;
}

.pt-1 {
  padding-top: 0.25rem;
}

.pr-1 {
  padding-right: 0.25rem;
}

.pb-1 {
  padding-bottom: 0.25rem;
}

.pl-1 {
  padding-left: 0.25rem;
}

.pt-2 {
  padding-top: 0.5rem;
}

.pr-2 {
  padding-right: 0.5rem;
}

.pb-2 {
  padding-bottom: 0.5rem;
}

.pl-2 {
  padding-left: 0.5rem;
}

.pt-3 {
  padding-top: 0.75rem;
}

.pr-3 {
  padding-right: 0.75rem;
}

.pb-3 {
  padding-bottom: 0.75rem;
}

.pl-3 {
  padding-left: 0.75rem;
}

.pt-4 {
  padding-top: 1rem;
}

.pr-4 {
  padding-right: 1rem;
}

.pb-4 {
  padding-bottom: 1rem;
}

.pl-4 {
  padding-left: 1rem;
}

.pt-5 {
  padding-top: 1.25rem;
}

.pr-5 {
  padding-right: 1.25rem;
}

.pb-5 {
  padding-bottom: 1.25rem;
}

.pl-5 {
  padding-left: 1.25rem;
}

.pt-6 {
  padding-top: 1.5rem;
}

.pr-6 {
  padding-right: 1.5rem;
}

.pb-6 {
  padding-bottom: 1.5rem;
}

.pl-6 {
  padding-left: 1.5rem;
}

.pt-8 {
  padding-top: 2rem;
}

.pr-8 {
  padding-right: 2rem;
}

.pb-8 {
  padding-bottom: 2rem;
}

.pl-8 {
  padding-left: 2rem;
}

.pt-10 {
  padding-top: 2.5rem;
}

.pr-10 {
  padding-right: 2.5rem;
}

.pb-10 {
  padding-bottom: 2.5rem;
}

.pl-10 {
  padding-left: 2.5rem;
}

.pt-12 {
  padding-top: 3rem;
}

.pr-12 {
  padding-right: 3rem;
}

.pb-12 {
  padding-bottom: 3rem;
}

.pl-12 {
  padding-left: 3rem;
}

.pt-16 {
  padding-top: 4rem;
}

.pr-16 {
  padding-right: 4rem;
}

.pb-16 {
  padding-bottom: 4rem;
}

.pl-16 {
  padding-left: 4rem;
}

.pt-20 {
  padding-top: 5rem;
}

.pr-20 {
  padding-right: 5rem;
}

.pb-20 {
  padding-bottom: 5rem;
}

.pl-20 {
  padding-left: 5rem;
}

.pt-24 {
  padding-top: 6rem;
}

.pr-24 {
  padding-right: 6rem;
}

.pb-24 {
  padding-bottom: 6rem;
}

.pl-24 {
  padding-left: 6rem;
}

.pt-32 {
  padding-top: 8rem;
}

.pr-32 {
  padding-right: 8rem;
}

.pb-32 {
  padding-bottom: 8rem;
}

.pl-32 {
  padding-left: 8rem;
}

.pt-40 {
  padding-top: 10rem;
}

.pr-40 {
  padding-right: 10rem;
}

.pb-40 {
  padding-bottom: 10rem;
}

.pl-40 {
  padding-left: 10rem;
}

.pt-48 {
  padding-top: 12rem;
}

.pr-48 {
  padding-right: 12rem;
}

.pb-48 {
  padding-bottom: 12rem;
}

.pl-48 {
  padding-left: 12rem;
}

.pt-56 {
  padding-top: 14rem;
}

.pr-56 {
  padding-right: 14rem;
}

.pb-56 {
  padding-bottom: 14rem;
}

.pl-56 {
  padding-left: 14rem;
}

.pt-64 {
  padding-top: 16rem;
}

.pr-64 {
  padding-right: 16rem;
}

.pb-64 {
  padding-bottom: 16rem;
}

.pl-64 {
  padding-left: 16rem;
}

.pt-px {
  padding-top: 1px;
}

.pr-px {
  padding-right: 1px;
}

.pb-px {
  padding-bottom: 1px;
}

.pl-px {
  padding-left: 1px;
}

.pointer-events-none {
  pointer-events: none;
}

.pointer-events-auto {
  pointer-events: auto;
}

.static {
  position: static;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.sticky {
  position: sticky;
}
.top-0 {
  top: 0;
}

.right-0 {
  right: 0;
}

.bottom-0 {
  bottom: 0;
}

.left-0 {
  left: 0;
}

.top-auto {
  top: auto;
}

.right-auto {
  right: auto;
}

.bottom-auto {
  bottom: auto;
}

.left-auto {
  left: auto;
}

.stroke-0 {
  stroke-width: 0;
}

.stroke-1 {
  stroke-width: 1;
}

.stroke-2 {
  stroke-width: 2;
}

.table-auto {
  table-layout: auto;
}

.table-fixed {
  table-layout: fixed;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-justify {
  text-align: justify;
}

.text-transparent {
  color: transparent;
}

.text-current {
  color: currentColor;
}

.text-black {
  --text-opacity: 1;
  color: #000;
  color: rgba(0, 0, 0, var(--text-opacity));
}

.text-white {
  --text-opacity: 1;
  color: #fff;
  color: rgba(255, 255, 255, var(--text-opacity));
}

.text-gray-100 {
  --text-opacity: 1;
  color: #f7fafc;
  color: rgba(247, 250, 252, var(--text-opacity));
}

.text-gray-200 {
  --text-opacity: 1;
  color: #edf2f7;
  color: rgba(237, 242, 247, var(--text-opacity));
}

.text-gray-300 {
  --text-opacity: 1;
  color: #e2e8f0;
  color: rgba(226, 232, 240, var(--text-opacity));
}

.text-gray-400 {
  --text-opacity: 1;
  color: #cbd5e0;
  color: rgba(203, 213, 224, var(--text-opacity));
}

.text-gray-500 {
  --text-opacity: 1;
  color: #a0aec0;
  color: rgba(160, 174, 192, var(--text-opacity));
}

.text-gray-600 {
  --text-opacity: 1;
  color: #718096;
  color: rgba(113, 128, 150, var(--text-opacity));
}

.text-gray-700 {
  --text-opacity: 1;
  color: #4a5568;
  color: rgba(74, 85, 104, var(--text-opacity));
}

.text-gray-800 {
  --text-opacity: 1;
  color: #2d3748;
  color: rgba(45, 55, 72, var(--text-opacity));
}

.text-gray-900 {
  --text-opacity: 1;
  color: #1a202c;
  color: rgba(26, 32, 44, var(--text-opacity));
}

.text-red-100 {
  --text-opacity: 1;
  color: #fff5f5;
  color: rgba(255, 245, 245, var(--text-opacity));
}

.text-red-200 {
  --text-opacity: 1;
  color: #fed7d7;
  color: rgba(254, 215, 215, var(--text-opacity));
}

.text-red-300 {
  --text-opacity: 1;
  color: #feb2b2;
  color: rgba(254, 178, 178, var(--text-opacity));
}

.text-red-400 {
  --text-opacity: 1;
  color: #fc8181;
  color: rgba(252, 129, 129, var(--text-opacity));
}

.text-red-500 {
  --text-opacity: 1;
  color: #f56565;
  color: rgba(245, 101, 101, var(--text-opacity));
}

.text-red-600 {
  --text-opacity: 1;
  color: #e53e3e;
  color: rgba(229, 62, 62, var(--text-opacity));
}

.text-red-700 {
  --text-opacity: 1;
  color: #c53030;
  color: rgba(197, 48, 48, var(--text-opacity));
}

.text-red-800 {
  --text-opacity: 1;
  color: #9b2c2c;
  color: rgba(155, 44, 44, var(--text-opacity));
}

.text-red-900 {
  --text-opacity: 1;
  color: #742a2a;
  color: rgba(116, 42, 42, var(--text-opacity));
}

.text-orange-100 {
  --text-opacity: 1;
  color: #fffaf0;
  color: rgba(255, 250, 240, var(--text-opacity));
}

.text-orange-200 {
  --text-opacity: 1;
  color: #feebc8;
  color: rgba(254, 235, 200, var(--text-opacity));
}

.text-orange-300 {
  --text-opacity: 1;
  color: #fbd38d;
  color: rgba(251, 211, 141, var(--text-opacity));
}

.text-orange-400 {
  --text-opacity: 1;
  color: #f6ad55;
  color: rgba(246, 173, 85, var(--text-opacity));
}

.text-orange-500 {
  --text-opacity: 1;
  color: #ed8936;
  color: rgba(237, 137, 54, var(--text-opacity));
}

.text-orange-600 {
  --text-opacity: 1;
  color: #dd6b20;
  color: rgba(221, 107, 32, var(--text-opacity));
}

.text-orange-700 {
  --text-opacity: 1;
  color: #c05621;
  color: rgba(192, 86, 33, var(--text-opacity));
}

.text-orange-800 {
  --text-opacity: 1;
  color: #9c4221;
  color: rgba(156, 66, 33, var(--text-opacity));
}

.text-orange-900 {
  --text-opacity: 1;
  color: #7b341e;
  color: rgba(123, 52, 30, var(--text-opacity));
}

.text-yellow-100 {
  --text-opacity: 1;
  color: #fffff0;
  color: rgba(255, 255, 240, var(--text-opacity));
}

.text-yellow-200 {
  --text-opacity: 1;
  color: #fefcbf;
  color: rgba(254, 252, 191, var(--text-opacity));
}

.text-yellow-300 {
  --text-opacity: 1;
  color: #faf089;
  color: rgba(250, 240, 137, var(--text-opacity));
}

.text-yellow-400 {
  --text-opacity: 1;
  color: #f6e05e;
  color: rgba(246, 224, 94, var(--text-opacity));
}

.text-yellow-500 {
  --text-opacity: 1;
  color: #ecc94b;
  color: rgba(236, 201, 75, var(--text-opacity));
}

.text-yellow-600 {
  --text-opacity: 1;
  color: #d69e2e;
  color: rgba(214, 158, 46, var(--text-opacity));
}

.text-yellow-700 {
  --text-opacity: 1;
  color: #b7791f;
  color: rgba(183, 121, 31, var(--text-opacity));
}

.text-yellow-800 {
  --text-opacity: 1;
  color: #975a16;
  color: rgba(151, 90, 22, var(--text-opacity));
}

.text-yellow-900 {
  --text-opacity: 1;
  color: #744210;
  color: rgba(116, 66, 16, var(--text-opacity));
}

.text-green-100 {
  --text-opacity: 1;
  color: #f0fff4;
  color: rgba(240, 255, 244, var(--text-opacity));
}

.text-green-200 {
  --text-opacity: 1;
  color: #c6f6d5;
  color: rgba(198, 246, 213, var(--text-opacity));
}

.text-green-300 {
  --text-opacity: 1;
  color: #9ae6b4;
  color: rgba(154, 230, 180, var(--text-opacity));
}

.text-green-400 {
  --text-opacity: 1;
  color: #68d391;
  color: rgba(104, 211, 145, var(--text-opacity));
}

.text-green-500 {
  --text-opacity: 1;
  color: #48bb78;
  color: rgba(72, 187, 120, var(--text-opacity));
}

.text-green-600 {
  --text-opacity: 1;
  color: #38a169;
  color: rgba(56, 161, 105, var(--text-opacity));
}

.text-green-700 {
  --text-opacity: 1;
  color: #2f855a;
  color: rgba(47, 133, 90, var(--text-opacity));
}

.text-green-800 {
  --text-opacity: 1;
  color: #276749;
  color: rgba(39, 103, 73, var(--text-opacity));
}

.text-green-900 {
  --text-opacity: 1;
  color: #22543d;
  color: rgba(34, 84, 61, var(--text-opacity));
}

.text-teal-100 {
  --text-opacity: 1;
  color: #e6fffa;
  color: rgba(230, 255, 250, var(--text-opacity));
}

.text-teal-200 {
  --text-opacity: 1;
  color: #b2f5ea;
  color: rgba(178, 245, 234, var(--text-opacity));
}

.text-teal-300 {
  --text-opacity: 1;
  color: #81e6d9;
  color: rgba(129, 230, 217, var(--text-opacity));
}

.text-teal-400 {
  --text-opacity: 1;
  color: #4fd1c5;
  color: rgba(79, 209, 197, var(--text-opacity));
}

.text-teal-500 {
  --text-opacity: 1;
  color: #38b2ac;
  color: rgba(56, 178, 172, var(--text-opacity));
}

.text-teal-600 {
  --text-opacity: 1;
  color: #319795;
  color: rgba(49, 151, 149, var(--text-opacity));
}

.text-teal-700 {
  --text-opacity: 1;
  color: #2c7a7b;
  color: rgba(44, 122, 123, var(--text-opacity));
}

.text-teal-800 {
  --text-opacity: 1;
  color: #285e61;
  color: rgba(40, 94, 97, var(--text-opacity));
}

.text-teal-900 {
  --text-opacity: 1;
  color: #234e52;
  color: rgba(35, 78, 82, var(--text-opacity));
}

.text-blue-100 {
  --text-opacity: 1;
  color: #ebf8ff;
  color: rgba(235, 248, 255, var(--text-opacity));
}

.text-blue-200 {
  --text-opacity: 1;
  color: #bee3f8;
  color: rgba(190, 227, 248, var(--text-opacity));
}

.text-blue-300 {
  --text-opacity: 1;
  color: #90cdf4;
  color: rgba(144, 205, 244, var(--text-opacity));
}

.text-blue-400 {
  --text-opacity: 1;
  color: #63b3ed;
  color: rgba(99, 179, 237, var(--text-opacity));
}

.text-blue-500 {
  --text-opacity: 1;
  color: #4299e1;
  color: rgba(66, 153, 225, var(--text-opacity));
}

.text-blue-600 {
  --text-opacity: 1;
  color: #3182ce;
  color: rgba(49, 130, 206, var(--text-opacity));
}

.text-blue-700 {
  --text-opacity: 1;
  color: #2b6cb0;
  color: rgba(43, 108, 176, var(--text-opacity));
}

.text-blue-800 {
  --text-opacity: 1;
  color: #2c5282;
  color: rgba(44, 82, 130, var(--text-opacity));
}

.text-blue-900 {
  --text-opacity: 1;
  color: #2a4365;
  color: rgba(42, 67, 101, var(--text-opacity));
}

.text-indigo-100 {
  --text-opacity: 1;
  color: #ebf4ff;
  color: rgba(235, 244, 255, var(--text-opacity));
}

.text-indigo-200 {
  --text-opacity: 1;
  color: #c3dafe;
  color: rgba(195, 218, 254, var(--text-opacity));
}

.text-indigo-300 {
  --text-opacity: 1;
  color: #a3bffa;
  color: rgba(163, 191, 250, var(--text-opacity));
}

.text-indigo-400 {
  --text-opacity: 1;
  color: #7f9cf5;
  color: rgba(127, 156, 245, var(--text-opacity));
}

.text-indigo-500 {
  --text-opacity: 1;
  color: #667eea;
  color: rgba(102, 126, 234, var(--text-opacity));
}

.text-indigo-600 {
  --text-opacity: 1;
  color: #5a67d8;
  color: rgba(90, 103, 216, var(--text-opacity));
}

.text-indigo-700 {
  --text-opacity: 1;
  color: #4c51bf;
  color: rgba(76, 81, 191, var(--text-opacity));
}

.text-indigo-800 {
  --text-opacity: 1;
  color: #434190;
  color: rgba(67, 65, 144, var(--text-opacity));
}

.text-indigo-900 {
  --text-opacity: 1;
  color: #3c366b;
  color: rgba(60, 54, 107, var(--text-opacity));
}

.text-purple-100 {
  --text-opacity: 1;
  color: #faf5ff;
  color: rgba(250, 245, 255, var(--text-opacity));
}

.text-purple-200 {
  --text-opacity: 1;
  color: #e9d8fd;
  color: rgba(233, 216, 253, var(--text-opacity));
}

.text-purple-300 {
  --text-opacity: 1;
  color: #d6bcfa;
  color: rgba(214, 188, 250, var(--text-opacity));
}

.text-purple-400 {
  --text-opacity: 1;
  color: #b794f4;
  color: rgba(183, 148, 244, var(--text-opacity));
}

.text-purple-500 {
  --text-opacity: 1;
  color: #9f7aea;
  color: rgba(159, 122, 234, var(--text-opacity));
}

.text-purple-600 {
  --text-opacity: 1;
  color: #805ad5;
  color: rgba(128, 90, 213, var(--text-opacity));
}

.text-purple-700 {
  --text-opacity: 1;
  color: #6b46c1;
  color: rgba(107, 70, 193, var(--text-opacity));
}

.text-purple-800 {
  --text-opacity: 1;
  color: #553c9a;
  color: rgba(85, 60, 154, var(--text-opacity));
}

.text-purple-900 {
  --text-opacity: 1;
  color: #44337a;
  color: rgba(68, 51, 122, var(--text-opacity));
}

.text-pink-100 {
  --text-opacity: 1;
  color: #fff5f7;
  color: rgba(255, 245, 247, var(--text-opacity));
}

.text-pink-200 {
  --text-opacity: 1;
  color: #fed7e2;
  color: rgba(254, 215, 226, var(--text-opacity));
}

.text-pink-300 {
  --text-opacity: 1;
  color: #fbb6ce;
  color: rgba(251, 182, 206, var(--text-opacity));
}

.text-pink-400 {
  --text-opacity: 1;
  color: #f687b3;
  color: rgba(246, 135, 179, var(--text-opacity));
}

.text-pink-500 {
  --text-opacity: 1;
  color: #ed64a6;
  color: rgba(237, 100, 166, var(--text-opacity));
}

.text-pink-600 {
  --text-opacity: 1;
  color: #d53f8c;
  color: rgba(213, 63, 140, var(--text-opacity));
}

.text-pink-700 {
  --text-opacity: 1;
  color: #b83280;
  color: rgba(184, 50, 128, var(--text-opacity));
}

.text-pink-800 {
  --text-opacity: 1;
  color: #97266d;
  color: rgba(151, 38, 109, var(--text-opacity));
}

.text-pink-900 {
  --text-opacity: 1;
  color: #702459;
  color: rgba(112, 36, 89, var(--text-opacity));
}

.italic {
  font-style: italic;
}

.not-italic {
  font-style: normal;
}

.uppercase {
  text-transform: uppercase;
}

.lowercase {
  text-transform: lowercase;
}

.capitalize {
  text-transform: capitalize;
}

.normal-case {
  text-transform: none;
}

.underline {
  text-decoration: underline;
}

.line-through {
  text-decoration: line-through;
}

.no-underline {
  text-decoration: none;
}

.hover\:underline:hover {
  text-decoration: underline;
}

.hover\:line-through:hover {
  text-decoration: line-through;
}

.hover\:no-underline:hover {
  text-decoration: none;
}

.focus\:underline:focus {
  text-decoration: underline;
}

.focus\:line-through:focus {
  text-decoration: line-through;
}

.focus\:no-underline:focus {
  text-decoration: none;
}

.antialiased {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.subpixel-antialiased {
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
}

.ordinal, .slashed-zero, .lining-nums, .oldstyle-nums, .proportional-nums, .tabular-nums, .diagonal-fractions, .stacked-fractions {
  --font-variant-numeric-ordinal: var(--tailwind-empty,/*!*/ /*!*/);
  --font-variant-numeric-slashed-zero: var(--tailwind-empty,/*!*/ /*!*/);
  --font-variant-numeric-figure: var(--tailwind-empty,/*!*/ /*!*/);
  --font-variant-numeric-spacing: var(--tailwind-empty,/*!*/ /*!*/);
  --font-variant-numeric-fraction: var(--tailwind-empty,/*!*/ /*!*/);
  font-variant-numeric: var(--font-variant-numeric-ordinal) var(--font-variant-numeric-slashed-zero) var(--font-variant-numeric-figure) var(--font-variant-numeric-spacing) var(--font-variant-numeric-fraction);
}

.normal-nums {
  font-variant-numeric: normal;
}

.ordinal {
  --font-variant-numeric-ordinal: ordinal;
}

.slashed-zero {
  --font-variant-numeric-slashed-zero: slashed-zero;
}

.lining-nums {
  --font-variant-numeric-figure: lining-nums;
}

.oldstyle-nums {
  --font-variant-numeric-figure: oldstyle-nums;
}

.proportional-nums {
  --font-variant-numeric-spacing: proportional-nums;
}

.tabular-nums {
  --font-variant-numeric-spacing: tabular-nums;
}

.diagonal-fractions {
  --font-variant-numeric-fraction: diagonal-fractions;
}

.stacked-fractions {
  --font-variant-numeric-fraction: stacked-fractions;
}

.tracking-tighter {
  letter-spacing: -0.05em;
}

.tracking-tight {
  letter-spacing: -0.025em;
}

.tracking-normal {
  letter-spacing: 0;
}

.tracking-wide {
  letter-spacing: 0.025em;
}

.tracking-wider {
  letter-spacing: 0.05em;
}

.tracking-widest {
  letter-spacing: 0.1em;
}

.select-none {
  -webkit-user-select: none;
          user-select: none;
}

.select-text {
  -webkit-user-select: text;
          user-select: text;
}

.select-all {
  -webkit-user-select: all;
          user-select: all;
}

.select-auto {
  -webkit-user-select: auto;
          user-select: auto;
}

.align-baseline {
  vertical-align: baseline;
}

.align-top {
  vertical-align: top;
}

.align-middle {
  vertical-align: middle;
}

.align-bottom {
  vertical-align: bottom;
}

.align-text-top {
  vertical-align: text-top;
}

.align-text-bottom {
  vertical-align: text-bottom;
}

.visible {
  visibility: visible;
}

.invisible {
  visibility: hidden;
}

.whitespace-normal {
  white-space: normal;
}

.whitespace-no-wrap {
  white-space: nowrap;
}

.whitespace-pre {
  white-space: pre;
}

.whitespace-pre-line {
  white-space: pre-line;
}

.whitespace-pre-wrap {
  white-space: pre-wrap;
}

.break-normal {
  word-wrap: normal;
  overflow-wrap: normal;
  word-break: normal;
}

.break-words {
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.break-all {
  word-break: break-all;
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.gap-x-32 {
  grid-column-gap: 8rem;
  column-gap: 8rem;
}

.gap-x-40 {
  grid-column-gap: 10rem;
  column-gap: 10rem;
}

.gap-x-48 {
  grid-column-gap: 12rem;
  column-gap: 12rem;
}

.gap-x-56 {
  grid-column-gap: 14rem;
  column-gap: 14rem;
}

.gap-x-64 {
  grid-column-gap: 16rem;
  column-gap: 16rem;
}

.gap-x-px {
  grid-column-gap: 1px;
  column-gap: 1px;
}

.row-gap-0 {
  grid-row-gap: 0;
  row-gap: 0;
}

.row-gap-1 {
  grid-row-gap: 0.25rem;
  row-gap: 0.25rem;
}

.row-gap-2 {
  grid-row-gap: 0.5rem;
  row-gap: 0.5rem;
}

.row-gap-3 {
  grid-row-gap: 0.75rem;
  row-gap: 0.75rem;
}

.row-gap-4 {
  grid-row-gap: 1rem;
  row-gap: 1rem;
}

.row-gap-5 {
  grid-row-gap: 1.25rem;
  row-gap: 1.25rem;
}

.row-gap-6 {
  grid-row-gap: 1.5rem;
  row-gap: 1.5rem;
}

.row-gap-8 {
  grid-row-gap: 2rem;
  row-gap: 2rem;
}

.row-gap-10 {
  grid-row-gap: 2.5rem;
  row-gap: 2.5rem;
}

.row-gap-12 {
  grid-row-gap: 3rem;
  row-gap: 3rem;
}

.row-gap-16 {
  grid-row-gap: 4rem;
  row-gap: 4rem;
}

.row-gap-20 {
  grid-row-gap: 5rem;
  row-gap: 5rem;
}

.row-gap-24 {
  grid-row-gap: 6rem;
  row-gap: 6rem;
}

.row-gap-32 {
  grid-row-gap: 8rem;
  row-gap: 8rem;
}

.row-gap-40 {
  grid-row-gap: 10rem;
  row-gap: 10rem;
}

.row-gap-48 {
  grid-row-gap: 12rem;
  row-gap: 12rem;
}

.row-gap-56 {
  grid-row-gap: 14rem;
  row-gap: 14rem;
}

.row-gap-64 {
  grid-row-gap: 16rem;
  row-gap: 16rem;
}

.row-gap-px {
  grid-row-gap: 1px;
  row-gap: 1px;
}

.gap-y-0 {
  grid-row-gap: 0;
  row-gap: 0;
}

.gap-y-1 {
  grid-row-gap: 0.25rem;
  row-gap: 0.25rem;
}

.gap-y-2 {
  grid-row-gap: 0.5rem;
  row-gap: 0.5rem;
}

.gap-y-3 {
  grid-row-gap: 0.75rem;
  row-gap: 0.75rem;
}

.gap-y-4 {
  grid-row-gap: 1rem;
  row-gap: 1rem;
}

.gap-y-5 {
  grid-row-gap: 1.25rem;
  row-gap: 1.25rem;
}

.gap-y-6 {
  grid-row-gap: 1.5rem;
  row-gap: 1.5rem;
}

.gap-y-8 {
  grid-row-gap: 2rem;
  row-gap: 2rem;
}

.gap-y-10 {
  grid-row-gap: 2.5rem;
  row-gap: 2.5rem;
}

.gap-y-12 {
  grid-row-gap: 3rem;
  row-gap: 3rem;
}

.gap-y-16 {
  grid-row-gap: 4rem;
  row-gap: 4rem;
}

.gap-y-20 {
  grid-row-gap: 5rem;
  row-gap: 5rem;
}

.gap-y-24 {
  grid-row-gap: 6rem;
  row-gap: 6rem;
}

.gap-y-32 {
  grid-row-gap: 8rem;
  row-gap: 8rem;
}

.gap-y-40 {
  grid-row-gap: 10rem;
  row-gap: 10rem;
}

.gap-y-48 {
  grid-row-gap: 12rem;
  row-gap: 12rem;
}

.gap-y-56 {
  grid-row-gap: 14rem;
  row-gap: 14rem;
}

.gap-y-64 {
  grid-row-gap: 16rem;
  row-gap: 16rem;
}

.gap-y-px {
  grid-row-gap: 1px;
  row-gap: 1px;
}

.grid-flow-row {
  grid-auto-flow: row;
}

.grid-flow-col {
  grid-auto-flow: column;
}

.grid-flow-row-dense {
  grid-auto-flow: row dense;
}

.grid-flow-col-dense {
  grid-auto-flow: column dense;
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.grid-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.grid-cols-5 {
  grid-template-columns: repeat(5, minmax(0, 1fr));
}

.grid-cols-6 {
  grid-template-columns: repeat(6, minmax(0, 1fr));
}

.grid-cols-7 {
  grid-template-columns: repeat(7, minmax(0, 1fr));
}

.grid-cols-8 {
  grid-template-columns: repeat(8, minmax(0, 1fr));
}

.grid-cols-9 {
  grid-template-columns: repeat(9, minmax(0, 1fr));
}

.grid-cols-10 {
  grid-template-columns: repeat(10, minmax(0, 1fr));
}

.grid-cols-11 {
  grid-template-columns: repeat(11, minmax(0, 1fr));
}

.grid-cols-12 {
  grid-template-columns: repeat(12, minmax(0, 1fr));
}

.grid-cols-none {
  grid-template-columns: none;
}

.auto-cols-auto {
  grid-auto-columns: auto;
}

.auto-cols-min {
  grid-auto-columns: min-content;
}

.auto-cols-max {
  grid-auto-columns: max-content;
}

.auto-cols-fr {
  grid-auto-columns: minmax(0, 1fr);
}

.col-auto {
  grid-column: auto;
}

.col-span-1 {
  grid-column: span 1 / span 1;
}

.col-span-2 {
  grid-column: span 2 / span 2;
}

.col-span-3 {
  grid-column: span 3 / span 3;
}

.col-span-4 {
  grid-column: span 4 / span 4;
}

.col-span-5 {
  grid-column: span 5 / span 5;
}

.col-span-6 {
  grid-column: span 6 / span 6;
}

.col-span-7 {
  grid-column: span 7 / span 7;
}

.col-span-8 {
  grid-column: span 8 / span 8;
}

.col-span-9 {
  grid-column: span 9 / span 9;
}

.col-span-10 {
  grid-column: span 10 / span 10;
}

.col-span-11 {
  grid-column: span 11 / span 11;
}

.col-span-12 {
  grid-column: span 12 / span 12;
}

.col-span-full {
  grid-column: 1 / -1;
}

.col-start-1 {
  grid-column-start: 1;
}

.col-start-2 {
  grid-column-start: 2;
}

.col-start-3 {
  grid-column-start: 3;
}

.col-start-4 {
  grid-column-start: 4;
}

.col-start-5 {
  grid-column-start: 5;
}

.col-start-6 {
  grid-column-start: 6;
}

.col-start-7 {
  grid-column-start: 7;
}

.col-start-8 {
  grid-column-start: 8;
}

.col-start-9 {
  grid-column-start: 9;
}

.col-start-10 {
  grid-column-start: 10;
}

.col-start-11 {
  grid-column-start: 11;
}

.col-start-12 {
  grid-column-start: 12;
}

.col-start-13 {
  grid-column-start: 13;
}

.col-start-auto {
  grid-column-start: auto;
}

.col-end-1 {
  grid-column-end: 1;
}

.col-end-2 {
  grid-column-end: 2;
}

.col-end-3 {
  grid-column-end: 3;
}

.col-end-4 {
  grid-column-end: 4;
}

.col-end-5 {
  grid-column-end: 5;
}

.col-end-6 {
  grid-column-end: 6;
}

.col-end-7 {
  grid-column-end: 7;
}

.col-end-8 {
  grid-column-end: 8;
}

.col-end-9 {
  grid-column-end: 9;
}

.col-end-10 {
  grid-column-end: 10;
}

.col-end-11 {
  grid-column-end: 11;
}

.col-end-12 {
  grid-column-end: 12;
}

.col-end-13 {
  grid-column-end: 13;
}

.col-end-auto {
  grid-column-end: auto;
}

.grid-rows-1 {
  grid-template-rows: repeat(1, minmax(0, 1fr));
}

.grid-rows-2 {
  grid-template-rows: repeat(2, minmax(0, 1fr));
}

.grid-rows-3 {
  grid-template-rows: repeat(3, minmax(0, 1fr));
}

.grid-rows-4 {
  grid-template-rows: repeat(4, minmax(0, 1fr));
}

.grid-rows-5 {
  grid-template-rows: repeat(5, minmax(0, 1fr));
}

.grid-rows-6 {
  grid-template-rows: repeat(6, minmax(0, 1fr));
}

.grid-rows-none {
  grid-template-rows: none;
}

.auto-rows-auto {
  grid-auto-rows: auto;
}

.auto-rows-min {
  grid-auto-rows: min-content;
}

.auto-rows-max {
  grid-auto-rows: max-content;
}

.auto-rows-fr {
  grid-auto-rows: minmax(0, 1fr);
}

.row-auto {
  grid-row: auto;
}

.row-span-1 {
  grid-row: span 1 / span 1;
}

.row-span-2 {
  grid-row: span 2 / span 2;
}

.row-span-3 {
  grid-row: span 3 / span 3;
}

.row-span-4 {
  grid-row: span 4 / span 4;
}

.row-span-5 {
  grid-row: span 5 / span 5;
}

.row-span-6 {
  grid-row: span 6 / span 6;
}

.row-span-full {
  grid-row: 1 / -1;
}

.row-start-1 {
  grid-row-start: 1;
}

.row-start-2 {
  grid-row-start: 2;
}

.row-start-3 {
  grid-row-start: 3;
}

.row-start-4 {
  grid-row-start: 4;
}

.row-start-5 {
  grid-row-start: 5;
}

.row-start-6 {
  grid-row-start: 6;
}

.row-start-7 {
  grid-row-start: 7;
}

.row-start-auto {
  grid-row-start: auto;
}

.row-end-1 {
  grid-row-end: 1;
}

.row-end-2 {
  grid-row-end: 2;
}



.float-left{
  position: relative;
  float: left;
}
 html {
  line-height: 1.15; /* 1 */
  -webkit-text-size-adjust: 100%; /* 2 */
}


body {
  margin: 0;
}


 main {
  display: block;
  /* overflow-y: scroll; */
  overflow-y: auto;
  position: relative;
  width: 100% !important;
}

h1 {
  font-size: 2em;
  margin: 0.67em 0;
}


hr {
  box-sizing: content-box; /* 1 */
  height: 0; /* 1 */
  overflow: visible; /* 2 */
}


pre {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}


a {
  background-color: transparent;
}

abbr[title] {
  border-bottom: none; /* 1 */
  text-decoration: underline; /* 2 */
  text-decoration: underline dotted; /* 2 */
}

b,
strong {
  font-weight: bolder;
}

code,
kbd,
samp {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}

small {
  font-size: 80%;
}


sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}


img {
  border-style: none;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit; /* 1 */
  font-size: 100%; /* 1 */
  line-height: 1.15; /* 1 */
  margin: 0; /* 2 */
}

button,
input { /* 1 */
  overflow: visible;
}


button,
select { /* 1 */
  text-transform: none;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

/**
 * Correct the padding in Firefox.
 */

fieldset {
  padding: 0.35em 0.75em 0.625em;
}


legend {
  box-sizing: border-box; /* 1 */
  color: inherit; /* 2 */
  display: table; /* 1 */
  max-width: 100%; /* 1 */
  padding: 0; /* 3 */
  white-space: normal; /* 1 */
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type="checkbox"],
[type="radio"] {
  box-sizing: border-box; /* 1 */
  padding: 0; /* 2 */
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}


[type="search"] {
  -webkit-appearance: textfield; /* 1 */
  outline-offset: -2px; /* 2 */
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}


::-webkit-file-upload-button {
  -webkit-appearance: button; /* 1 */
  font: inherit; /* 2 */
}

details {
  display: block;
}

summary {
  display: list-item;
}


template {
  display: none;
}


[hidden] {
  display: none;
}


blockquote,
dl,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
figure,
p,
pre {
  margin: 0;
}

button {
  background-color: transparent;
  background-image: none;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

fieldset {
  margin: 0;
  padding: 0;
}

ol,
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}


html {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; /* 1 */
  line-height: 1.5; /* 2 */
}

*,
::before,
::after {
  box-sizing: border-box; /* 1 */
  border-width: 0; /* 2 */
  border-style: solid; /* 2 */
  border-color: #e2e8f0; /* 2 */
}

hr {
  border-top-width: 1px;
}

img {
  border-style: solid;
}

textarea {
  resize: vertical;
}

input::placeholder,
textarea::placeholder {
  color: #a0aec0;
}

button,
[role="button"] {
  cursor: pointer;
}

table {
  border-collapse: collapse;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  font-weight: inherit;
}


a {
  color: inherit;
  text-decoration: inherit;
}

button,
input,
optgroup,
select,
textarea {
  padding: 0;
  line-height: inherit;
  color: inherit;
}

pre,
code,
kbd,
samp {
  font-family: Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

img,
svg,
video,
canvas,
audio,
iframe,
embed,
object {
  display: block;
  vertical-align: middle;
}

img,
video {
  max-width: 100%;
  height: auto;
}

.container {
  width: 100%;
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}

/* Stop purging. */

h4.d-flex.justify-content-center.bold {
  color: #005800;
  font-weight: 700;
}

/*# sourceMappingURL=css.map */
.required::after {
  content: '*';
  color: crimson;
  margin-left: 3px;
}

h3.log-text {
  font-weight: 800;
}

button.mb-2.me-2.btn.btn-primary.active.side {
  background-color: #548235 !important;
  border-radius: 5px;
}

.mb-low.row.mb-3 {
  margin-bottom: 0px !important;
}
a.form-label {
  font-style: italic;
  color: #000;
}

.ui-theme-settings {
  position: fixed;
  z-index: 155;
  right: -30px;
  top: 0;
  height: 100vh;
  transform: translate(500px);
  transition: all 0.2s;
  box-shadow: -0.46875rem 0 2.1875rem rgba(8, 10, 37, 0.03), -0.9375rem 0 1.40625rem rgba(8, 10, 37, 0.03), -0.25rem 0 0.53125rem rgba(8, 10, 37, 0.05), -0.125rem 0 0.1875rem rgba(8, 10, 37, 0.03);
}

.ui-theme-settings .btn-open-options {
  border-radius: 50px;
  position: absolute;
  left: -114px;
  bottom: 80px;
  padding: 0;
  height: 54px;
  line-height: 54px;
  width: 54px;
  text-align: center;
  display: block;
  box-shadow: 0 0.46875rem 2.1875rem rgba(8, 10, 37, 0.03), 0 0.9375rem 1.40625rem rgba(8, 10, 37, 0.03), 0 0.25rem 0.53125rem rgba(8, 10, 37, 0.05), 0 0.125rem 0.1875rem rgba(8, 10, 37, 0.03);
  margin-top: -27px;
}

.ui-theme-settings .btn-open-options svg {
  top: 50%;
  left: 50%;
  position: absolute;
  margin: -0.5em 0 0 -0.5em;
}

.ui-theme-settings .theme-settings__inner {
  background: #fff;
  width: 500px;
  height: 100vh;
  padding: 0;
}

.ui-theme-settings.settings-open {
  transform: translate(0);
  right: 0;
}

.ui-theme-settings .theme-settings-swatches {
  text-align: center;
}

.ui-theme-settings .theme-settings-swatches .swatch-holder-img {
  width: 72px;
  height: auto;
  border-radius: 3px;
}

.ui-theme-settings .theme-settings-swatches .swatch-holder-img img {
  width: 100%;
}

.ui-theme-settings .themeoptions-heading {
  font-size: 1.1rem;
  color: #495057;
  margin: 0;
  background: #f8f9fa;
  padding: 0.75rem 1.5rem;
  border-bottom: #dee2e6 solid 1px;
  border-top: #dee2e6 solid 1px;
  display: flex;
  align-items: center;
  align-content: center;
}

.ui-theme-settings .themeoptions-heading:first-child {
  border-top: 0;
}

.ui-theme-settings .list-group-item h5,
.ui-theme-settings .list-group-item .h5 {
  color: #545cd8;
  font-size: 0.968rem;
  text-transform: uppercase;
  margin: 0;
  text-align: center;
}

.swatch-holder {
  width: 24px;
  height: 24px;
  line-height: 24px;
  margin: 5px 5px 0;
  transition: all 0.2s;
  opacity: 0.7;
  display: inline-block;
  border-radius: 30px;
}

.swatch-holder.active {
  border: #fff solid 2px;
  box-shadow: 0 0 0 5px #545cd8;
  opacity: 1;
}

.swatch-holder:hover {
  opacity: 1;
}

.swatch-holder-lg {
  width: 48px;
  height: 48px;
  line-height: 48px;
}

.font-icon-wrapper {
  text-align: center;
  border: #e9ecef solid 1px;
  border-radius: 0.25rem;
  margin: 0 0 10px;
  padding: 5px;
}

.font-icon-wrapper.font-icon-lg {
  float: left;
  padding: 10px;
  text-align: center;
  margin-right: 15px;
  min-width: 64px;
}

.font-icon-wrapper.font-icon-lg i {
  font-size: 2.2rem;
}

.font-icon-wrapper:hover {
  background: #f8f9fa;
  color: #545cd8;
}

.font-icon-wrapper:hover p {
  color: #6c757d;
}

.font-icon-wrapper i {
  font-size: 1.65rem;
}

.font-icon-wrapper p {
  color: #adb5bd;
  font-size: 0.8066666667rem;
  margin: 5px 0 0;
}

.btn-icon-vertical {
  min-width: 100px;
}

.card.mb-3 {
  margin-bottom: 1.5rem !important;
}

.demo-image-bg {
  height: 350px;
  margin-bottom: 1.5rem;
}

.loader-wrapper {
  width: 150px;
  height: 100px;
  float: left;
}

.slider-item {
  background: #dee2e6;
  border-radius: 0.25rem;
  color: #6c757d;
  font-size: 36px;
  padding: 0;
  position: relative;
  height: 150px;
  line-height: 150px;
  text-align: center;
  margin: 0 1.5rem;
  transition: all 0.2s;
}

.slick-center .slider-item {
  background: #adb5bd;
  color: #495057;
}

.after-img {
  max-width: 100%;
}

@media only screen and (max-width: 1320px) {
  .header-user-info {
    display: none;
  }
}

@media (max-width: 767.98px) {
  .app-main {
    display: block;
  }

  .dropdown-menu::before,
  .dropdown-menu::after {
    display: none;
  }

  .app-sidebar {
    flex: 0 0 280px !important;
    width: 280px !important;
    transform: translateX(-280px);
  }

  .app-sidebar .app-header__logo {
    display: none;
  }

  .sidebar-mobile-open .app-sidebar {
    transform: translateX(0);
  }

  .sidebar-mobile-open .app-sidebar .app-sidebar__inner .app-sidebar__heading {
    text-indent: initial;
  }

  .sidebar-mobile-open .app-sidebar .app-sidebar__inner .app-sidebar__heading::before {
    display: none;
  }

  .sidebar-mobile-open .app-sidebar .app-sidebar__inner .metismenu-link {
    text-indent: initial;
    padding: 0 1.5rem 0 45px;
  }

  .sidebar-mobile-open .app-sidebar .app-sidebar__inner .metismenu-icon {
    text-indent: initial;
    left: 5px;
    margin-left: 0;
  }

  .sidebar-mobile-open .app-sidebar .app-sidebar__inner .metismenu-state-icon {
    visibility: visible;
  }

  .sidebar-mobile-open .app-sidebar .app-sidebar__inner .metismenu-container.visible {
    padding: 0.5em 0 0 2rem;
  }

  .sidebar-mobile-open .app-sidebar .app-sidebar__inner .metismenu-container.visible>.metismenu-item>.metismenu-link {
    height: 2.3em;
  }

  .sidebar-mobile-open .app-sidebar .app-sidebar__inner .metismenu-container .metismenu-container .metismenu-link {
    padding-left: 1em;
  }

  .sidebar-mobile-open .app-sidebar .app-header__logo {
    width: auto !important;
  }

  .sidebar-mobile-open .app-sidebar .app-header__logo .logo-src {
    width: 97px !important;
    margin-left: auto;
    margin-right: 0;
  }

  .sidebar-mobile-open .fixed-sidebar .app-sidebar {
    height: 100%;
  }

  .sidebar-mobile-open .sidebar-mobile-overlay {
    display: block;
  }

  .app-main .app-main__outer {
    padding-left: 0 !important;
    width: auto;
  }

  .app-header {
    justify-content: space-between;
  }

  .app-header .app-header__logo {
    display: none;
    order: 2;
    background: transparent !important;
    border: 0 !important;
  }

  .app-header .app-header__content {
    visibility: hidden;
    opacity: 0;
    box-shadow: 0 0.46875rem 2.1875rem rgba(8, 10, 37, 0.03), 0 0.9375rem 1.40625rem rgba(8, 10, 37, 0.03), 0 0.25rem 0.53125rem rgba(8, 10, 37, 0.05), 0 0.125rem 0.1875rem rgba(8, 10, 37, 0.03);
    position: absolute;
    left: 5%;
    width: 90%;
    top: 0;
    transition: all 0.2s;
    background: #fff;
    border-radius: 50px;
    padding: 0 10px;
    overflow: hidden;
  }

  .app-header .app-header__content .header-btn-lg {
    margin-left: 0.5rem;
    padding: 0 0.5rem;
  }

  .app-header .app-header__content .header-btn-lg .BurgerBox {
    margin-top: 5px;
  }

  .app-header .app-header__content .header-btn-lg+.header-btn-lg {
    display: none;
  }

  .app-header .app-header__content .app-header-left .nav {
    display: none;
  }

  .app-header .app-header__content.header-mobile-open {
    visibility: visible;
    opacity: 1;
    top: 80px;
  }

  .app-header .app-header__mobile-menu {
    display: flex;
    order: 1;
  }

  .app-header .app-header__menu {
    display: flex;
    order: 3;
  }

  .app-header.header-text-light .app-header__menu>span .btn,
  .app-header.header-text-light .app-header__menu>.btn {
    background: rgba(255, 255, 255, 0.1);
    border-color: rgba(255, 255, 255, 0.1);
  }

  .app-header.header-text-light .header-mobile-open {
    background: #343a40;
  }

  .popover,
  .dropdown-menu {
    position: fixed !important;
    z-index: 50;
    left: 5% !important;
    top: 50% !important;
    width: 90% !important;
    transform: translateY(-50%) !important;
    min-width: 10px !important;
  }

  .popover .btn-icon-vertical .btn-icon-wrapper,
  .dropdown-menu .btn-icon-vertical .btn-icon-wrapper {
    display: none;
  }

  .popover {
    max-width: initial;
  }

  .popover .arrow {
    display: none !important;
  }

  .app-page-title {
    text-align: center;
  }

  .app-page-title .page-title-heading,
  .app-page-title .page-title-wrapper {
    margin: 0 auto;
    display: block;
  }

  .app-page-title .page-title-actions {
    margin: 0.75rem auto 0;
  }

  .app-page-title .page-title-actions .breadcrumb-item,
  .app-page-title .page-title-actions .breadcrumb,
  .app-page-title .page-title-subheading .breadcrumb-item,
  .app-page-title .page-title-subheading .breadcrumb {
    display: inline-block;
  }

  .app-footer .app-footer__inner .app-footer-right {
    display: none;
  }

  .app-footer .app-footer__inner .app-footer-left {
    width: 100%;
  }

  .app-footer .app-footer__inner .app-footer-left .footer-dots {
    margin: 0 auto;
  }

  .fixed-footer.closed-sidebar .app-footer .app-footer__inner {
    margin-left: 0 !important;
  }

  .widget-content .widget-numbers {
    font-size: 1.6rem;
    line-height: 1;
  }

  .slick-slider-sm .slick-slider {
    max-width: 650px !important;
  }

  .body-tabs.body-tabs-layout.body-tabs-big {
    display: block !important;
  }

  .body-tabs.body-tabs-layout.body-tabs-big .RRT__tab {
    display: inline-block !important;
    padding: 0 1.5rem !important;
  }

  .body-tabs.body-tabs-layout.body-tabs-big .RRT__showmore {
    margin-top: -20px;
  }

  .ReactTable .-pagination .-center {
    display: none;
  }

  .ReactTable .-pagination .-previous {
    padding-right: 3px;
  }

  .bg-transparent.list-group-item {
    border-color: transparent;
  }

  .tabs-lg-alternate.card-header>.nav .nav-item .widget-number {
    font-size: 1.5rem;
  }

  .page-title-head {
    display: block;
  }
}

@media (max-width: 767.98px) {

  .app-page-title .page-title-icon,
  .ui-theme-settings {
    display: none;
  }

  .card-header.responsive-center {
    display: block;
    text-align: center;
    height: auto;
    padding: 1.5rem;
  }

  .card-header.responsive-center .nav,
  .card-header.responsive-center .btn-actions-pane-right {
    margin: 0.75rem 0 0;
  }

  .card-header.responsive-center .nav .d-inline-block.ml-2,
  .card-header.responsive-center .btn-actions-pane-right .d-inline-block.ml-2 {
    width: 100% !important;
    text-align: left;
    margin: 0 !important;
  }

  .slick-slider-sm .slick-slider {
    max-width: 650px !important;
  }
}

@media (min-width: 992px) {
  .slick-slider-sm .slick-slider {
    max-width: 850px !important;
  }
}

@media (max-width: 991.98px) {
  .-hide-paging .-pagination .-center {
    display: none;
  }
}

@media (max-width: 575.98px) {
  .app-main .app-main__inner {
    padding: 15px 15px 0;
  }

  .mbg-3,
  body .card.mb-3 {
    margin-bottom: 0.75rem !important;
  }

  .app-page-title {
    padding: 0.75rem;
    margin: -0.75rem -0.75rem 1.5rem;
  }

  .body-tabs.body-tabs-layout {
    margin-left: -0.75rem;
    margin-right: -0.75rem;
    padding: 0 0.75rem;
  }

  .app-main .app-main__inner>.RRT__container>.RRT__panel {
    margin-top: 0.75rem;
  }

  .popover,
  .dropdown-menu {
    width: 80%;
    left: 10%;
  }

  body .card-header {
    height: auto;
    display: block;
    padding: 0.75rem 1.5rem;
    text-align: center;
  }

  body .card-header .btn-actions-pane-right {
    padding: 0.75rem 0 0;
  }

  body .card-header .actions-icon-btn {
    padding: 0;
  }

  .card-header.card-header-tab .card-header-title {
    display: inline-flex !important;
    line-height: 1;
  }

  .card-header.card-header-tab>.nav {
    margin: 0.75rem 0 -0.75rem;
    display: table !important;
    width: 100%;
  }

  .card-header.card-header-tab>.nav .nav-item {
    display: table-cell;
  }

  .header-icon {
    display: none;
  }

  .profile-responsive-sm .dropdown-menu-header .menu-header-content.btn-pane-right,
  .profile-responsive .dropdown-menu-header .menu-header-content.btn-pane-right {
    display: block;
    text-align: center;
  }

  .profile-responsive-sm .dropdown-menu-header .menu-header-content.btn-pane-right .avatar-icon-wrapper,
  .profile-responsive .dropdown-menu-header .menu-header-content.btn-pane-right .avatar-icon-wrapper {
    margin-right: 0 !important;
  }

  .profile-responsive-sm .dropdown-menu-header .menu-header-content.btn-pane-right .menu-header-btn-pane,
  .profile-responsive .dropdown-menu-header .menu-header-content.btn-pane-right .menu-header-btn-pane {
    margin-top: 1rem;
  }

  .slick-slider-sm .slick-slider .slick-prev {
    left: 0.75rem;
  }

  .slick-slider-sm .slick-slider .slick-next {
    right: 0.75rem;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1500px) {
  .profile-responsive .dropdown-menu-header .menu-header-content.btn-pane-right {
    display: block;
    text-align: center;
  }

  .profile-responsive .dropdown-menu-header .menu-header-content.btn-pane-right .avatar-icon-wrapper {
    margin-right: 0 !important;
  }

  .profile-responsive .dropdown-menu-header .menu-header-content.btn-pane-right .menu-header-btn-pane {
    margin-top: 1rem;
  }
}
.table thead th,
.table td {
  vertical-align: middle;
}

.table th .filter {
  margin-top: 0.375rem;
  height: auto;
  padding: 0.3rem 0.5rem;
}

.ReactVirtualized__Table__headerTruncatedText {
  display: inline-block;
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.ReactVirtualized__Table__headerColumn,
.ReactVirtualized__Table__rowColumn {
  margin-right: 10px;
  min-width: 0px;
}

.ReactVirtualized__Table__rowColumn {
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ReactVirtualized__Table__headerColumn:first-of-type,
.ReactVirtualized__Table__rowColumn:first-of-type {
  margin-left: 10px;
}

.ReactVirtualized__Table__sortableHeaderColumn {
  cursor: pointer;
}

.ReactVirtualized__Table__sortableHeaderIconContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.ReactVirtualized__Table__sortableHeaderIcon {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 24px;
  flex: 0 0 24px;
  height: 1em;
  width: 1em;
  fill: currentColor;
}

/* List default theme */
.ReactTable {
  position: relative;
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.ReactTable .rt-table {
  flex: auto 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  border-collapse: collapse;
  overflow: auto;
}

.ReactTable .-pagination input,
.ReactTable .-pagination select,
.ReactTable .rt-thead.-filters input,
.ReactTable .rt-thead.-filters select {
  display: block;
  width: 100%;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  height: calc(1.5em + (0.5rem + 2px));
  padding: 0.25rem 0.5rem;
  border-radius: 0.2rem;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
}

.ReactTable .-pagination input::-ms-expand,
.ReactTable .-pagination select::-ms-expand,
.ReactTable .rt-thead.-filters input::-ms-expand,
.ReactTable .rt-thead.-filters select::-ms-expand {
  background-color: transparent;
  border: 0;
}

.ReactTable .-pagination input::placeholder,
.ReactTable .-pagination select::placeholder,
.ReactTable .rt-thead.-filters input::placeholder,
.ReactTable .rt-thead.-filters select::placeholder {
  color: #6c757d;
  opacity: 1;
}

.ReactTable .-pagination input:disabled,
.ReactTable .-pagination input[readonly],
.ReactTable .-pagination select:disabled,
.ReactTable .-pagination select[readonly],
.ReactTable .rt-thead.-filters input:disabled,
.ReactTable .rt-thead.-filters input[readonly],
.ReactTable .rt-thead.-filters select:disabled,
.ReactTable .rt-thead.-filters select[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

.ReactTable .rt-thead {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  user-select: none;
}

.ReactTable .rt-thead.-headerGroups {
  background: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid #e9ecef;
}

.ReactTable .rt-thead.-headerGroups .rt-th,
.ReactTable .rt-thead.-headerGroups .rt-td {
  padding: 0.3rem;
  color: #6c757d;
}

.ReactTable .rt-thead.-headerGroups .rt-th:hover,
.ReactTable .rt-thead.-headerGroups .rt-td:hover {
  color: #6c757d;
}

.ReactTable .rt-thead.-headerGroups .rt-th:empty,
.ReactTable .rt-thead.-headerGroups .rt-td:empty {
  padding: 0;
}

.ReactTable .rt-thead.-filters {
  border-bottom: 1px solid #e9ecef;
  background: #f8f9fa;
}

.ReactTable .rt-thead.-filters .rt-th {
  border-right: 1px solid #e9ecef;
}

.ReactTable .rt-thead.-filters.-header {
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.15);
}

.ReactTable .rt-thead .rt-tr {
  text-align: center;
}

.ReactTable .rt-thead .rt-th,
.ReactTable .rt-thead .rt-td {
  padding: 0.55rem;
  line-height: normal;
  position: relative;
  font-weight: bold;
  border-right: 1px solid #e9ecef;
  border-bottom: 1px solid #e9ecef;
  transition: box-shadow 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  box-shadow: inset 0 0 0 0 transparent;
  color: #545cd8;
}

.ReactTable .rt-thead .rt-th:hover,
.ReactTable .rt-thead .rt-td:hover {
  color: #2a32b6;
}

.ReactTable .rt-thead .rt-th.-sort-asc,
.ReactTable .rt-thead .rt-td.-sort-asc {
  box-shadow: inset 0 3px 0 0 #545cd8;
}

.ReactTable .rt-thead .rt-th.-sort-desc,
.ReactTable .rt-thead .rt-td.-sort-desc {
  box-shadow: inset 0 -3px 0 0 #545cd8;
}

.ReactTable .rt-thead .rt-th.-cursor-pointer,
.ReactTable .rt-thead .rt-td.-cursor-pointer {
  cursor: pointer;
}

.ReactTable .rt-thead .rt-th:last-child,
.ReactTable .rt-thead .rt-td:last-child {
  border-right: 0;
}

.ReactTable .rt-thead .rt-resizable-header {
  overflow: visible;
}

.ReactTable .rt-thead .rt-resizable-header:last-child {
  overflow: hidden;
}

.ReactTable .rt-thead .rt-resizable-header-content {
  overflow: hidden;
  text-overflow: ellipsis;
}

.ReactTable .rt-thead .rt-header-pivot {
  border-right-color: #f7f7f7;
}

.ReactTable .rt-thead .rt-header-pivot:after,
.ReactTable .rt-thead .rt-header-pivot:before {
  left: 100%;
  top: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.ReactTable .rt-thead .rt-header-pivot:after {
  border-color: rgba(255, 255, 255, 0);
  border-left-color: #fff;
  border-width: 8px;
  margin-top: -8px;
}

.ReactTable .rt-thead .rt-header-pivot:before {
  border-color: rgba(102, 102, 102, 0);
  border-left-color: #f7f7f7;
  border-width: 10px;
  margin-top: -10px;
}

.ReactTable .rt-tbody {
  flex: 99999 1 auto;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.ReactTable .rt-tbody .rt-tr-group {
  border-bottom: solid 1px #e9ecef;
}

.ReactTable .rt-tbody .rt-tr-group:last-child {
  border-bottom: 0;
}

.ReactTable .rt-tbody .rt-td {
  border-right: 1px solid #e9ecef;
  align-items: center;
  align-content: center;
  display: flex;
}

.ReactTable .rt-tbody .rt-td [contenteditable=true] {
  width: 100%;
  border: 1px solid transparent;
  background: #fff !important;
  border-radius: 0.2rem;
}

.ReactTable .rt-tbody .rt-td [contenteditable=true]:focus {
  padding: 3px 5px;
  border-color: #bcbff0;
  outline: none;
}

.ReactTable .rt-tbody .rt-td:last-child {
  border-right: 0;
}

.ReactTable .rt-tbody .rt-expandable {
  cursor: pointer;
  text-overflow: clip;
}

.ReactTable .rt-tr-group {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.ReactTable .rt-tr {
  flex: 1 0 auto;
  display: inline-flex;
}

.ReactTable .rt-th,
.ReactTable .rt-td {
  flex: 1 0 0;
  text-overflow: ellipsis;
  padding: 0.55rem;
  transition: 0.3s ease;
  transition-property: width, min-width, padding, opacity;
}

.ReactTable .rt-th.-hidden,
.ReactTable .rt-td.-hidden {
  width: 0 !important;
  min-width: 0 !important;
  padding: 0 !important;
  border: 0 !important;
  opacity: 0 !important;
}

.ReactTable .rt-expander {
  display: inline-block;
  position: relative;
  color: transparent;
  margin: 0 10px 0 0;
}

.ReactTable .rt-expander:after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-90deg);
  border-left: 5.04px solid transparent;
  border-right: 5.04px solid transparent;
  border-top: 7px solid rgba(0, 0, 0, 0.8);
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  cursor: pointer;
}

.ReactTable .rt-expander.-open:after {
  transform: translate(-50%, -50%) rotate(0);
}

.ReactTable .rt-resizer {
  display: inline-block;
  position: absolute;
  width: 36px;
  top: 0;
  bottom: 0;
  right: -18px;
  cursor: col-resize;
  z-index: 10;
}

.ReactTable .rt-tfoot {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
}

.ReactTable .rt-tfoot .rt-td {
  border-right: 1px solid #e9ecef;
}

.ReactTable .rt-tfoot .rt-td:last-child {
  border-right: 0;
}

.ReactTable.-striped .rt-tr.-odd {
  background: rgba(0, 0, 0, 0.03);
}

.ReactTable.-fixed .-header {
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.07);
}

.ReactTable.-fixed .pagination-bottom {
  box-shadow: 0 -2px 7px 0 rgba(0, 0, 0, 0.07);
}

.ReactTable.-highlight .rt-tbody .rt-tr:not(.-padRow):hover {
  background: #e0f3ff;
}

.ReactTable .-pagination {
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-wrap: wrap;
  padding: 3px;
  border-top: 2px solid rgba(0, 0, 0, 0.1);
}

.ReactTable .-pagination .-btn {
  appearance: none;
  display: block;
  width: 100%;
  height: 100%;
  border: 0;
  border-radius: 0.25rem;
  padding: 6px;
  font-size: 1em;
  color: #545cd8;
  background: #f8f9fa;
  transition: all 0.2s ease;
  cursor: pointer;
  outline: none;
  font-weight: bold;
}

.ReactTable .-pagination .-btn[disabled] {
  opacity: 0.5;
  cursor: default;
}

.ReactTable .-pagination .-btn:not([disabled]):hover {
  background: #545cd8;
  color: #fff;
}

.ReactTable .-pagination .-previous,
.ReactTable .-pagination .-next {
  flex: 1;
  text-align: center;
}

.ReactTable .-pagination .-center {
  text-align: center;
  margin-bottom: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
}

.ReactTable .-pagination .-totalPages {
  padding-left: 5px;
  font-weight: bold;
}

.ReactTable .-pagination .-pageInfo {
  display: inherit;
  margin: 3px 0 3px 10px;
  white-space: nowrap;
  height: 30px;
  line-height: 30px;
}

.ReactTable .-pagination .-pageJump {
  display: inline-block;
  margin: 0 5px;
}

.ReactTable .-pagination .-pageJump input {
  width: 70px;
  text-align: center;
}

.ReactTable .-pagination .-pageSizeOptions {
  margin: 3px 10px;
}

.ReactTable .rt-noData {
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: rgba(255, 255, 255, 0.8);
  transition: all 0.3s ease;
  z-index: 1;
  pointer-events: none;
  padding: 20px;
  color: rgba(0, 0, 0, 0.5);
}

.ReactTable .-loading {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.8);
  transition: all 0.3s ease;
  z-index: -1;
  opacity: 0;
  pointer-events: none;
}

.ReactTable .-loading>div {
  position: absolute;
  display: block;
  text-align: center;
  width: 100%;
  top: 50%;
  left: 0;
  font-size: 15px;
  color: #545cd8;
  transform: translateY(-52%);
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.ReactTable .-loading.-active {
  opacity: 1;
  z-index: 2;
  pointer-events: all;
}

.ReactTable .-loading.-active>div {
  transform: translateY(50%);
}

.ReactTable .rt-resizing .rt-th,
.ReactTable .rt-resizing .rt-td {
  transition: none !important;
  cursor: col-resize;
  user-select: none;
}

.line-scale>div {
  background-color: #545cd8;
  width: 4px;
  height: 35px;
  border-radius: 2px;
  margin: 2px;
  animation-fill-mode: both;
  display: inline-block;
}

@keyframes line-scale-party {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(0.5);
  }

  100% {
    transform: scale(1);
  }
}

.line-scale-party>div:nth-child(1) {
  animation-delay: 0.43s;
  animation-duration: 1.29s;
}

.line-scale-party>div:nth-child(2) {
  animation-delay: 0.38s;
  animation-duration: 0.38s;
}

.line-scale-party>div:nth-child(3) {
  animation-delay: 0.52s;
  animation-duration: 0.99s;
}

.line-scale-party>div:nth-child(4) {
  animation-delay: 0.46s;
  animation-duration: 0.46s;
}

.line-scale-party>div {
  background-color: #545cd8;
  width: 4px;
  height: 35px;
  border-radius: 2px;
  margin: 2px;
  animation-fill-mode: both;
  display: inline-block;
  animation-name: line-scale-party;
  animation-iteration-count: infinite;
  animation-delay: 0;
}

@keyframes line-scale-pulse-out {
  0% {
    transform: scaley(1);
  }

  50% {
    transform: scaley(0.4);
  }

  100% {
    transform: scaley(1);
  }
}

.line-scale-pulse-out>div {
  background-color: #545cd8;
  width: 4px;
  height: 35px;
  border-radius: 2px;
  margin: 2px;
  animation-fill-mode: both;
  display: inline-block;
  animation: line-scale-pulse-out 0.9s -0.6s infinite cubic-bezier(0.85, 0.25, 0.37, 0.85);
}

.line-scale-pulse-out>div:nth-child(2),
.line-scale-pulse-out>div:nth-child(4) {
  animation-delay: -0.4s !important;
}

.line-scale-pulse-out>div:nth-child(1),
.line-scale-pulse-out>div:nth-child(5) {
  animation-delay: -0.2s !important;
}

@keyframes line-scale-pulse-out-rapid {
  0% {
    transform: scaley(1);
  }

  80% {
    transform: scaley(0.3);
  }

  90% {
    transform: scaley(1);
  }
}

.line-scale-pulse-out-rapid>div {
  background-color: #545cd8;
  width: 4px;
  height: 35px;
  border-radius: 2px;
  margin: 2px;
  animation-fill-mode: both;
  display: inline-block;
  vertical-align: middle;
  animation: line-scale-pulse-out-rapid 0.9s -0.5s infinite cubic-bezier(0.11, 0.49, 0.38, 0.78);
}

.line-scale-pulse-out-rapid>div:nth-child(2),
.line-scale-pulse-out-rapid>div:nth-child(4) {
  animation-delay: -0.25s !important;
}

.line-scale-pulse-out-rapid>div:nth-child(1),
.line-scale-pulse-out-rapid>div:nth-child(5) {
  animation-delay: 0s !important;
}

@keyframes line-spin-fade-loader {
  50% {
    opacity: 0.3;
  }

  100% {
    opacity: 1;
  }
}

.line-spin-fade-loader {
  position: relative;
  top: -10px;
  left: -4px;
}

.line-spin-fade-loader>div:nth-child(1) {
  top: 20px;
  left: 0;
  animation: line-spin-fade-loader 1.2s -0.84s infinite ease-in-out;
}

.line-spin-fade-loader>div:nth-child(2) {
  top: 13.6363636364px;
  left: 13.6363636364px;
  transform: rotate(-45deg);
  animation: line-spin-fade-loader 1.2s -0.72s infinite ease-in-out;
}

.line-spin-fade-loader>div:nth-child(3) {
  top: 0;
  left: 20px;
  transform: rotate(90deg);
  animation: line-spin-fade-loader 1.2s -0.6s infinite ease-in-out;
}

.line-spin-fade-loader>div:nth-child(4) {
  top: -13.6363636364px;
  left: 13.6363636364px;
  transform: rotate(45deg);
  animation: line-spin-fade-loader 1.2s -0.48s infinite ease-in-out;
}

.line-spin-fade-loader>div:nth-child(5) {
  top: -20px;
  left: 0;
  animation: line-spin-fade-loader 1.2s -0.36s infinite ease-in-out;
}

.line-spin-fade-loader>div:nth-child(6) {
  top: -13.6363636364px;
  left: -13.6363636364px;
  transform: rotate(-45deg);
  animation: line-spin-fade-loader 1.2s -0.24s infinite ease-in-out;
}

.line-spin-fade-loader>div:nth-child(7) {
  top: 0;
  left: -20px;
  transform: rotate(90deg);
  animation: line-spin-fade-loader 1.2s -0.12s infinite ease-in-out;
}

.line-spin-fade-loader>div:nth-child(8) {
  top: 13.6363636364px;
  left: -13.6363636364px;
  transform: rotate(45deg);
  animation: line-spin-fade-loader 1.2s 0s infinite ease-in-out;
}

.line-spin-fade-loader>div {
  background-color: #545cd8;
  width: 4px;
  height: 35px;
  border-radius: 2px;
  margin: 2px;
  animation-fill-mode: both;
  position: absolute;
  width: 5px;
  height: 15px;
}

/**
 * Misc
 */
@keyframes triangle-skew-spin {
  25% {
    transform: perspective(100px) rotateX(180deg) rotateY(0);
  }

  50% {
    transform: perspective(100px) rotateX(180deg) rotateY(180deg);
  }

  75% {
    transform: perspective(100px) rotateX(0) rotateY(180deg);
  }

  100% {
    transform: perspective(100px) rotateX(0) rotateY(0);
  }
}

.triangle-skew-spin>div {
  animation-fill-mode: both;
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-bottom: 20px solid #545cd8;
  animation: triangle-skew-spin 3s 0s cubic-bezier(0.09, 0.57, 0.49, 0.9) infinite;
}

@keyframes square-spin {
  25% {
    transform: perspective(100px) rotateX(180deg) rotateY(0);
  }

  50% {
    transform: perspective(100px) rotateX(180deg) rotateY(180deg);
  }

  75% {
    transform: perspective(100px) rotateX(0) rotateY(180deg);
  }

  100% {
    transform: perspective(100px) rotateX(0) rotateY(0);
  }
}

.square-spin>div {
  animation-fill-mode: both;
  width: 50px;
  height: 50px;
  background: #545cd8;
  animation: square-spin 3s 0s cubic-bezier(0.09, 0.57, 0.49, 0.9) infinite;
}

@keyframes rotate_pacman_half_up {
  0% {
    transform: rotate(270deg);
  }

  50% {
    transform: rotate(360deg);
  }

  100% {
    transform: rotate(270deg);
  }
}

@keyframes rotate_pacman_half_down {
  0% {
    transform: rotate(90deg);
  }

  50% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(90deg);
  }
}

@keyframes pacman-balls {
  75% {
    opacity: 0.7;
  }

  100% {
    transform: translate(-100px, -6.25px);
  }
}

.pacman {
  position: relative;
}

.pacman>div:nth-child(2) {
  animation: pacman-balls 1s -0.99s infinite linear;
}

.pacman>div:nth-child(3) {
  animation: pacman-balls 1s -0.66s infinite linear;
}

.pacman>div:nth-child(4) {
  animation: pacman-balls 1s -0.33s infinite linear;
}

.pacman>div:nth-child(5) {
  animation: pacman-balls 1s 0s infinite linear;
}

.pacman>div:first-of-type {
  width: 0px;
  height: 0px;
  border-right: 25px solid transparent;
  border-top: 25px solid #545cd8;
  border-left: 25px solid #545cd8;
  border-bottom: 25px solid #545cd8;
  border-radius: 25px;
  animation: rotate_pacman_half_up 0.5s 0s infinite;
  position: relative;
  left: -30px;
}

.pacman>div:nth-child(2) {
  width: 0px;
  height: 0px;
  border-right: 25px solid transparent;
  border-top: 25px solid #545cd8;
  border-left: 25px solid #545cd8;
  border-bottom: 25px solid #545cd8;
  border-radius: 25px;
  animation: rotate_pacman_half_down 0.5s 0s infinite;
  margin-top: -50px;
  position: relative;
  left: -30px;
}

.pacman>div:nth-child(3),
.pacman>div:nth-child(4),
.pacman>div:nth-child(5),
.pacman>div:nth-child(6) {
  background-color: #545cd8;
  width: 15px;
  height: 15px;
  border-radius: 100%;
  margin: 2px;
  width: 10px;
  height: 10px;
  position: absolute;
  transform: translate(0, -6.25px);
  top: 25px;
  left: 70px;
}

@keyframes cube-transition {
  25% {
    transform: translateX(50px) scale(0.5) rotate(-90deg);
  }

  50% {
    transform: translate(50px, 50px) rotate(-180deg);
  }

  75% {
    transform: translateY(50px) scale(0.5) rotate(-270deg);
  }

  100% {
    transform: rotate(-360deg);
  }
}

.cube-transition {
  position: relative;
  transform: translate(-25px, -25px);
}

.cube-transition>div {
  animation-fill-mode: both;
  width: 10px;
  height: 10px;
  position: absolute;
  top: -5px;
  left: -5px;
  background-color: #545cd8;
  animation: cube-transition 1.6s 0s infinite ease-in-out;
}

.cube-transition>div:last-child {
  animation-delay: -0.8s;
}

@keyframes spin-rotate {
  0% {
    transform: rotate(0deg);
  }

  50% {
    transform: rotate(180deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.semi-circle-spin {
  position: relative;
  width: 35px;
  height: 35px;
  overflow: hidden;
}

.semi-circle-spin>div {
  position: absolute;
  border-width: 0px;
  border-radius: 100%;
  animation: spin-rotate 0.6s 0s infinite linear;
  background-image: linear-gradient(transparent 0%, transparent 70%, #545cd8 30%, #545cd8 100%);
  width: 100%;
  height: 100%;
}

.progress .progress-bar:last-child {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.progress.progress-bar-sm {
  height: 0.5rem;
}

.progress.progress-bar-xs {
  height: 0.3rem;
}

.progress.progress-bar-rounded {
  border-radius: 30px;
}

.progress-bar-animated-alt.progress-bar,
.progress-bar-animated-alt .progress-bar {
  position: relative;
}

.progress-bar-animated-alt.progress-bar::after,
.progress-bar-animated-alt .progress-bar::after {
  content: "";
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #fff;
  animation: progress-active 2s ease infinite;
}

@keyframes progress-active {
  0% {
    opacity: 0.4;
    width: 0;
  }

  100% {
    opacity: 0;
    width: 100%;
  }
}

.vertical-timeline {
  width: 100%;
  position: relative;
  padding: 1.5rem 0 1rem;
}

.vertical-timeline::after {
  content: "";
  display: table;
  clear: both;
}

.vertical-timeline::before {
  content: "";
  position: absolute;
  top: 0;
  left: 67px;
  height: 100%;
  width: 4px;
  background: #e9ecef;
  border-radius: 0.25rem;
}

.vertical-timeline-element {
  position: relative;
  margin: 0 0 1rem;
}

.vertical-timeline-element:after {
  content: "";
  display: table;
  clear: both;
}

.vertical-timeline-element:last-child {
  margin-bottom: 0;
}

.vertical-timeline-element-content {
  position: relative;
  margin-left: 90px;
  font-size: 0.8rem;
}

.vertical-timeline-element-content:after {
  content: "";
  display: table;
  clear: both;
}

.vertical-timeline-element-content .timeline-title {
  font-size: 0.8rem;
  text-transform: uppercase;
  margin: 0 0 0.5rem;
  padding: 2px 0 0;
  font-weight: bold;
}

.vertical-timeline-element-content p {
  color: #6c757d;
  margin: 0 0 0.5rem;
}

.vertical-timeline-element-content .vertical-timeline-element-date {
  display: block;
  position: absolute;
  left: -90px;
  top: 0;
  padding-right: 10px;
  text-align: right;
  color: #adb5bd;
  font-size: 0.7619047619rem;
  white-space: nowrap;
}

.vertical-timeline-element-icon {
  position: absolute;
  top: 0;
  left: 60px;
}

.vertical-timeline-element-icon .badge-dot-xl {
  box-shadow: 0 0 0 5px #fff;
}

.vertical-timeline-element--no-children .vertical-timeline-element-content {
  background: 0 0;
  box-shadow: none;
}

.vertical-timeline-element--no-children .vertical-timeline-element-content::before {
  display: none;
}

.vertical-without-time::before {
  left: 11px;
}

.vertical-without-time .vertical-timeline-element-content {
  margin-left: 36px;
}

.vertical-without-time .vertical-timeline-element-icon {
  left: 4px;
}

.vertical-time-icons {
  padding: 2rem 0 0;
}

.vertical-time-icons::before {
  content: "";
  position: absolute;
  top: 0;
  left: 14px;
  height: 100%;
  width: 6px;
  background: #e9ecef;
  border-radius: 0.25rem;
}

.vertical-time-icons .vertical-timeline-element {
  margin-bottom: 1rem;
}

.vertical-time-icons .vertical-timeline-element-content {
  margin-left: 50px;
}

.vertical-time-icons .vertical-timeline-element-icon {
  width: 34px;
  height: 34px;
  left: 0;
  top: -7px;
}

.vertical-time-icons .vertical-timeline-element-icon .timeline-icon {
  width: 34px;
  height: 34px;
  background: #fff;
  border-radius: 50px;
  border-width: 2px;
  border-style: solid;
  box-shadow: 0 0 0 5px #fff;
  text-align: center;
  display: flex;
  align-items: center;
  align-content: center;
}

.vertical-time-icons .vertical-timeline-element-icon .timeline-icon i {
  display: block;
  font-size: 1.1rem;
  margin: 0 auto;
}

.vertical-time-icons .vertical-timeline-element-icon .timeline-icon svg {
  margin: 0 auto;
}

.vertical-time-simple {
  padding: 0.5rem 0;
}

.vertical-time-simple .vertical-timeline-element {
  margin: 0 0 0.5rem;
}

.vertical-time-simple .timeline-title {
  font-weight: normal;
  font-size: 0.9166666667rem;
  padding: 0;
}

.vertical-time-simple .vertical-timeline-element-icon {
  height: 14px;
  width: 14px;
  background: #e9ecef;
  position: absolute;
  left: 6px;
  top: 2px;
  display: block;
  border-radius: 20px;
}

.vertical-time-simple .vertical-timeline-element-icon::after {
  content: "";
  position: absolute;
  background: #fff;
  left: 50%;
  top: 50%;
  margin: -4px 0 0 -4px;
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 20px;
}

.vertical-time-simple .timeline-title {
  text-transform: none;
}

.dot-primary .vertical-timeline-element-icon {
  background: #545cd8;
}

.dot-secondary .vertical-timeline-element-icon {
  background: #6c757d;
}

.dot-success .vertical-timeline-element-icon {
  background: #3ac47d;
}

.dot-info .vertical-timeline-element-icon {
  background: #30b1ff;
}

.dot-warning .vertical-timeline-element-icon {
  background: #f7b924;
}

.dot-danger .vertical-timeline-element-icon {
  background: #d92550;
}

.dot-light .vertical-timeline-element-icon {
  background: #eeeeee;
}

.dot-dark .vertical-timeline-element-icon {
  background: #343a40;
}

.dot-focus .vertical-timeline-element-icon {
  background: #444054;
}

.dot-alternate .vertical-timeline-element-icon {
  background: #83588a;
}

.vertical-timeline--animate .vertical-timeline-element-icon.is-hidden {
  visibility: hidden;
}

.vertical-timeline--animate .vertical-timeline-element-icon.bounce-in {
  visibility: visible;
  animation: cd-bounce-1 0.8s;
}

@-webkit-keyframes cd-bounce-1 {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.5);
  }

  60% {
    opacity: 1;
    -webkit-transform: scale(1.2);
  }

  100% {
    -webkit-transform: scale(1);
  }
}

@-moz-keyframes cd-bounce-1 {
  0% {
    opacity: 0;
    -moz-transform: scale(0.5);
  }

  60% {
    opacity: 1;
    -moz-transform: scale(1.2);
  }

  100% {
    -moz-transform: scale(1);
  }
}

@keyframes cd-bounce-1 {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.5);
    -moz-transform: scale(0.5);
    -ms-transform: scale(0.5);
    -o-transform: scale(0.5);
    transform: scale(0.5);
  }

  60% {
    opacity: 1;
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -o-transform: scale(1.2);
    transform: scale(1.2);
  }

  100% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}

.vertical-timeline--animate .vertical-timeline-element-content.is-hidden {
  visibility: hidden;
}

.vertical-timeline--animate .vertical-timeline-element-content.bounce-in {
  visibility: visible;
  -webkit-animation: cd-bounce-2 0.6s;
  -moz-animation: cd-bounce-2 0.6s;
  animation: cd-bounce-2 0.6s;
}

@media only screen and (min-width: 1170px) {

  .vertical-timeline--two-columns.vertical-timeline--animate .vertical-timeline-element.vertical-timeline-element--right .vertical-timeline-element-content.bounce-in,
  .vertical-timeline--two-columns.vertical-timeline--animate .vertical-timeline-element:nth-child(even):not(.vertical-timeline-element--left) .vertical-timeline-element-content.bounce-in {
    -webkit-animation: cd-bounce-2-inverse 0.6s;
    -moz-animation: cd-bounce-2-inverse 0.6s;
    animation: cd-bounce-2-inverse 0.6s;
  }
}

@media only screen and (max-width: 1169px) {
  .vertical-timeline--animate .vertical-timeline-element-content.bounce-in {
    visibility: visible;
    -webkit-animation: cd-bounce-2-inverse 0.6s;
    -moz-animation: cd-bounce-2-inverse 0.6s;
    animation: cd-bounce-2-inverse 0.6s;
  }
}

@-webkit-keyframes cd-bounce-2 {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100px);
  }

  60% {
    opacity: 1;
    -webkit-transform: translateX(20px);
  }

  100% {
    -webkit-transform: translateX(0);
  }
}

@-moz-keyframes cd-bounce-2 {
  0% {
    opacity: 0;
    -moz-transform: translateX(-100px);
  }

  60% {
    opacity: 1;
    -moz-transform: translateX(20px);
  }

  100% {
    -moz-transform: translateX(0);
  }
}

@keyframes cd-bounce-2 {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100px);
    -moz-transform: translateX(-100px);
    -ms-transform: translateX(-100px);
    -o-transform: translateX(-100px);
    transform: translateX(-100px);
  }

  60% {
    opacity: 1;
    -webkit-transform: translateX(20px);
    -moz-transform: translateX(20px);
    -ms-transform: translateX(20px);
    -o-transform: translateX(20px);
    transform: translateX(20px);
  }

  100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}

@-webkit-keyframes cd-bounce-2-inverse {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100px);
  }

  60% {
    opacity: 1;
    -webkit-transform: translateX(-20px);
  }

  100% {
    -webkit-transform: translateX(0);
  }
}

@-moz-keyframes cd-bounce-2-inverse {
  0% {
    opacity: 0;
    -moz-transform: translateX(100px);
  }

  60% {
    opacity: 1;
    -moz-transform: translateX(-20px);
  }

  100% {
    -moz-transform: translateX(0);
  }
}

@keyframes cd-bounce-2-inverse {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100px);
    -moz-transform: translateX(100px);
    -ms-transform: translateX(100px);
    -o-transform: translateX(100px);
    transform: translateX(100px);
  }

  60% {
    opacity: 1;
    -webkit-transform: translateX(-20px);
    -moz-transform: translateX(-20px);
    -ms-transform: translateX(-20px);
    -o-transform: translateX(-20px);
    transform: translateX(-20px);
  }

  100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}

.todo-list-wrapper .todo-indicator {
  position: absolute;
  width: 4px;
  height: 60%;
  border-radius: 0.3rem;
  left: 0.5rem;
  top: 20%;
  opacity: 0.6;
  transition: opacity 0.2s;
}

.todo-list-wrapper .list-group-item:hover .todo-indicator {
  opacity: 0.9;
}

.todo-list-wrapper .custom-control,
.todo-list-wrapper input[checkbox] {
  margin-left: 0.5rem;
}

.list-group-flush+.card-footer {
  border-top: 0;
}

.rm-list-borders .list-group-item {
  border: 0;
  padding: 0.6666666667rem 0;
}

.rm-list-borders-scroll .list-group-item {
  border: 0;
  padding-right: 1.5rem;
}

.input-group .input-group-prepend div:not([class]) .react-datepicker__input-container .form-control,
.input-group .input-group-prepend+div .react-datepicker__input-container .form-control {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.input-group>div:not([class]) {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
}

.input-group>div:not([class]) .react-datepicker__input-container .form-control {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group .input-group-prepend+div {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
}

.input-group>.react-datepicker-wrapper {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
}

.input-group>.react-datepicker-wrapper>.react-datepicker__input-container>.form-control {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

legend {
  font-size: 0.88rem;
  font-weight: bold;
}

.form-heading {
  font-size: 1.1rem;
  margin: 0;
  color: #545cd8;
}

.form-heading p {
  color: #6c757d;
  padding: 0.3rem 0 0;
  font-size: 0.88rem;
}

.custom-select {
  -webkit-appearance: none;
  -moz-appearance: none;
}

.pagination li a {
  position: relative;
  display: block;
  padding: 0.375rem 0.75rem;
  margin-left: -1px;
  color: #545cd8;
  background-color: #fff;
  border: 1px solid #dee2e6;
}

.pagination li a:hover {
  z-index: 2;
  color: #434aad;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.pagination li a:focus {
  z-index: 2;
  outline: 0;
  box-shadow: none;
}

.pagination li a:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.pagination li:first-child a {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.pagination li:last-child a {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.pagination li.active a {
  z-index: 1;
  color: #fff;
  background-color: #545cd8;
  border-color: #545cd8;
}

.pagination li.active a:hover {
  color: #fff;
}

.pagination li.disabled a {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}

.pagination-rounded li a {
  border-radius: 50px !important;
  margin: 0 0.3rem;
}

.chat-box-wrapper {
  display: flex;
  clear: both;
  padding: 0.75rem;
}

.chat-box-wrapper+.chat-box-wrapper {
  padding-top: 0;
}

.chat-box-wrapper .chat-box {
  box-shadow: 0 0 0 transparent;
  position: relative;
  opacity: 1;
  background: #e0f3ff;
  border: 0;
  padding: 0.75rem 1.5rem;
  border-radius: 30px;
  border-top-left-radius: 0.25rem;
  flex: 1;
  display: flex;
  max-width: 50%;
  min-width: 100%;
  text-align: left;
}

.chat-box-wrapper .chat-box+small,
.chat-box-wrapper .chat-box+.small {
  text-align: left;
  padding: 0.5rem 0 0;
  margin-left: 1.5rem;
  display: block;
}

.chat-box-wrapper.chat-box-wrapper-right {
  text-align: right;
}

.chat-box-wrapper.chat-box-wrapper-right .chat-box {
  border-radius: 30px;
  border-top-left-radius: 30px;
  border-top-right-radius: 0.25rem;
  margin-left: auto;
}

.chat-box-wrapper.chat-box-wrapper-right .chat-box+small,
.chat-box-wrapper.chat-box-wrapper-right .chat-box+.small {
  text-align: right;
  margin-right: 1.5rem;
  margin-left: 0;
}

.forms-wizard {
  margin: 0;
  padding: 0;
  list-style-type: none;
  width: 100%;
  display: table;
  table-layout: fixed;
  border-radius: 0.25rem;
}

.forms-wizard li {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  font-size: 1rem;
  padding: 1rem 0;
  color: #adb5bd;
  position: relative;
}

.forms-wizard li::after,
.forms-wizard li::before {
  position: absolute;
  height: 4px;
  top: 50%;
  margin-top: -1rem;
  width: 50%;
  content: "";
  background: #dee2e6;
  z-index: 5;
  transition: all 0.2s;
}

.forms-wizard li::after {
  left: 50%;
}

.forms-wizard li::before {
  left: 0;
}

.forms-wizard li:first-child::before {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

.forms-wizard li:last-child::after {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.forms-wizard li em {
  font-style: normal;
  font-size: 1.32rem;
  background: #ced4da;
  color: #fff;
  text-align: center;
  padding: 0;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50px;
  display: block;
  margin: 0 auto 0.5rem;
  position: relative;
  z-index: 7;
  transition: all 0.2s;
}

.forms-wizard li.form-wizard-step-doing {
  color: #495057;
}

.forms-wizard li.form-wizard-step-doing em {
  background: #545cd8;
}

.forms-wizard li.form-wizard-step-doing::before {
  background: #545cd8;
}

.forms-wizard li.form-wizard-step-done em {
  font-family: "Linearicons-Free";
  background: #3ac47d;
  overflow: hidden;
}

.forms-wizard li.form-wizard-step-done em::before {
  width: 42px;
  height: 42px;
  font-size: 1.2rem;
  line-height: 40px;
  text-align: center;
  display: block;
}

.forms-wizard li.form-wizard-step-done::after,
.forms-wizard li.form-wizard-step-done::before {
  background: #3ac47d;
}

.forms-wizard li:hover {
  color: #6c757d;
}

.forms-wizard-alt .forms-wizard li {
  font-size: 0.88rem;
}

.forms-wizard-alt .forms-wizard li em {
  width: 14px;
  height: 14px;
  line-height: 14px;
  text-indent: -999rem;
  border: #fff solid 2px;
  margin: -7px auto 0;
}

.forms-wizard-vertical .forms-wizard {
  display: block;
  width: 30%;
  float: left;
  padding: 0 1.5rem 1.5rem 0;
}

.forms-wizard-vertical .forms-wizard li {
  text-align: left;
  display: flex;
  align-items: center;
  align-content: flex-start;
  padding: 0.75rem;
  margin-bottom: 0.5rem;
  border-radius: 0.3rem;
  transition: all 0.2s;
}

.forms-wizard-vertical .forms-wizard li::before,
.forms-wizard-vertical .forms-wizard li::after {
  display: none;
}

.forms-wizard-vertical .forms-wizard li em {
  margin: 0 0.75rem 0 0;
}

.forms-wizard-vertical .forms-wizard li:hover {
  background: #e9ecef;
}

.forms-wizard-vertical .forms-wizard li.form-wizard-step-doing {
  background: #545cd8;
  color: #fff;
}

.forms-wizard-vertical .forms-wizard li.form-wizard-step-doing em {
  background: rgba(255, 255, 255, 0.2);
}

.forms-wizard-vertical .form-wizard-content {
  width: 70%;
  overflow: auto;
}

@media screen and (min-width: 360px) and (max-width: 812px) {
  .forms-wizard-vertical .forms-wizard {
    display: block;
    width: 50%;
    float: left;
    padding: 0 1.5rem 1.5rem 0;
  }

  .forms-wizard-vertical .forms-wizard li {
    text-align: left;
    display: flex;
    align-items: center;
    align-content: flex-start;
    padding: 0.75rem;
    margin-bottom: 0.5rem;
    border-radius: 0.3rem;
    transition: all 0.2s;
  }

  .forms-wizard-vertical .forms-wizard li::before,
  .forms-wizard-vertical .forms-wizard li::after {
    display: none;
  }

  .forms-wizard-vertical .forms-wizard li em {
    margin: 0 0.75rem 0 0;
  }

  .forms-wizard-vertical .forms-wizard li:hover {
    background: #e9ecef;
  }

  .forms-wizard-vertical .forms-wizard li.form-wizard-step-doing {
    background: #545cd8;
    color: #fff;
  }

  .forms-wizard-vertical .forms-wizard li.form-wizard-step-doing em {
    background: rgba(255, 255, 255, 0.2);
  }

  .forms-wizard-vertical .form-wizard-content {
    width: 50%;
    overflow: auto;
  }
}

.icon-wrapper {
  display: flex;
  align-content: center;
  align-items: center;
}

.widget-chart {
  text-align: center;
  padding: 1rem;
  position: relative;
}

.widget-chart .progress-sub-label {
  opacity: 0.8;
  padding: 5px 0 0;
}

.widget-chart .progress-circle-wrapper {
  min-width: 68px;
  margin-right: 1rem;
}

.widget-chart .progress-circle-wrapper .react-sweet-progress-symbol {
  font-size: 0.8rem;
}

.widget-chart .widget-chart-content {
  position: relative;
  z-index: 5;
}

.widget-chart .widget-chart-content-lg {
  padding: 2rem 0 1rem 2rem;
}

.widget-chart .widget-chart-content-lg .widget-numbers {
  margin-bottom: 0;
}

.widget-chart .widget-chart-wrapper {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  opacity: 0.25;
  z-index: 6;
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  overflow: hidden;
}

.widget-chart .widget-numbers {
  font-weight: bold;
  font-size: 2.5rem;
  display: block;
  line-height: 1;
  margin: 1rem auto;
}

.widget-chart .widget-numbers+.widget-chart-flex,
.widget-chart .widget-numbers+.widget-description,
.widget-chart .widget-numbers+.widget-subheading {
  margin-top: -0.5rem;
}

.widget-chart .widget-subheading {
  margin: -0.5rem 0 0;
  display: block;
  opacity: 0.6;
}

.widget-chart .widget-subheading:first-child {
  margin-top: 0;
}

.widget-chart .widget-subheading+.widget-numbers {
  margin-top: 0.5rem;
}

.widget-chart .widget-description {
  margin: 1rem 0 0;
}

.widget-chart.widget-chart-hover {
  transition: all 0.2s;
}

.widget-chart.widget-chart-hover:hover {
  z-index: 15;
  transform: scale(1.15);
  box-shadow: 0 0.46875rem 4.1875rem rgba(8, 10, 37, 0.05), 0 0.9375rem 2.40625rem rgba(8, 10, 37, 0.05), 0 0.25rem 1.3125rem rgba(8, 10, 37, 0.06), 0 0.125rem 1.1875rem rgba(8, 10, 37, 0.06);
  cursor: pointer;
  background: #fff;
}

.widget-chart .widget-chart-actions {
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
  z-index: 12;
}

.widget-chart .widget-chart-actions .btn-link {
  font-size: 1.1rem;
  padding-top: 0;
  padding-bottom: 0;
  opacity: 0.6;
}

.widget-chart .widget-progress-wrapper {
  margin-top: 1rem;
}

.widget-chart .widget-progress-wrapper.progress-wrapper-bottom {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
}

.widget-chart .widget-progress-wrapper.progress-wrapper-bottom .progress {
  margin: 0 -1px -1px;
}

.widget-chart .widget-progress-wrapper.progress-wrapper-bottom .progress {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.widget-chart .widget-progress-wrapper.progress-wrapper-bottom .progress .progress-bar {
  border-bottom-left-radius: 0.25rem;
}

.widget-chart .widget-chart-flex {
  display: flex;
  align-items: center;
  align-content: center;
  margin-bottom: 1rem;
}

.widget-chart .widget-chart-flex:last-child {
  margin-bottom: 0;
}

.widget-chart .widget-chart-flex .widget-subheading {
  margin: 0;
}

.widget-chart .widget-chart-flex .widget-description {
  margin-top: 0;
}

.widget-chart.text-start {
  flex-direction: row;
  align-items: center;
}

.widget-chart.text-start .icon-wrapper {
  min-width: 54px;
  margin: 0 1rem 0 0;
}

.widget-chart.text-start .widget-numbers {
  margin-left: 0;
}

.widget-chart.text-start .widget-chart-content {
  display: flex;
  flex-direction: column;
  align-content: center;
  flex: 1;
  position: relative;
}

.widget-chart.text-start .widget-chart-content>.widget-numbers:first-child {
  margin-top: 0;
}

.widget-chart.text-start .widget-chart-content .widget-description {
  align-self: flex-start;
}

.widget-chart.text-start .widget-chart-wrapper {
  height: 35%;
}

.widget-chart.widget-chart-left {
  padding-bottom: 15%;
}

.widget-chart .chart-wrapper-relative {
  position: relative;
  opacity: 1;
  margin-top: 1rem;
}

.widget-chart-actions {
  position: absolute;
  right: 1rem;
  top: 1rem;
  z-index: 12;
}

.widget-chart-actions .btn-link {
  font-size: 1.1rem;
  padding-top: 0;
  padding-bottom: 0;
  opacity: 0.6;
}

.widget-chart:hover .widget-chart-actions .btn-link,
.widget-content:hover .widget-chart-actions .btn-link {
  opacity: 1;
}

.grid-menu .widget-chart.widget-chart-hover:hover {
  background: #fff;
  border-radius: 0.25rem;
}

.icon-wrapper {
  width: 54px;
  height: 54px;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
}

.icon-wrapper[class*=border-] {
  border-width: 1px;
  border-style: solid;
}

.icon-wrapper .icon-wrapper-bg {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 3;
  opacity: 0.2;
}

.icon-wrapper .icon-wrapper-bg.bg-light {
  opacity: 0.08;
}

.icon-wrapper i {
  margin: 0 auto;
  font-size: 1.7rem;
  position: relative;
  z-index: 5;
}

.icon-wrapper i:before {
  margin-top: -3px;
}

.icon-wrapper .progress-circle-wrapper {
  width: 100%;
  margin-right: 0;
}

.widget-chart2 .widget-chart-flex {
  display: flex;
  align-items: baseline;
  align-content: center;
  margin-bottom: 0;
}

.widget-chart2 .widget-chart-flex .widget-subtitle {
  margin-left: auto;
}

.widget-chart2 .widget-chart-flex .widget-numbers {
  font-weight: normal;
}

.widget-chart2 .widget-chart-flex+.widget-chart-flex .widget-numbers {
  margin-bottom: 0;
}

.widget-chart2 .widget-chat-wrapper-outer {
  display: flex;
  flex: 1;
  flex-direction: column;
  max-width: 100%;
}

.widget-chart2 .widget-chat-wrapper-outer .widget-chart-wrapper {
  height: 70px;
  opacity: 0.8;
  position: relative;
  margin: 1rem auto -0.5rem;
}

.widget-chart2 .widget-chat-wrapper-outer .widget-chart-wrapper-lg {
  height: 130px;
}

.card-btm-border {
  border-bottom: transparent solid 4px;
}

.progress-box {
  text-align: center;
}

.progress-box h4,
.progress-box .h4 {
  font-size: 0.88rem;
  font-weight: bold;
  opacity: 0.6;
  text-transform: uppercase;
  padding-bottom: 0.3333333333rem;
}

.progress-box svg {
  margin: 0 auto;
}

.svg-bg {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0.1;
}

.svg-bg svg {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.widget-numbers-sm {
  font-size: 1.5rem;
}

.widget-content {
  padding: 1rem;
  flex-direction: row;
  align-items: center;
}

.widget-content .widget-content-wrapper {
  display: flex;
  flex: 1;
  position: relative;
  align-items: center;
}

.widget-content .widget-content-left .widget-heading {
  opacity: 0.8;
  font-weight: bold;
}

.widget-content .widget-content-left .widget-subheading {
  opacity: 0.5;
}

.widget-content .widget-content-right {
  margin-left: auto;
}

.widget-content .widget-numbers {
  font-weight: bold;
  font-size: 1.8rem;
  display: block;
}

.widget-content .widget-content-outer {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.widget-content .widget-progress-wrapper {
  margin-top: 1rem;
}

.widget-content .widget-progress-wrapper .progress-sub-label {
  margin-top: 0.3333333333rem;
  opacity: 0.5;
  display: flex;
  align-content: center;
  align-items: center;
}

.widget-content .widget-progress-wrapper .progress-sub-label .sub-label-right {
  margin-left: auto;
}

.widget-content .widget-content-right.widget-content-actions {
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.2s;
}

.widget-content:hover .widget-content-right.widget-content-actions {
  visibility: visible;
  opacity: 1;
}

.profile-block {
  position: relative;
  overflow: hidden;
}

.profile-block .profile-blur {
  width: 100%;
  height: 100%;
  filter: blur(5px);
  transform: scale(1.8);
  position: absolute;
  left: -25%;
  top: -25%;
}

.profile-block .profile-inner {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  left: 0;
  top: 0;
}

.profile-block .dropdown-menu-header .menu-header-content {
  padding: 3rem;
}

.profile-block .dropdown-menu-header .menu-header-content .menu-header-title {
  margin: 0.75rem 0 0;
}

.profile-block .dropdown-menu-header .menu-header-content .menu-header-subtitle {
  margin: 0.5rem 0 0;
}

.profile-block .dropdown-menu-header .menu-header-btn-pane {
  margin: 0.5rem 0 0;
}

.profile-block .dropdown-menu-header .menu-header-btn-pane .btn-icon {
  color: #fff;
  padding: 0;
  width: 44px;
  height: 44px;
  line-height: 37px;
  font-size: 1.1rem;
}

.profile-block .dropdown-menu-header .menu-header-btn-pane .btn-icon:hover {
  background: rgba(255, 255, 255, 0.2);
}

.app-logo {
  height: 23px;
  width: 97px;
  /* background: url("utils/images/logo-inverse.png"); */
}

.app-logo-inverse {
  height: 23px;
  width: 97px;
  /* background: url("utils/images/logo.png"); */
}

.app-login-box .app-logo {
  margin-bottom: 3rem;
}

.app-login-box h4,
.app-login-box .h4 {
  margin-bottom: 1.5rem;
  font-weight: normal;
}

.app-login-box h4 div,
.app-login-box .h4 div {
  opacity: 0.6;
}

.app-login-box h4 span,
.app-login-box .h4 span {
  font-size: 1.1rem;
}

.app-inner-layout.rm-sidebar .app-inner-layout__wrapper .app-inner-layout__content {
  width: auto;
  float: none;
}

.app-inner-layout .app-inner-layout__header {
  width: 100%;
  padding: 1.5rem;
  text-align: left;
  border-bottom: #e9ecef solid 1px;
}

.app-inner-layout .app-inner-layout__header .app-page-title {
  margin: 0;
  padding: 0;
  background: transparent;
}

.app-inner-layout .app-inner-layout__header-boxed {
  padding: 1.5rem;
}

.app-inner-layout .app-inner-layout__header-boxed .app-inner-layout__header {
  border-radius: 0.3rem;
}

.app-inner-layout .app-inner-layout__wrapper {
  position: relative;
  display: flex;
  flex-direction: row;
  min-height: 100vh;
}

.app-inner-layout .app-inner-layout__wrapper .app-inner-layout__content {
  flex: 1;
  display: flex;
}

.app-inner-layout .app-inner-layout__wrapper .app-inner-layout__content.card {
  box-shadow: 0 0 0 0 transparent;
  border-radius: 0;
  border: 0;
}

.app-inner-layout .app-inner-layout__wrapper .app-inner-layout__content .app-inner-layout__top-pane {
  display: flex;
  align-content: center;
  align-items: center;
  text-align: left;
  padding: 0.75rem 1.5rem;
}

.app-inner-layout .app-inner-layout__wrapper .app-inner-layout__content .pane-left {
  display: flex;
  align-items: center;
}

.app-inner-layout .app-inner-layout__wrapper .app-inner-layout__content .pane-right {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.app-inner-layout .app-inner-layout__wrapper .app-inner-layout__content .app-inner-layout__bottom-pane {
  padding: 1.5rem;
  border-top: #e9ecef solid 1px;
}

.app-inner-layout .app-inner-layout__wrapper .app-inner-layout__sidebar {
  width: 270px;
  list-style: none;
  text-align: left;
  order: -1;
  flex: 0 0 270px;
  display: flex;
  margin: 0;
  position: relative;
}

.app-inner-layout .app-inner-layout__wrapper .app-inner-layout__sidebar .dropdown-item {
  white-space: normal;
}

.app-inner-layout .app-inner-layout__wrapper .app-inner-layout__sidebar.card {
  box-shadow: 0 0 0 0 transparent;
  border-radius: 0;
  background: #f8f9fa;
  border: 0;
  border-right: #e9ecef solid 1px;
  border-left: #e9ecef solid 1px;
}

.app-inner-layout .app-inner-layout__wrapper .app-inner-layout__sidebar .app-inner-layout__sidebar-footer,
.app-inner-layout .app-inner-layout__wrapper .app-inner-layout__sidebar .app-inner-layout__sidebar-header {
  background: #f8f9fa;
}

.app-inner-layout .app-inner-layout__wrapper .app-inner-layout__aside {
  width: 60px;
}

.app-inner-layout .app-inner-layout__footer {
  width: 100%;
  height: 50px;
}

.app-wrapper-footer .app-footer {
  border-top: #e9ecef solid 1px;
}

.app-wrapper-footer .app-footer .app-footer__inner {
  border-left: #e9ecef solid 1px;
}

.chat-layout.app-inner-layout .app-inner-layout__sidebar {
  width: 360px;
  flex: 0 0 360px;
}

.badge-pulse {
  -webkit-animation: pulse 2s ease-out;
  -webkit-animation-iteration-count: infinite;
  position: relative;
}

.badge-pulse::before,
.badge-pulse::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  border: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  -webkit-animation: sploosh 2s cubic-bezier(0.165, 0.84, 0.44, 1);
  -webkit-animation-iteration-count: infinite;
}

.badge-pulse::after {
  -webkit-animation-delay: 0.33s;
  -webkit-animation-duration: 2.2s;
}

.card {
  box-shadow: 0 0.46875rem 2.1875rem rgba(8, 10, 37, 0.03), 0 0.9375rem 1.40625rem rgba(8, 10, 37, 0.03), 0 0.25rem 0.53125rem rgba(8, 10, 37, 0.05), 0 0.125rem 0.1875rem rgba(8, 10, 37, 0.03);
  border-width: 0;
  transition: all 0.2s;
}

.card>.dropdown-menu-header {
  margin: 0;
}

.card>.dropdown-menu-header .dropdown-menu-header-inner {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.card.text-dark.text-white .card-footer,
.card.text-dark.text-white .card-header,
.card.text-white .card-footer,
.card.text-white .card-header {
  background: rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 0.9);
}

.card.text-dark.text-white .card-footer,
.card.text-dark.text-white .card-header {
  color: rgba(0, 0, 0, 0.9);
}

.card .card-footer {
  display: flex;
  align-items: center;
}

.btn-actions-pane-right {
  margin-left: auto;
  white-space: nowrap;
}

.btn-actions-pane-left {
  margin-right: auto;
}

.actions-icon-btn .btn-icon-only {
  padding-left: 0;
  padding-right: 0;
  color: #495057;
}

.actions-icon-btn .btn-icon-only .btn-icon-wrapper {
  font-size: 1.3rem;
  width: 30px;
  text-align: center;
}

.actions-icon-btn .btn-icon-only:hover {
  color: #545cd8;
}

.card-header,
.card-title {
  text-transform: uppercase;
  color: rgba(18, 21, 78, 0.7);
  font-weight: bold;
  font-size: 0.88rem;
}

.card-header {
  display: flex;
  align-items: center;
  border-bottom-width: 1px;
  padding-top: 0;
  padding-bottom: 0;
  padding-right: 0.5rem;
  height: 3.5rem;
}

.card-header.no-border {
  border: 0;
  padding: 0;
  height: auto;
}

.card-header .menu-header-subtitle {
  display: block;
}

.card-header.card-header-tab .nav {
  width: auto;
  margin-left: auto;
}

.card-header.card-header-tab .card-header-title {
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.card-header .header-icon {
  font-size: 1.65rem;
  margin-right: 0.5rem;
}

.card-header>.nav {
  margin-left: -0.5rem;
  height: 100%;
  width: 100%;
}

.card-header>.nav .nav-item {
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
}

.card-header>.nav .nav-link {
  text-transform: none;
  width: 100%;
  display: block;
  color: #495057;
}

.card-header>.nav .nav-link::before {
  content: "";
  border-radius: 15px;
  background: #545cd8;
  transition: all 0.2s;
  height: 4px;
  width: 100%;
  position: absolute;
  left: 0;
  bottom: -2px;
  opacity: 0;
}

.card-header>.nav .nav-link:hover {
  color: #545cd8;
}

.card-header>.nav .nav-link.active {
  color: #545cd8;
}

.card-header>.nav .nav-link.active::before {
  opacity: 1;
}

.card-header.card-header-tab-animation .nav .nav-link::before {
  transform: scale(0);
  opacity: 1;
  width: 90%;
  left: 5%;
}

.card-header.card-header-tab-animation .nav .nav-link.active::before,
.card-header.card-header-tab-animation .nav .nav-link:hover::before {
  transform: scale(1);
}

.card-border {
  box-shadow: 0 0 0 transparent;
  border-width: 1px;
}

.card-hover-shadow:hover {
  box-shadow: 0 0.46875rem 2.1875rem rgba(8, 10, 37, 0.03), 0 0.9375rem 1.40625rem rgba(8, 10, 37, 0.03), 0 0.25rem 0.53125rem rgba(8, 10, 37, 0.05), 0 0.125rem 0.1875rem rgba(8, 10, 37, 0.03);
}

.card-hover-shadow-2x:hover {
  box-shadow: 0 0.66875rem 2.3875rem rgba(8, 10, 37, 0.03), 0 1.1375rem 1.60625rem rgba(8, 10, 37, 0.03), 0 0.45rem 0.73125rem rgba(8, 10, 37, 0.05), 0 0.325rem 0.3875rem rgba(8, 10, 37, 0.03);
}

.card-subtitle {
  margin-bottom: 1rem;
  font-size: 0.968rem;
  color: rgba(18, 21, 78, 0.55);
}

.card-shadow-primary {
  box-shadow: 0 0.46875rem 2.1875rem rgba(84, 92, 216, 0.03), 0 0.9375rem 1.40625rem rgba(84, 92, 216, 0.03), 0 0.25rem 0.53125rem rgba(84, 92, 216, 0.05), 0 0.125rem 0.1875rem rgba(84, 92, 216, 0.03);
}

.card-shadow-secondary {
  box-shadow: 0 0.46875rem 2.1875rem rgba(108, 117, 125, 0.03), 0 0.9375rem 1.40625rem rgba(108, 117, 125, 0.03), 0 0.25rem 0.53125rem rgba(108, 117, 125, 0.05), 0 0.125rem 0.1875rem rgba(108, 117, 125, 0.03);
}

.card-shadow-success {
  box-shadow: 0 0.46875rem 2.1875rem rgba(58, 196, 125, 0.03), 0 0.9375rem 1.40625rem rgba(58, 196, 125, 0.03), 0 0.25rem 0.53125rem rgba(58, 196, 125, 0.05), 0 0.125rem 0.1875rem rgba(58, 196, 125, 0.03);
}

.card-shadow-info {
  box-shadow: 0 0.46875rem 2.1875rem rgba(48, 177, 255, 0.03), 0 0.9375rem 1.40625rem rgba(48, 177, 255, 0.03), 0 0.25rem 0.53125rem rgba(48, 177, 255, 0.05), 0 0.125rem 0.1875rem rgba(48, 177, 255, 0.03);
}

.card-shadow-warning {
  box-shadow: 0 0.46875rem 2.1875rem rgba(247, 185, 36, 0.03), 0 0.9375rem 1.40625rem rgba(247, 185, 36, 0.03), 0 0.25rem 0.53125rem rgba(247, 185, 36, 0.05), 0 0.125rem 0.1875rem rgba(247, 185, 36, 0.03);
}

.card-shadow-danger {
  box-shadow: 0 0.46875rem 2.1875rem rgba(217, 37, 80, 0.03), 0 0.9375rem 1.40625rem rgba(217, 37, 80, 0.03), 0 0.25rem 0.53125rem rgba(217, 37, 80, 0.05), 0 0.125rem 0.1875rem rgba(217, 37, 80, 0.03);
}

.card-shadow-light {
  box-shadow: 0 0.46875rem 2.1875rem rgba(238, 238, 238, 0.03), 0 0.9375rem 1.40625rem rgba(238, 238, 238, 0.03), 0 0.25rem 0.53125rem rgba(238, 238, 238, 0.05), 0 0.125rem 0.1875rem rgba(238, 238, 238, 0.03);
}

.card-shadow-dark {
  box-shadow: 0 0.46875rem 2.1875rem rgba(52, 58, 64, 0.03), 0 0.9375rem 1.40625rem rgba(52, 58, 64, 0.03), 0 0.25rem 0.53125rem rgba(52, 58, 64, 0.05), 0 0.125rem 0.1875rem rgba(52, 58, 64, 0.03);
}

.card-shadow-focus {
  box-shadow: 0 0.46875rem 2.1875rem rgba(68, 64, 84, 0.03), 0 0.9375rem 1.40625rem rgba(68, 64, 84, 0.03), 0 0.25rem 0.53125rem rgba(68, 64, 84, 0.05), 0 0.125rem 0.1875rem rgba(68, 64, 84, 0.03);
}

.card-shadow-alternate {
  box-shadow: 0 0.46875rem 2.1875rem rgba(131, 88, 138, 0.03), 0 0.9375rem 1.40625rem rgba(131, 88, 138, 0.03), 0 0.25rem 0.53125rem rgba(131, 88, 138, 0.05), 0 0.125rem 0.1875rem rgba(131, 88, 138, 0.03);
}

.card-header-lg {
  padding: 2rem 2rem;
  height: auto;
}

.sticky-active-class .sticky-inner-wrapper>div {
  box-shadow: 0 0.46875rem 2.1875rem rgba(8, 10, 37, 0.03), 0 0.9375rem 1.40625rem rgba(8, 10, 37, 0.03), 0 0.25rem 0.53125rem rgba(8, 10, 37, 0.05), 0 0.125rem 0.1875rem rgba(8, 10, 37, 0.03);
}

.nav-pills,
.nav-tabs {
  margin-bottom: 1rem;
}

.nav-link {
  font-weight: bold;
}

.nav-link:hover {
  cursor: pointer;
}

.nav-tabs .nav-link:hover {
  color: #545cd8 !important;
}

.nav-tabs .nav-link.active {
  color: #545cd8;
}

.nav-pills .nav-link:hover {
  color: #545cd8 !important;
}

.nav-pills .nav-link.active {
  background: #545cd8;
}

.nav-pills .nav-link.active:hover {
  color: #fff !important;
}
body {
  -webkit-backface-visibility: hidden;
}

.animated,
.TabsAnimation-appear {
  -webkit-animation-duration: calc(1s);
  animation-duration: calc(1s);
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.animated.infinite,
.infinite.TabsAnimation-appear {
  animation-iteration-count: infinite;
}

.animated.hinge,
.hinge.TabsAnimation-appear {
  -webkit-animation-duration: calc(1s * 2);
  animation-duration: calc(1s * 2);
}

.animated.bounceIn,
.bounceIn.TabsAnimation-appear,
.animated.bounceOut,
.bounceOut.TabsAnimation-appear {
  -webkit-animation-duration: calc(1s * 0.75);
  animation-duration: calc(1s * 0.75);
}

.animated.flipOutX,
.flipOutX.TabsAnimation-appear,
.animated.flipOutY,
.flipOutY.TabsAnimation-appear {
  -webkit-animation-duration: calc(1s * 0.75);
  animation-duration: calc(1s * 0.75);
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}

@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
}

@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.fadeInUp,
.TabsAnimation-appear {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
}

@-webkit-keyframes lightSpeedIn {
  0% {
    -webkit-transform: translateX(100%) skewX(-30deg);
    opacity: 0;
  }

  60% {
    -webkit-transform: translateX(-20%) skewX(30deg);
    opacity: 1;
  }

  80% {
    -webkit-transform: translateX(0%) skewX(-15deg);
    opacity: 1;
  }

  100% {
    -webkit-transform: translateX(0%) skewX(0deg);
    opacity: 1;
  }
}

@keyframes lightSpeedIn {
  0% {
    transform: translateX(100%) skewX(-30deg);
    opacity: 0;
  }

  60% {
    transform: translateX(-20%) skewX(30deg);
    opacity: 1;
  }

  80% {
    transform: translateX(0%) skewX(-15deg);
    opacity: 1;
  }

  100% {
    transform: translateX(0%) skewX(0deg);
    opacity: 1;
  }
}

.lightSpeedIn {
  -webkit-animation-name: lightSpeedIn;
  animation-name: lightSpeedIn;
  -webkit-animation-timing-function: ease-out;
  animation-timing-function: ease-out;
}

@-webkit-keyframes lightSpeedOut {
  0% {
    -webkit-transform: translateX(0%) skewX(0deg);
    opacity: 1;
  }

  100% {
    -webkit-transform: translateX(100%) skewX(-30deg);
    opacity: 0;
  }
}

@keyframes lightSpeedOut {
  0% {
    transform: translateX(0%) skewX(0deg);
    opacity: 1;
  }

  100% {
    transform: translateX(100%) skewX(-30deg);
    opacity: 0;
  }
}

.lightSpeedOut {
  -webkit-animation-name: lightSpeedOut;
  animation-name: lightSpeedOut;
  -webkit-animation-timing-function: ease-in;
  animation-timing-function: ease-in;
}

.dropdown-menu.show {
  animation: fade-in2 0.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

.popover.show {
  display: block;
  animation: fade-in2 0.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

.dropdown-menu[data-placement^=top].show {
  animation: fade-in3 0.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  bottom: auto !important;
  top: auto !important;
}

@keyframes fade-in2 {
  0% {
    margin-top: -50px;
    visibility: hidden;
    opacity: 0;
  }

  100% {
    margin-top: 0px;
    visibility: visible;
    opacity: 1;
  }
}

.form-control {
  transition: all 0.2s;
}

.btn-outline-2x {
  border-width: 2px;
}

.btn-group .btn {
  font-size: 0.8rem;
  font-weight: 500;
}

.btn-group .btn-outline-2x+.btn-outline-2x {
  margin-left: -2px;
}

.btn-group .btn-square {
  border-radius: 0;
}

.btn {
  font-size: 0.8rem;
  font-weight: 500;
}

.btn.btn-pill.btn-wide,
.btn.btn-pill {
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
}

.btn-dashed {
  border-style: dashed;
}

.btn-icon {
  vertical-align: bottom;
}

.btn-icon.btn-icon-right .btn-icon-wrapper {
  margin-left: 0.5rem;
  margin-right: 0;
}

.btn-icon .btn-icon-wrapper {
  margin-right: 0.5rem;
  margin-left: 0;
  margin-top: 0;
  font-size: 17px;
  vertical-align: middle;
  transition: color 0.1s;
  display: inline-block;
}

.btn-icon.btn-link {
  text-decoration: none;
}

.btn-icon.btn-lg:not(.btn-block) .btn-icon-wrapper,
.btn-group-lg>.btn-icon.btn:not(.btn-block) .btn-icon-wrapper {
  font-size: 25px;
}

.btn-icon.btn-sm:not(.btn-block) .btn-icon-wrapper,
.btn-group-sm>.btn-icon.btn:not(.btn-block) .btn-icon-wrapper {
  font-size: 16px;
}

.btn-icon-only .btn-icon-wrapper {
  margin-left: 0;
  margin-right: 0;
}

.btn-hover-shine {
  position: relative;
}

.btn-hover-shine:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.4);
  -webkit-transition: none;
  -moz-transition: none;
  transition: none;
}

.btn-hover-shine:hover:after {
  width: 120%;
  background-color: rgba(255, 255, 255, 0);
  transition: all 0.4s ease-in-out;
}

.btn-hover-shine.btn-pill::after {
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
}

.btn-icon-vertical {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.btn-icon-vertical .btn-icon-wrapper {
  display: block;
  font-size: 200%;
  margin: 5px 0;
  opacity: 0.6;
}

.btn-icon-vertical.btn-link {
  text-decoration: none;
}

.btn-icon-vertical.btn-lg:not(.btn-block) .btn-icon-wrapper,
.btn-group-lg>.btn-icon-vertical.btn:not(.btn-block) .btn-icon-wrapper {
  font-size: 25px;
}

.btn-icon-vertical.btn-sm:not(.btn-block) .btn-icon-wrapper,
.btn-group-sm>.btn-icon-vertical.btn:not(.btn-block) .btn-icon-wrapper {
  font-size: 16px;
}

.btn-icon-vertical:active .btn-icon-wrapper,
.btn-icon-vertical.active .btn-icon-wrapper,
.btn-icon-vertical:hover .btn-icon-wrapper {
  opacity: 1;
}

.btn-icon-vertical.btn-icon-bottom .btn-icon-wrapper {
  margin: 0.2rem 0 5px;
}

.btn-icon-vertical.btn-transition-text .btn-icon-wrapper {
  transition: all 0.2s !important;
}

.btn-icon-vertical.btn-transition-text:hover .btn-icon-wrapper {
  transform: scale(1.3);
}

.btn-icon-vertical.btn-transition-text.btn-transition-alt:hover .btn-icon-wrapper {
  color: #fff !important;
}

.btn-icon-lg {
  font-size: 2.2rem !important;
}

.btn-transition {
  color: #6c757d;
  border-color: #e9ecef;
  background-color: none;
}

.btn-transition.btn-outline-link {
  border-color: transparent;
  background-color: transparent;
}

.btn-transition.btn-outline-link:hover {
  color: #545cd8;
  background: #f8f9fa;
}

.btn-transition.disabled,
.btn-transition:disabled {
  color: #6c757d;
  border-color: #e9ecef;
}

.btn-transition:hover .btn-icon-wrapper {
  transition: none;
}

.btn-transition-alt:hover .icon-gradient {
  -webkit-background-clip: initial;
  -webkit-text-fill-color: initial;
  background-clip: initial;
  text-fill-color: initial;
  background: none !important;
  color: #fff;
}

.btn-square {
  border-radius: 0 !important;
}

.btn.btn-wide {
  padding: 0.375rem 1.5rem;
  font-size: 0.8rem;
  border-radius: 0.25rem;
}

.btn-lg.btn-wide,
.btn-group-lg>.btn-wide.btn {
  padding: 0.5rem 2rem;
  font-size: 1.1rem;
  border-radius: 0.3rem;
}

.btn-sm.btn-wide,
.btn-group-sm>.btn-wide.btn {
  padding: 0.25rem 1rem;
  font-size: 0.8rem;
  border-radius: 0.2rem;
}

.dropdown-toggle::after {
  position: relative;
  top: 2px;
  opacity: 0.8;
}

.dropright .dropdown-toggle::after {
  top: 0;
}

.dropdown-toggle-split {
  border-left: rgba(255, 255, 255, 0.1) solid 1px;
}

.btn-gradient-primary {
  background-image: linear-gradient(140deg, #2a32b6 -30%, #545cd8 90%);
  background-color: #2a32b6;
  border-color: #2a32b6;
  color: color-yiq(#20278c);
}

.btn-gradient-primary.active,
.btn-gradient-primary:active,
.btn-gradient-primary:not(:disabled):not(.disabled):hover {
  background-image: linear-gradient(120deg, #272faa 0%, #4850d5 100%);
  color: color-yiq(#20278c);
  border-color: #20278c;
}

.btn-gradient-primary:focus,
.btn-gradient-primary.focus,
.btn-gradient-primary:active,
.btn-gradient-primary.active {
  color: color-yiq(#20278c) !important;
  border-color: #252ca1 !important;
}

.btn-gradient-primary.btn-shadow {
  box-shadow: 0 0.125rem 0.625rem rgba(84, 92, 216, 0.4), 0 0.0625rem 0.125rem rgba(84, 92, 216, 0.5);
}

.btn-gradient-primary.btn-shadow:hover {
  box-shadow: 0 0.125rem 0.625rem rgba(84, 92, 216, 0.5), 0 0.0625rem 0.125rem rgba(84, 92, 216, 0.6);
}

.btn-gradient-secondary {
  background-image: linear-gradient(140deg, #494f54 -30%, #6c757d 90%);
  background-color: #494f54;
  border-color: #494f54;
  color: color-yiq(#313539);
}

.btn-gradient-secondary.active,
.btn-gradient-secondary:active,
.btn-gradient-secondary:not(:disabled):not(.disabled):hover {
  background-image: linear-gradient(120deg, #41474c 0%, #656d75 100%);
  color: color-yiq(#313539);
  border-color: #313539;
}

.btn-gradient-secondary:focus,
.btn-gradient-secondary.focus,
.btn-gradient-secondary:active,
.btn-gradient-secondary.active {
  color: color-yiq(#313539) !important;
  border-color: #3d4246 !important;
}

.btn-gradient-secondary.btn-shadow {
  box-shadow: 0 0.125rem 0.625rem rgba(108, 117, 125, 0.4), 0 0.0625rem 0.125rem rgba(108, 117, 125, 0.5);
}

.btn-gradient-secondary.btn-shadow:hover {
  box-shadow: 0 0.125rem 0.625rem rgba(108, 117, 125, 0.5), 0 0.0625rem 0.125rem rgba(108, 117, 125, 0.6);
}

.btn-gradient-success {
  background-image: linear-gradient(140deg, #298957 -30%, #3ac47d 90%);
  background-color: #298957;
  border-color: #298957;
  color: color-yiq(#1d623e);
}

.btn-gradient-success.active,
.btn-gradient-success:active,
.btn-gradient-success:not(:disabled):not(.disabled):hover {
  background-image: linear-gradient(120deg, #257d50 0%, #37b875 100%);
  color: color-yiq(#1d623e);
  border-color: #1d623e;
}

.btn-gradient-success:focus,
.btn-gradient-success.focus,
.btn-gradient-success:active,
.btn-gradient-success.active {
  color: color-yiq(#1d623e) !important;
  border-color: #23754b !important;
}

.btn-gradient-success.btn-shadow {
  box-shadow: 0 0.125rem 0.625rem rgba(58, 196, 125, 0.4), 0 0.0625rem 0.125rem rgba(58, 196, 125, 0.5);
}

.btn-gradient-success.btn-shadow:hover {
  box-shadow: 0 0.125rem 0.625rem rgba(58, 196, 125, 0.5), 0 0.0625rem 0.125rem rgba(58, 196, 125, 0.6);
}

.btn-gradient-info {
  background-image: linear-gradient(140deg, #008de3 -30%, #30b1ff 90%);
  background-color: #008de3;
  border-color: #008de3;
  color: color-yiq(#006db0);
}

.btn-gradient-info.active,
.btn-gradient-info:active,
.btn-gradient-info:not(:disabled):not(.disabled):hover {
  background-image: linear-gradient(120deg, #0084d3 0%, #21abff 100%);
  color: color-yiq(#006db0);
  border-color: #006db0;
}

.btn-gradient-info:focus,
.btn-gradient-info.focus,
.btn-gradient-info:active,
.btn-gradient-info.active {
  color: color-yiq(#006db0) !important;
  border-color: #007dc9 !important;
}

.btn-gradient-info.btn-shadow {
  box-shadow: 0 0.125rem 0.625rem rgba(48, 177, 255, 0.4), 0 0.0625rem 0.125rem rgba(48, 177, 255, 0.5);
}

.btn-gradient-info.btn-shadow:hover {
  box-shadow: 0 0.125rem 0.625rem rgba(48, 177, 255, 0.5), 0 0.0625rem 0.125rem rgba(48, 177, 255, 0.6);
}

.btn-gradient-warning {
  background-image: linear-gradient(140deg, #c78f07 -30%, #f7b924 90%);
  background-color: #c78f07;
  border-color: #c78f07;
  color: color-yiq(#966c05);
}

.btn-gradient-warning.active,
.btn-gradient-warning:active,
.btn-gradient-warning:not(:disabled):not(.disabled):hover {
  background-image: linear-gradient(120deg, #b88407 0%, #f6b415 100%);
  color: color-yiq(#966c05);
  border-color: #966c05;
}

.btn-gradient-warning:focus,
.btn-gradient-warning.focus,
.btn-gradient-warning:active,
.btn-gradient-warning.active {
  color: color-yiq(#966c05) !important;
  border-color: #af7d06 !important;
}

.btn-gradient-warning.btn-shadow {
  box-shadow: 0 0.125rem 0.625rem rgba(247, 185, 36, 0.4), 0 0.0625rem 0.125rem rgba(247, 185, 36, 0.5);
}

.btn-gradient-warning.btn-shadow:hover {
  box-shadow: 0 0.125rem 0.625rem rgba(247, 185, 36, 0.5), 0 0.0625rem 0.125rem rgba(247, 185, 36, 0.6);
}

.btn-gradient-danger {
  background-image: linear-gradient(140deg, #981a38 -30%, #d92550 90%);
  background-color: #981a38;
  border-color: #981a38;
  color: color-yiq(#6c1228);
}

.btn-gradient-danger.active,
.btn-gradient-danger:active,
.btn-gradient-danger:not(:disabled):not(.disabled):hover {
  background-image: linear-gradient(120deg, #8b1833 0%, #cc234b 100%);
  color: color-yiq(#6c1228);
  border-color: #6c1228;
}

.btn-gradient-danger:focus,
.btn-gradient-danger.focus,
.btn-gradient-danger:active,
.btn-gradient-danger.active {
  color: color-yiq(#6c1228) !important;
  border-color: #821630 !important;
}

.btn-gradient-danger.btn-shadow {
  box-shadow: 0 0.125rem 0.625rem rgba(217, 37, 80, 0.4), 0 0.0625rem 0.125rem rgba(217, 37, 80, 0.5);
}

.btn-gradient-danger.btn-shadow:hover {
  box-shadow: 0 0.125rem 0.625rem rgba(217, 37, 80, 0.5), 0 0.0625rem 0.125rem rgba(217, 37, 80, 0.6);
}

.btn-gradient-focus {
  background-image: linear-gradient(140deg, #211f29 -30%, #444054 90%);
  background-color: #211f29;
  border-color: #211f29;
  color: color-yiq(#09090c);
}

.btn-gradient-focus.active,
.btn-gradient-focus:active,
.btn-gradient-focus:not(:disabled):not(.disabled):hover {
  background-image: linear-gradient(120deg, #1a1820 0%, #3d394b 100%);
  color: color-yiq(#09090c);
  border-color: #09090c;
}

.btn-gradient-focus:focus,
.btn-gradient-focus.focus,
.btn-gradient-focus:active,
.btn-gradient-focus.active {
  color: color-yiq(#09090c) !important;
  border-color: #15141a !important;
}

.btn-gradient-focus.btn-shadow {
  box-shadow: 0 0.125rem 0.625rem rgba(68, 64, 84, 0.4), 0 0.0625rem 0.125rem rgba(68, 64, 84, 0.5);
}

.btn-gradient-focus.btn-shadow:hover {
  box-shadow: 0 0.125rem 0.625rem rgba(68, 64, 84, 0.5), 0 0.0625rem 0.125rem rgba(68, 64, 84, 0.6);
}

.btn-gradient-alternate {
  background-image: linear-gradient(140deg, #573a5b -30%, #83588a 90%);
  background-color: #573a5b;
  border-color: #573a5b;
  color: color-yiq(#39263c);
}

.btn-gradient-alternate.active,
.btn-gradient-alternate:active,
.btn-gradient-alternate:not(:disabled):not(.disabled):hover {
  background-image: linear-gradient(120deg, #4e3452 0%, #7a5281 100%);
  color: color-yiq(#39263c);
  border-color: #39263c;
}

.btn-gradient-alternate:focus,
.btn-gradient-alternate.focus,
.btn-gradient-alternate:active,
.btn-gradient-alternate.active {
  color: color-yiq(#39263c) !important;
  border-color: #48304c !important;
}

.btn-gradient-alternate.btn-shadow {
  box-shadow: 0 0.125rem 0.625rem rgba(131, 88, 138, 0.4), 0 0.0625rem 0.125rem rgba(131, 88, 138, 0.5);
}

.btn-gradient-alternate.btn-shadow:hover {
  box-shadow: 0 0.125rem 0.625rem rgba(131, 88, 138, 0.5), 0 0.0625rem 0.125rem rgba(131, 88, 138, 0.6);
}

.btn-gradient-light {
  background-image: linear-gradient(140deg, #c8c8c8 -30%, #eeeeee 90%);
  background-color: #c8c8c8;
  border-color: #c8c8c8;
  color: color-yiq(#aeaeae);
}

.btn-gradient-light.active,
.btn-gradient-light:active,
.btn-gradient-light:not(:disabled):not(.disabled):hover {
  background-image: linear-gradient(120deg, silver 0%, #e6e6e6 100%);
  color: color-yiq(#aeaeae);
  border-color: #aeaeae;
}

.btn-gradient-light:focus,
.btn-gradient-light.focus,
.btn-gradient-light:active,
.btn-gradient-light.active {
  color: color-yiq(#aeaeae) !important;
  border-color: #bbbbbb !important;
}

.btn-gradient-light.btn-shadow {
  box-shadow: 0 0.125rem 0.625rem rgba(238, 238, 238, 0.4), 0 0.0625rem 0.125rem rgba(238, 238, 238, 0.5);
}

.btn-gradient-light.btn-shadow:hover {
  box-shadow: 0 0.125rem 0.625rem rgba(238, 238, 238, 0.5), 0 0.0625rem 0.125rem rgba(238, 238, 238, 0.6);
}

.btn-gradient-dark {
  background-image: linear-gradient(140deg, #121416 -30%, #343a40 90%);
  background-color: #121416;
  border-color: #121416;
  color: color-yiq(black);
}

.btn-gradient-dark.active,
.btn-gradient-dark:active,
.btn-gradient-dark:not(:disabled):not(.disabled):hover {
  background-image: linear-gradient(120deg, #0b0c0d 0%, #2d3238 100%);
  color: color-yiq(black);
  border-color: black;
}

.btn-gradient-dark:focus,
.btn-gradient-dark.focus,
.btn-gradient-dark:active,
.btn-gradient-dark.active {
  color: color-yiq(black) !important;
  border-color: #060708 !important;
}

.btn-gradient-dark.btn-shadow {
  box-shadow: 0 0.125rem 0.625rem rgba(52, 58, 64, 0.4), 0 0.0625rem 0.125rem rgba(52, 58, 64, 0.5);
}

.btn-gradient-dark.btn-shadow:hover {
  box-shadow: 0 0.125rem 0.625rem rgba(52, 58, 64, 0.5), 0 0.0625rem 0.125rem rgba(52, 58, 64, 0.6);
}

.btn-shadow.active {
  box-shadow: 0 0 0 0 transparent !important;
}

.btn-primary {
  color: #fff;
  background-color: #005800;
  border-color: #005800;
  border-radius: none;
}

.btn-primary:hover {
  color: #fff;
  background-color: #005800;
  border-color: #005800;
}

.btn-check:focus+.btn-primary,
.btn-primary:focus {
  color: #fff;
  background-color: #474eb8;
  border-color: #434aad;
  box-shadow: 0 0 0 0 rgba(110, 116, 222, 0.5);
}

.btn-check:checked+.btn-primary,
.btn-check:active+.btn-primary,
.btn-primary:active,
.btn-primary.active,
.show>.btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #005800;
  border-color: #005800;
}

.btn-check:checked+.btn-primary:focus,
.btn-check:active+.btn-primary:focus,
.btn-primary:active:focus,
.btn-primary.active:focus,
.show>.btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(110, 116, 222, 0.5);
}

.btn-primary:disabled,
.btn-primary.disabled {
  color: #fff;
  background-color: #545cd8;
  border-color: #545cd8;
}

.btn-primary.btn-shadow {
  box-shadow: 0 0.125rem 0.625rem rgba(84, 92, 216, 0.4), 0 0.0625rem 0.125rem rgba(84, 92, 216, 0.5);
}

.btn-primary.btn-shadow:hover {
  box-shadow: 0 0.125rem 0.625rem rgba(84, 92, 216, 0.5), 0 0.0625rem 0.125rem rgba(84, 92, 216, 0.6);
}

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-secondary:hover {
  color: #fff;
  background-color: #5c636a;
  border-color: #565e64;
}

.btn-check:focus+.btn-secondary,
.btn-secondary:focus {
  color: #fff;
  background-color: #5c636a;
  border-color: #565e64;
  box-shadow: 0 0 0 0 rgba(130, 138, 145, 0.5);
}

.btn-check:checked+.btn-secondary,
.btn-check:active+.btn-secondary,
.btn-secondary:active,
.btn-secondary.active,
.show>.btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #565e64;
  border-color: #51585e;
}

.btn-check:checked+.btn-secondary:focus,
.btn-check:active+.btn-secondary:focus,
.btn-secondary:active:focus,
.btn-secondary.active:focus,
.show>.btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(130, 138, 145, 0.5);
}

.btn-secondary:disabled,
.btn-secondary.disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-secondary.btn-shadow {
  box-shadow: 0 0.125rem 0.625rem rgba(108, 117, 125, 0.4), 0 0.0625rem 0.125rem rgba(108, 117, 125, 0.5);
}

.btn-secondary.btn-shadow:hover {
  box-shadow: 0 0.125rem 0.625rem rgba(108, 117, 125, 0.5), 0 0.0625rem 0.125rem rgba(108, 117, 125, 0.6);
}

.btn-success {
  color: #000;
  background-color: #3ac47d;
  border-color: #3ac47d;
}

.btn-success:hover {
  color: #000;
  background-color: #58cd91;
  border-color: #4eca8a;
}

.btn-check:focus+.btn-success,
.btn-success:focus {
  color: #000;
  background-color: #58cd91;
  border-color: #4eca8a;
  box-shadow: 0 0 0 0 rgba(49, 167, 106, 0.5);
}

.btn-check:checked+.btn-success,
.btn-check:active+.btn-success,
.btn-success:active,
.btn-success.active,
.show>.btn-success.dropdown-toggle {
  color: #000;
  background-color: #61d097;
  border-color: #4eca8a;
}

.btn-check:checked+.btn-success:focus,
.btn-check:active+.btn-success:focus,
.btn-success:active:focus,
.btn-success.active:focus,
.show>.btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(49, 167, 106, 0.5);
}

.btn-success:disabled,
.btn-success.disabled {
  color: #000;
  background-color: #3ac47d;
  border-color: #3ac47d;
}

.btn-success.btn-shadow {
  box-shadow: 0 0.125rem 0.625rem rgba(58, 196, 125, 0.4), 0 0.0625rem 0.125rem rgba(58, 196, 125, 0.5);
}

.btn-success.btn-shadow:hover {
  box-shadow: 0 0.125rem 0.625rem rgba(58, 196, 125, 0.5), 0 0.0625rem 0.125rem rgba(58, 196, 125, 0.6);
}

.btn-info {
  color: #000;
  background-color: #30b1ff;
  border-color: #30b1ff;
}

.btn-info:hover {
  color: #000;
  background-color: #4fbdff;
  border-color: #45b9ff;
}

.btn-check:focus+.btn-info,
.btn-info:focus {
  color: #000;
  background-color: #4fbdff;
  border-color: #45b9ff;
  box-shadow: 0 0 0 0 rgba(41, 150, 217, 0.5);
}

.btn-check:checked+.btn-info,
.btn-check:active+.btn-info,
.btn-info:active,
.btn-info.active,
.show>.btn-info.dropdown-toggle {
  color: #000;
  background-color: #59c1ff;
  border-color: #45b9ff;
}

.btn-check:checked+.btn-info:focus,
.btn-check:active+.btn-info:focus,
.btn-info:active:focus,
.btn-info.active:focus,
.show>.btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(41, 150, 217, 0.5);
}

.btn-info:disabled,
.btn-info.disabled {
  color: #000;
  background-color: #30b1ff;
  border-color: #30b1ff;
}

.btn-info.btn-shadow {
  box-shadow: 0 0.125rem 0.625rem rgba(48, 177, 255, 0.4), 0 0.0625rem 0.125rem rgba(48, 177, 255, 0.5);
}

.btn-info.btn-shadow:hover {
  box-shadow: 0 0.125rem 0.625rem rgba(48, 177, 255, 0.5), 0 0.0625rem 0.125rem rgba(48, 177, 255, 0.6);
}

.btn-warning {
  color: #000;
  background-color: #f7b924;
  border-color: #f7b924;
}

.btn-warning:hover {
  color: #000;
  background-color: #f8c445;
  border-color: #f8c03a;
}

.btn-check:focus+.btn-warning,
.btn-warning:focus {
  color: #000;
  background-color: #f8c445;
  border-color: #f8c03a;
  box-shadow: 0 0 0 0 rgba(210, 157, 31, 0.5);
}

.btn-check:checked+.btn-warning,
.btn-check:active+.btn-warning,
.btn-warning:active,
.btn-warning.active,
.show>.btn-warning.dropdown-toggle {
  color: #000;
  background-color: #f9c750;
  border-color: #f8c03a;
}

.btn-check:checked+.btn-warning:focus,
.btn-check:active+.btn-warning:focus,
.btn-warning:active:focus,
.btn-warning.active:focus,
.show>.btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(210, 157, 31, 0.5);
}

.btn-warning:disabled,
.btn-warning.disabled {
  color: #000;
  background-color: #f7b924;
  border-color: #f7b924;
}

.btn-warning.btn-shadow {
  box-shadow: 0 0.125rem 0.625rem rgba(247, 185, 36, 0.4), 0 0.0625rem 0.125rem rgba(247, 185, 36, 0.5);
}

.btn-warning.btn-shadow:hover {
  box-shadow: 0 0.125rem 0.625rem rgba(247, 185, 36, 0.5), 0 0.0625rem 0.125rem rgba(247, 185, 36, 0.6);
}

.btn-danger {
  color: #fff;
  background-color: #d92550;
  border-color: #d92550;
}

.btn-danger:hover {
  color: #fff;
  background-color: #b81f44;
  border-color: #ae1e40;
}

.btn-check:focus+.btn-danger,
.btn-danger:focus {
  color: #fff;
  background-color: #b81f44;
  border-color: #ae1e40;
  box-shadow: 0 0 0 0 rgba(223, 70, 106, 0.5);
}

.btn-check:checked+.btn-danger,
.btn-check:active+.btn-danger,
.btn-danger:active,
.btn-danger.active,
.show>.btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #ae1e40;
  border-color: #a31c3c;
}

.btn-check:checked+.btn-danger:focus,
.btn-check:active+.btn-danger:focus,
.btn-danger:active:focus,
.btn-danger.active:focus,
.show>.btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(223, 70, 106, 0.5);
}

.btn-danger:disabled,
.btn-danger.disabled {
  color: #fff;
  background-color: #d92550;
  border-color: #d92550;
}

.btn-danger.btn-shadow {
  box-shadow: 0 0.125rem 0.625rem rgba(217, 37, 80, 0.4), 0 0.0625rem 0.125rem rgba(217, 37, 80, 0.5);
}

.btn-danger.btn-shadow:hover {
  box-shadow: 0 0.125rem 0.625rem rgba(217, 37, 80, 0.5), 0 0.0625rem 0.125rem rgba(217, 37, 80, 0.6);
}

.btn-light {
  color: #000;
  background-color: #eeeeee;
  border-color: #eeeeee;
}

.btn-light:hover {
  color: #000;
  background-color: #f1f1f1;
  border-color: #f0f0f0;
}

.btn-check:focus+.btn-light,
.btn-light:focus {
  color: #000;
  background-color: #f1f1f1;
  border-color: #f0f0f0;
  box-shadow: 0 0 0 0 rgba(202, 202, 202, 0.5);
}

.btn-check:checked+.btn-light,
.btn-check:active+.btn-light,
.btn-light:active,
.btn-light.active,
.show>.btn-light.dropdown-toggle {
  color: #000;
  background-color: #f1f1f1;
  border-color: #f0f0f0;
}

.btn-check:checked+.btn-light:focus,
.btn-check:active+.btn-light:focus,
.btn-light:active:focus,
.btn-light.active:focus,
.show>.btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(202, 202, 202, 0.5);
}

.btn-light:disabled,
.btn-light.disabled {
  color: #000;
  background-color: #eeeeee;
  border-color: #eeeeee;
}

.btn-light.btn-shadow {
  box-shadow: 0 0.125rem 0.625rem rgba(238, 238, 238, 0.4), 0 0.0625rem 0.125rem rgba(238, 238, 238, 0.5);
}

.btn-light.btn-shadow:hover {
  box-shadow: 0 0.125rem 0.625rem rgba(238, 238, 238, 0.5), 0 0.0625rem 0.125rem rgba(238, 238, 238, 0.6);
}

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark:hover {
  color: #fff;
  background-color: #2c3136;
  border-color: #2a2e33;
}

.btn-check:focus+.btn-dark,
.btn-dark:focus {
  color: #fff;
  background-color: #2c3136;
  border-color: #2a2e33;
  box-shadow: 0 0 0 0 rgba(82, 88, 93, 0.5);
}

.btn-check:checked+.btn-dark,
.btn-check:active+.btn-dark,
.btn-dark:active,
.btn-dark.active,
.show>.btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #2a2e33;
  border-color: #272c30;
}

.btn-check:checked+.btn-dark:focus,
.btn-check:active+.btn-dark:focus,
.btn-dark:active:focus,
.btn-dark.active:focus,
.show>.btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(82, 88, 93, 0.5);
}

.btn-dark:disabled,
.btn-dark.disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark.btn-shadow {
  box-shadow: 0 0.125rem 0.625rem rgba(52, 58, 64, 0.4), 0 0.0625rem 0.125rem rgba(52, 58, 64, 0.5);
}

.btn-dark.btn-shadow:hover {
  box-shadow: 0 0.125rem 0.625rem rgba(52, 58, 64, 0.5), 0 0.0625rem 0.125rem rgba(52, 58, 64, 0.6);
}

.btn-focus {
  color: #fff;
  background-color: #444054;
  border-color: #444054;
}

.btn-focus:hover {
  color: #fff;
  background-color: #3a3647;
  border-color: #363343;
}

.btn-check:focus+.btn-focus,
.btn-focus:focus {
  color: #fff;
  background-color: #3a3647;
  border-color: #363343;
  box-shadow: 0 0 0 0 rgba(96, 93, 110, 0.5);
}

.btn-check:checked+.btn-focus,
.btn-check:active+.btn-focus,
.btn-focus:active,
.btn-focus.active,
.show>.btn-focus.dropdown-toggle {
  color: #fff;
  background-color: #363343;
  border-color: #33303f;
}

.btn-check:checked+.btn-focus:focus,
.btn-check:active+.btn-focus:focus,
.btn-focus:active:focus,
.btn-focus.active:focus,
.show>.btn-focus.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(96, 93, 110, 0.5);
}

.btn-focus:disabled,
.btn-focus.disabled {
  color: #fff;
  background-color: #444054;
  border-color: #444054;
}

.btn-focus.btn-shadow {
  box-shadow: 0 0.125rem 0.625rem rgba(68, 64, 84, 0.4), 0 0.0625rem 0.125rem rgba(68, 64, 84, 0.5);
}

.btn-focus.btn-shadow:hover {
  box-shadow: 0 0.125rem 0.625rem rgba(68, 64, 84, 0.5), 0 0.0625rem 0.125rem rgba(68, 64, 84, 0.6);
}

.btn-alternate {
  color: #fff;
  background-color: #83588a;
  border-color: #83588a;
}

.btn-alternate:hover {
  color: #fff;
  background-color: #6f4b75;
  border-color: #69466e;
}

.btn-check:focus+.btn-alternate,
.btn-alternate:focus {
  color: #fff;
  background-color: #6f4b75;
  border-color: #69466e;
  box-shadow: 0 0 0 0 rgba(150, 113, 156, 0.5);
}

.btn-check:checked+.btn-alternate,
.btn-check:active+.btn-alternate,
.btn-alternate:active,
.btn-alternate.active,
.show>.btn-alternate.dropdown-toggle {
  color: #fff;
  background-color: #69466e;
  border-color: #624268;
}

.btn-check:checked+.btn-alternate:focus,
.btn-check:active+.btn-alternate:focus,
.btn-alternate:active:focus,
.btn-alternate.active:focus,
.show>.btn-alternate.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(150, 113, 156, 0.5);
}

.btn-alternate:disabled,
.btn-alternate.disabled {
  color: #fff;
  background-color: #83588a;
  border-color: #83588a;
}

.btn-alternate.btn-shadow {
  box-shadow: 0 0.125rem 0.625rem rgba(131, 88, 138, 0.4), 0 0.0625rem 0.125rem rgba(131, 88, 138, 0.5);
}

.btn-alternate.btn-shadow:hover {
  box-shadow: 0 0.125rem 0.625rem rgba(131, 88, 138, 0.5), 0 0.0625rem 0.125rem rgba(131, 88, 138, 0.6);
}

.btn-shadow-primary:hover {
  box-shadow: 0 0.125rem 0.625rem rgba(84, 92, 216, 0.4), 0 0.0625rem 0.125rem rgba(84, 92, 216, 0.5);
}

.btn-shadow-secondary:hover {
  box-shadow: 0 0.125rem 0.625rem rgba(108, 117, 125, 0.4), 0 0.0625rem 0.125rem rgba(108, 117, 125, 0.5);
}

.btn-shadow-success:hover {
  box-shadow: 0 0.125rem 0.625rem rgba(58, 196, 125, 0.4), 0 0.0625rem 0.125rem rgba(58, 196, 125, 0.5);
}

.btn-shadow-info:hover {
  box-shadow: 0 0.125rem 0.625rem rgba(48, 177, 255, 0.4), 0 0.0625rem 0.125rem rgba(48, 177, 255, 0.5);
}

.btn-shadow-warning:hover {
  box-shadow: 0 0.125rem 0.625rem rgba(247, 185, 36, 0.4), 0 0.0625rem 0.125rem rgba(247, 185, 36, 0.5);
}

.btn-shadow-danger:hover {
  box-shadow: 0 0.125rem 0.625rem rgba(217, 37, 80, 0.4), 0 0.0625rem 0.125rem rgba(217, 37, 80, 0.5);
}

.btn-shadow-light:hover {
  box-shadow: 0 0.125rem 0.625rem rgba(238, 238, 238, 0.4), 0 0.0625rem 0.125rem rgba(238, 238, 238, 0.5);
}

.btn-shadow-dark:hover {
  box-shadow: 0 0.125rem 0.625rem rgba(52, 58, 64, 0.4), 0 0.0625rem 0.125rem rgba(52, 58, 64, 0.5);
}

.btn-shadow-focus:hover {
  box-shadow: 0 0.125rem 0.625rem rgba(68, 64, 84, 0.4), 0 0.0625rem 0.125rem rgba(68, 64, 84, 0.5);
}

.btn-shadow-alternate:hover {
  box-shadow: 0 0.125rem 0.625rem rgba(131, 88, 138, 0.4), 0 0.0625rem 0.125rem rgba(131, 88, 138, 0.5);
}

.btn-outline-primary {
  color: #545cd8;
  border-color: #545cd8;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #545cd8;
  border-color: #545cd8;
}

.btn-check:focus+.btn-outline-primary,
.btn-outline-primary:focus {
  box-shadow: 0 0 0 0 rgba(84, 92, 216, 0.5);
}

.btn-check:checked+.btn-outline-primary,
.btn-check:active+.btn-outline-primary,
.btn-outline-primary:active,
.btn-outline-primary.active,
.btn-outline-primary.dropdown-toggle.show {
  color: #fff;
  background-color: #545cd8;
  border-color: #545cd8;
}

.btn-check:checked+.btn-outline-primary:focus,
.btn-check:active+.btn-outline-primary:focus,
.btn-outline-primary:active:focus,
.btn-outline-primary.active:focus,
.btn-outline-primary.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0 rgba(84, 92, 216, 0.5);
}

.btn-outline-primary:disabled,
.btn-outline-primary.disabled {
  color: #545cd8;
  background-color: transparent;
}

.btn-outline-primary.btn-shadow {
  box-shadow: 0 0.125rem 0.625rem rgba(84, 92, 216, 0.4), 0 0.0625rem 0.125rem rgba(84, 92, 216, 0.5);
}

.btn-outline-primary.btn-shadow.active:hover,
.btn-outline-primary.btn-shadow.disabled:hover,
.btn-outline-primary.btn-shadow:active:hover,
.btn-outline-primary.btn-shadow:disabled:hover {
  box-shadow: 0 0.125rem 0.625rem rgba(84, 92, 216, 0.5), 0 0.0625rem 0.125rem rgba(84, 92, 216, 0.6);
}

.btn-outline-primary.btn-shadow:hover {
  box-shadow: 0px 5px 15px 2px rgba(84, 92, 216, 0.19);
}

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-check:focus+.btn-outline-secondary,
.btn-outline-secondary:focus {
  box-shadow: 0 0 0 0 rgba(108, 117, 125, 0.5);
}

.btn-check:checked+.btn-outline-secondary,
.btn-check:active+.btn-outline-secondary,
.btn-outline-secondary:active,
.btn-outline-secondary.active,
.btn-outline-secondary.dropdown-toggle.show {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-check:checked+.btn-outline-secondary:focus,
.btn-check:active+.btn-outline-secondary:focus,
.btn-outline-secondary:active:focus,
.btn-outline-secondary.active:focus,
.btn-outline-secondary.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0 rgba(108, 117, 125, 0.5);
}

.btn-outline-secondary:disabled,
.btn-outline-secondary.disabled {
  color: #6c757d;
  background-color: transparent;
}

.btn-outline-secondary.btn-shadow {
  box-shadow: 0 0.125rem 0.625rem rgba(108, 117, 125, 0.4), 0 0.0625rem 0.125rem rgba(108, 117, 125, 0.5);
}

.btn-outline-secondary.btn-shadow.active:hover,
.btn-outline-secondary.btn-shadow.disabled:hover,
.btn-outline-secondary.btn-shadow:active:hover,
.btn-outline-secondary.btn-shadow:disabled:hover {
  box-shadow: 0 0.125rem 0.625rem rgba(108, 117, 125, 0.5), 0 0.0625rem 0.125rem rgba(108, 117, 125, 0.6);
}

.btn-outline-secondary.btn-shadow:hover {
  box-shadow: 0px 5px 15px 2px rgba(108, 117, 125, 0.19);
}

.btn-outline-success {
  color: #3ac47d;
  border-color: #3ac47d;
}

.btn-outline-success:hover {
  color: #000;
  background-color: #3ac47d;
  border-color: #3ac47d;
}

.btn-check:focus+.btn-outline-success,
.btn-outline-success:focus {
  box-shadow: 0 0 0 0 rgba(58, 196, 125, 0.5);
}

.btn-check:checked+.btn-outline-success,
.btn-check:active+.btn-outline-success,
.btn-outline-success:active,
.btn-outline-success.active,
.btn-outline-success.dropdown-toggle.show {
  color: #000;
  background-color: #3ac47d;
  border-color: #3ac47d;
}

.btn-check:checked+.btn-outline-success:focus,
.btn-check:active+.btn-outline-success:focus,
.btn-outline-success:active:focus,
.btn-outline-success.active:focus,
.btn-outline-success.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0 rgba(58, 196, 125, 0.5);
}

.btn-outline-success:disabled,
.btn-outline-success.disabled {
  color: #3ac47d;
  background-color: transparent;
}

.btn-outline-success.btn-shadow {
  box-shadow: 0 0.125rem 0.625rem rgba(58, 196, 125, 0.4), 0 0.0625rem 0.125rem rgba(58, 196, 125, 0.5);
}

.btn-outline-success.btn-shadow.active:hover,
.btn-outline-success.btn-shadow.disabled:hover,
.btn-outline-success.btn-shadow:active:hover,
.btn-outline-success.btn-shadow:disabled:hover {
  box-shadow: 0 0.125rem 0.625rem rgba(58, 196, 125, 0.5), 0 0.0625rem 0.125rem rgba(58, 196, 125, 0.6);
}

.btn-outline-success.btn-shadow:hover {
  box-shadow: 0px 5px 15px 2px rgba(58, 196, 125, 0.19);
}

.btn-outline-info {
  color: #30b1ff;
  border-color: #30b1ff;
}

.btn-outline-info:hover {
  color: #000;
  background-color: #30b1ff;
  border-color: #30b1ff;
}

.btn-check:focus+.btn-outline-info,
.btn-outline-info:focus {
  box-shadow: 0 0 0 0 rgba(48, 177, 255, 0.5);
}

.btn-check:checked+.btn-outline-info,
.btn-check:active+.btn-outline-info,
.btn-outline-info:active,
.btn-outline-info.active,
.btn-outline-info.dropdown-toggle.show {
  color: #000;
  background-color: #30b1ff;
  border-color: #30b1ff;
}

.btn-check:checked+.btn-outline-info:focus,
.btn-check:active+.btn-outline-info:focus,
.btn-outline-info:active:focus,
.btn-outline-info.active:focus,
.btn-outline-info.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0 rgba(48, 177, 255, 0.5);
}

.btn-outline-info:disabled,
.btn-outline-info.disabled {
  color: #30b1ff;
  background-color: transparent;
}

.btn-outline-info.btn-shadow {
  box-shadow: 0 0.125rem 0.625rem rgba(48, 177, 255, 0.4), 0 0.0625rem 0.125rem rgba(48, 177, 255, 0.5);
}

.btn-outline-info.btn-shadow.active:hover,
.btn-outline-info.btn-shadow.disabled:hover,
.btn-outline-info.btn-shadow:active:hover,
.btn-outline-info.btn-shadow:disabled:hover {
  box-shadow: 0 0.125rem 0.625rem rgba(48, 177, 255, 0.5), 0 0.0625rem 0.125rem rgba(48, 177, 255, 0.6);
}

.btn-outline-info.btn-shadow:hover {
  box-shadow: 0px 5px 15px 2px rgba(48, 177, 255, 0.19);
}

.btn-outline-warning {
  color: #f7b924;
  border-color: #f7b924;
}

.btn-outline-warning:hover {
  color: #000;
  background-color: #f7b924;
  border-color: #f7b924;
}

.btn-check:focus+.btn-outline-warning,
.btn-outline-warning:focus {
  box-shadow: 0 0 0 0 rgba(247, 185, 36, 0.5);
}

.btn-check:checked+.btn-outline-warning,
.btn-check:active+.btn-outline-warning,
.btn-outline-warning:active,
.btn-outline-warning.active,
.btn-outline-warning.dropdown-toggle.show {
  color: #000;
  background-color: #f7b924;
  border-color: #f7b924;
}

.btn-check:checked+.btn-outline-warning:focus,
.btn-check:active+.btn-outline-warning:focus,
.btn-outline-warning:active:focus,
.btn-outline-warning.active:focus,
.btn-outline-warning.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0 rgba(247, 185, 36, 0.5);
}

.btn-outline-warning:disabled,
.btn-outline-warning.disabled {
  color: #f7b924;
  background-color: transparent;
}

.btn-outline-warning.btn-shadow {
  box-shadow: 0 0.125rem 0.625rem rgba(247, 185, 36, 0.4), 0 0.0625rem 0.125rem rgba(247, 185, 36, 0.5);
}

.btn-outline-warning.btn-shadow.active:hover,
.btn-outline-warning.btn-shadow.disabled:hover,
.btn-outline-warning.btn-shadow:active:hover,
.btn-outline-warning.btn-shadow:disabled:hover {
  box-shadow: 0 0.125rem 0.625rem rgba(247, 185, 36, 0.5), 0 0.0625rem 0.125rem rgba(247, 185, 36, 0.6);
}

.btn-outline-warning.btn-shadow:hover {
  box-shadow: 0px 5px 15px 2px rgba(247, 185, 36, 0.19);
}

.btn-outline-danger {
  color: #d92550;
  border-color: #d92550;
}

.btn-outline-danger:hover {
  color: #fff;
  background-color: #d92550;
  border-color: #d92550;
}

.btn-check:focus+.btn-outline-danger,
.btn-outline-danger:focus {
  box-shadow: 0 0 0 0 rgba(217, 37, 80, 0.5);
}

.btn-check:checked+.btn-outline-danger,
.btn-check:active+.btn-outline-danger,
.btn-outline-danger:active,
.btn-outline-danger.active,
.btn-outline-danger.dropdown-toggle.show {
  color: #fff;
  background-color: #d92550;
  border-color: #d92550;
}

.btn-check:checked+.btn-outline-danger:focus,
.btn-check:active+.btn-outline-danger:focus,
.btn-outline-danger:active:focus,
.btn-outline-danger.active:focus,
.btn-outline-danger.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0 rgba(217, 37, 80, 0.5);
}

.btn-outline-danger:disabled,
.btn-outline-danger.disabled {
  color: #d92550;
  background-color: transparent;
}

.btn-outline-danger.btn-shadow {
  box-shadow: 0 0.125rem 0.625rem rgba(217, 37, 80, 0.4), 0 0.0625rem 0.125rem rgba(217, 37, 80, 0.5);
}

.btn-outline-danger.btn-shadow.active:hover,
.btn-outline-danger.btn-shadow.disabled:hover,
.btn-outline-danger.btn-shadow:active:hover,
.btn-outline-danger.btn-shadow:disabled:hover {
  box-shadow: 0 0.125rem 0.625rem rgba(217, 37, 80, 0.5), 0 0.0625rem 0.125rem rgba(217, 37, 80, 0.6);
}

.btn-outline-danger.btn-shadow:hover {
  box-shadow: 0px 5px 15px 2px rgba(217, 37, 80, 0.19);
}

.btn-outline-light {
  color: #eeeeee;
  border-color: #eeeeee;
}

.btn-outline-light:hover {
  color: #000;
  background-color: #eeeeee;
  border-color: #eeeeee;
}

.btn-check:focus+.btn-outline-light,
.btn-outline-light:focus {
  box-shadow: 0 0 0 0 rgba(238, 238, 238, 0.5);
}

.btn-check:checked+.btn-outline-light,
.btn-check:active+.btn-outline-light,
.btn-outline-light:active,
.btn-outline-light.active,
.btn-outline-light.dropdown-toggle.show {
  color: #000;
  background-color: #eeeeee;
  border-color: #eeeeee;
}

.btn-check:checked+.btn-outline-light:focus,
.btn-check:active+.btn-outline-light:focus,
.btn-outline-light:active:focus,
.btn-outline-light.active:focus,
.btn-outline-light.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0 rgba(238, 238, 238, 0.5);
}

.btn-outline-light:disabled,
.btn-outline-light.disabled {
  color: #eeeeee;
  background-color: transparent;
}

.btn-outline-light.btn-shadow {
  box-shadow: 0 0.125rem 0.625rem rgba(238, 238, 238, 0.4), 0 0.0625rem 0.125rem rgba(238, 238, 238, 0.5);
}

.btn-outline-light.btn-shadow.active:hover,
.btn-outline-light.btn-shadow.disabled:hover,
.btn-outline-light.btn-shadow:active:hover,
.btn-outline-light.btn-shadow:disabled:hover {
  box-shadow: 0 0.125rem 0.625rem rgba(238, 238, 238, 0.5), 0 0.0625rem 0.125rem rgba(238, 238, 238, 0.6);
}

.btn-outline-light.btn-shadow:hover {
  box-shadow: 0px 5px 15px 2px rgba(238, 238, 238, 0.19);
}

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-check:focus+.btn-outline-dark,
.btn-outline-dark:focus {
  box-shadow: 0 0 0 0 rgba(52, 58, 64, 0.5);
}

.btn-check:checked+.btn-outline-dark,
.btn-check:active+.btn-outline-dark,
.btn-outline-dark:active,
.btn-outline-dark.active,
.btn-outline-dark.dropdown-toggle.show {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-check:checked+.btn-outline-dark:focus,
.btn-check:active+.btn-outline-dark:focus,
.btn-outline-dark:active:focus,
.btn-outline-dark.active:focus,
.btn-outline-dark.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0 rgba(52, 58, 64, 0.5);
}

.btn-outline-dark:disabled,
.btn-outline-dark.disabled {
  color: #343a40;
  background-color: transparent;
}

.btn-outline-dark.btn-shadow {
  box-shadow: 0 0.125rem 0.625rem rgba(52, 58, 64, 0.4), 0 0.0625rem 0.125rem rgba(52, 58, 64, 0.5);
}

.btn-outline-dark.btn-shadow.active:hover,
.btn-outline-dark.btn-shadow.disabled:hover,
.btn-outline-dark.btn-shadow:active:hover,
.btn-outline-dark.btn-shadow:disabled:hover {
  box-shadow: 0 0.125rem 0.625rem rgba(52, 58, 64, 0.5), 0 0.0625rem 0.125rem rgba(52, 58, 64, 0.6);
}

.btn-outline-dark.btn-shadow:hover {
  box-shadow: 0px 5px 15px 2px rgba(52, 58, 64, 0.19);
}

.btn-outline-focus {
  color: #444054;
  border-color: #444054;
}

.btn-outline-focus:hover {
  color: #fff;
  background-color: #444054;
  border-color: #444054;
}

.btn-check:focus+.btn-outline-focus,
.btn-outline-focus:focus {
  box-shadow: 0 0 0 0 rgba(68, 64, 84, 0.5);
}

.btn-check:checked+.btn-outline-focus,
.btn-check:active+.btn-outline-focus,
.btn-outline-focus:active,
.btn-outline-focus.active,
.btn-outline-focus.dropdown-toggle.show {
  color: #fff;
  background-color: #444054;
  border-color: #444054;
}

.btn-check:checked+.btn-outline-focus:focus,
.btn-check:active+.btn-outline-focus:focus,
.btn-outline-focus:active:focus,
.btn-outline-focus.active:focus,
.btn-outline-focus.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0 rgba(68, 64, 84, 0.5);
}

.btn-outline-focus:disabled,
.btn-outline-focus.disabled {
  color: #444054;
  background-color: transparent;
}

.btn-outline-focus.btn-shadow {
  box-shadow: 0 0.125rem 0.625rem rgba(68, 64, 84, 0.4), 0 0.0625rem 0.125rem rgba(68, 64, 84, 0.5);
}

.btn-outline-focus.btn-shadow.active:hover,
.btn-outline-focus.btn-shadow.disabled:hover,
.btn-outline-focus.btn-shadow:active:hover,
.btn-outline-focus.btn-shadow:disabled:hover {
  box-shadow: 0 0.125rem 0.625rem rgba(68, 64, 84, 0.5), 0 0.0625rem 0.125rem rgba(68, 64, 84, 0.6);
}

.btn-outline-focus.btn-shadow:hover {
  box-shadow: 0px 5px 15px 2px rgba(68, 64, 84, 0.19);
}

.btn-outline-alternate {
  color: #83588a;
  border-color: #83588a;
}

.btn-outline-alternate:hover {
  color: #fff;
  background-color: #83588a;
  border-color: #83588a;
}

.btn-check:focus+.btn-outline-alternate,
.btn-outline-alternate:focus {
  box-shadow: 0 0 0 0 rgba(131, 88, 138, 0.5);
}

.btn-check:checked+.btn-outline-alternate,
.btn-check:active+.btn-outline-alternate,
.btn-outline-alternate:active,
.btn-outline-alternate.active,
.btn-outline-alternate.dropdown-toggle.show {
  color: #fff;
  background-color: #83588a;
  border-color: #83588a;
}

.btn-check:checked+.btn-outline-alternate:focus,
.btn-check:active+.btn-outline-alternate:focus,
.btn-outline-alternate:active:focus,
.btn-outline-alternate.active:focus,
.btn-outline-alternate.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0 rgba(131, 88, 138, 0.5);
}

.btn-outline-alternate:disabled,
.btn-outline-alternate.disabled {
  color: #83588a;
  background-color: transparent;
}

.btn-outline-alternate.btn-shadow {
  box-shadow: 0 0.125rem 0.625rem rgba(131, 88, 138, 0.4), 0 0.0625rem 0.125rem rgba(131, 88, 138, 0.5);
}

.btn-outline-alternate.btn-shadow.active:hover,
.btn-outline-alternate.btn-shadow.disabled:hover,
.btn-outline-alternate.btn-shadow:active:hover,
.btn-outline-alternate.btn-shadow:disabled:hover {
  box-shadow: 0 0.125rem 0.625rem rgba(131, 88, 138, 0.5), 0 0.0625rem 0.125rem rgba(131, 88, 138, 0.6);
}

.btn-outline-alternate.btn-shadow:hover {
  box-shadow: 0px 5px 15px 2px rgba(131, 88, 138, 0.19);
}

.btn {
  position: relative;
  transition: color 0.15s, background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
}

@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.btn-light {
  border-color: gainsboro;
}

.btn-outline-light {
  color: #8f8f8f;
}

.dropdown-menu {
  box-shadow: 0 0.46875rem 2.1875rem rgba(8, 10, 37, 0.03), 0 0.9375rem 1.40625rem rgba(8, 10, 37, 0.03), 0 0.25rem 0.53125rem rgba(8, 10, 37, 0.05), 0 0.125rem 0.1875rem rgba(8, 10, 37, 0.03);
  font-size: 0.88rem;
  margin: 0.125rem;
}

.dropdown-menu.dropdown-menu-right {
  right: 0 !important;
}

.dropdown-menu .dropdown-header {
  text-transform: uppercase;
  font-size: 0.7333333333rem;
  color: #545cd8;
  font-weight: bold;
}

.dropdown-menu .dropdown-item {
  font-size: 0.88rem;
  /* display: flex; */
  text-align: center;
  align-items: center;
  transition: background-color 0.3s ease, color 0.3s ease;
  cursor: pointer;
  z-index: 6;
  position: relative;
}

.dropdown-menu .dropdown-item .dropdown-icon {
  font-size: 1rem;
  margin-right: 0.325rem;
  width: 30px;
  text-align: center;
  opacity: 0.3;
  margin-left: -10px;
}

.dropdown-menu .dropdown-item:hover .dropdown-icon {
  opacity: 0.7;
}

.dropdown-menu.dropdown-menu-shadow {
  box-shadow: 0 0.66875rem 2.3875rem rgba(8, 10, 37, 0.03), 0 1.1375rem 1.60625rem rgba(8, 10, 37, 0.03), 0 0.45rem 0.73125rem rgba(8, 10, 37, 0.05), 0 0.325rem 0.3875rem rgba(8, 10, 37, 0.03);
}

.dropdown-menu-rounded {
  border-radius: 10px;
  padding: 0.65rem;
}

.dropdown-menu-rounded .dropdown-item {
  border-radius: 30px;
}

.dropdown-menu-rounded .dropdown-divider {
  margin-left: -0.65rem;
  margin-right: -0.65rem;
}

.dropdown-menu-rounded .dropdown-menu-header {
  margin-left: -0.65rem;
  margin-right: -0.65rem;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.dropdown-menu-rounded .menu-header-image,
.dropdown-menu-rounded .dropdown-menu-header-inner {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.dropdown-menu-hover-link .dropdown-item:hover {
  background: none;
  color: #545cd8;
}

.dropdown-menu-hover-primary .dropdown-item:hover {
  background: #545cd8;
  color: #fff;
}

.dropdown-menu.dropdown-menu-lg {
  min-width: 22rem;
}

.dropdown-menu.dropdown-menu-xl {
  min-width: 25rem;
}

.dropdown-menu .dropdown-menu-header,
.dropdown-menu .menu-header-image,
.dropdown-menu .dropdown-menu-header-inner {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.dropdown-menu-header {
  color: #fff;
  margin-top: -0.65rem;
  margin-bottom: 0.65rem;
  position: relative;
  z-index: 6;
}

.dropdown-menu-header .dropdown-menu-header-inner {
  margin: -1px -1px 0;
  padding: 1.5rem 0.5rem;
  position: relative;
}

.dropdown-menu-header .menu-header-image {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 8;
  opacity: 0.25;
  filter: grayscale(80%);
  background-size: cover;
}

.dropdown-menu-header .menu-header-content {
  text-align: center;
  position: relative;
  z-index: 10;
}

.dropdown-menu-header .menu-header-content.text-start {
  padding-left: 0.5rem;
}

.dropdown-menu-header .menu-header-content.btn-pane-right {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  display: flex;
  align-content: center;
  align-items: center;
  text-align: left;
}

.dropdown-menu-header .menu-header-content.btn-pane-right .menu-header-btn-pane {
  margin: 0 0 0 auto;
}

.dropdown-menu-header .menu-header-content .menu-header-btn-pane {
  margin-top: 10px;
  margin-bottom: 3px;
}

.dropdown-menu-header+.grid-menu {
  margin-top: -0.65rem;
}

.menu-header-title {
  font-weight: 500;
  font-size: 1.1rem;
  margin: 0;
}

.menu-header-subtitle {
  font-size: var(--bs-font-sans-serif);
  margin: 5px 0 0;
  opacity: 0.8;
}

.dropdown-menu .grid-menu {
  margin-bottom: -0.65rem;
  padding: 1px;
}

.dropdown-menu .grid-menu [class*=col-] {
  padding: 0.65rem;
}

.dropdown-menu .grid-menu-xl {
  margin-bottom: -0.4814814815rem;
}

.dropdown-menu .grid-menu-xl [class*=col-] {
  padding: 0;
}

.dropdown-toggle::after {
  position: relative;
  top: 2px;
  opacity: 0.8;
  margin-left: 5px;
}

.dropdown-toggle-split::after {
  margin-left: 0;
}

.dropright .dropdown-toggle::after {
  top: 0;
}

.dropdown-toggle-split {
  border-left: rgba(255, 255, 255, 0.1) solid 2px;
}

.dropdown-menu::before,
.dropdown-menu::after {
  position: absolute;
  width: 14px;
  height: 14px;
  left: 5px;
  top: -5px;
  transform: rotate(45deg);
  border-radius: 4px;
  content: "";
  display: block;
  z-index: 5;
}

.dropdown-menu::before {
  background: #fff;
  z-index: 5;
}

.dropdown-menu::after {
  top: -6px;
  background: rgba(0, 0, 0, 0.15);
  z-index: 4;
}

.dropdown-menu[data-placement=top-start]::before,
.dropdown-menu[data-placement=top-start]::after {
  top: auto;
  bottom: -5px;
}

.dropdown-menu[data-placement=top-start]::after {
  bottom: -6px;
}

.dropdown-menu[data-placement=left-start]::before,
.dropdown-menu[data-placement=left-start]::after {
  left: auto;
  right: -5px;
  top: 5px;
}

.dropdown-menu[data-placement=left-start]::after {
  top: 5px;
  right: -6px;
}

.dropdown-menu[data-placement=right-start]::before,
.dropdown-menu[data-placement=right-start]::after {
  right: auto;
  left: -5px;
  top: 5px;
}

.dropdown-menu[data-placement=right-start]::after {
  top: 5px;
  left: -6px;
}

.dropdown-menu.dropdown-menu-right::before,
.dropdown-menu.dropdown-menu-right::after {
  left: auto;
  right: 6px;
}

.dropdown-menu.dropdown-menu-right::after {
  right: 6px;
}

.dropdown-menu.rm-pointers::before,
.dropdown-menu.rm-pointers::after {
  display: none;
}

.dropdown-mega-menu {
  width: 56rem;
  padding: 1rem;
}

.dropdown-mega-menu .nav-item.nav-item-header {
  text-transform: none;
  font-size: 0.88rem;
  padding-top: 0;
  font-weight: normal;
}

.dropdown-mega-menu .grid-menu {
  margin-bottom: 0;
}

.dropdown-mega-menu-sm {
  width: 40rem;
}

body .dropdown-menu.dropdown-menu-inline {
  border: 0;
  position: static !important;
  box-shadow: 0 0 0 transparent;
  background: transparent;
  border-radius: 0;
  display: inline-block;
  float: none;
  left: 0 !important;
  top: 0 !important;
  width: 100% !important;
  transform: translateY(0) !important;
}

body .dropdown-menu.dropdown-menu-inline::before,
body .dropdown-menu.dropdown-menu-inline::after {
  display: none;
}

.nav-item .nav-link {
  font-weight: normal;
}

.nav-link {
  display: flex;
  align-items: center;
  transition: background-color 0.3s ease, color 0.3s ease;
  cursor: pointer;
}

.nav-link .nav-link-icon {
  color: #545cd8;
  font-size: 1rem;
  width: 30px;
  text-align: center;
  opacity: 0.45;
  margin-left: -10px;
}

.nav-link:hover {
  color: #495057;
}

.nav-link:hover .nav-link-icon {
  opacity: 0.9;
  color: #545cd8;
}

.nav-link:disabled .nav-link-icon,
.nav-link.disabled .nav-link-icon {
  opacity: 0.3;
}

.nav-item.nav-item-header {
  text-transform: uppercase;
  font-size: 0.7333333333rem;
  color: #6c757d;
  font-weight: bold;
  padding: 0.5rem 1rem;
}

.nav-item.nav-item-btn {
  padding: 0.5rem 1rem;
}

.nav-item.nav-item-divider {
  margin: 0.5rem 0;
  height: 1px;
  overflow: hidden;
  background: #dee2e6;
}

.nav .badge {
  margin-left: 8px;
}

.nav-pills .nav-link.active,
.nav-pills .nav-link.active:hover {
  color: #fff;
}

.nav-pills .nav-link.active .nav-link-icon,
.nav-pills .nav-link.active:hover .nav-link-icon {
  color: #fff;
  opacity: 0.8;
}

.nav-pills .nav-link:hover {
  color: #495057 !important;
}

.grid-menu [class*=col-] {
  border-right: #dee2e6 solid 0;
  border-bottom: #dee2e6 solid 1px;
}

.grid-menu [class*=col-]:hover {
  z-index: 5;
}

.grid-menu [class*=col-]:nth-last-child(-n+2) {
  border-bottom-width: 0;
}

.grid-menu [class*=col-]:nth-child(1n) {
  border-right-width: 1px;
}

.grid-menu [class*=col-]:nth-child(2n) {
  border-right-width: 0;
}

.grid-menu [class*=col-]:nth-last-child(-n+1) {
  border-bottom-width: 0;
}

.grid-menu.grid-menu-3col [class*=col-]:nth-last-child(-n+3) {
  border-bottom-width: 0;
}

.grid-menu.grid-menu-3col [class*=col-]:nth-child(2n) {
  border-right-width: 1px;
}

.grid-menu.grid-menu-3col [class*=col-]:nth-child(3n) {
  border-right-width: 0;
}

.grid-menu .btn {
  display: block;
  border: 0;
  min-width: 100%;
}

.badge {
  font-weight: bold;
  text-transform: uppercase;
  padding: 5px 10px;
  min-width: 19px;
}

.badge-light {
  background: #fff;
}

.badge-dot {
  text-indent: -999em;
  padding: 0;
  width: 8px;
  height: 8px;
  border: transparent solid 1px;
  border-radius: 30px;
  min-width: 2px;
}

.badge-dot-lg {
  width: 10px;
  height: 10px;
}

.badge-dot-xl {
  width: 18px;
  height: 18px;
  position: relative;
}

.badge-dot-xl::before {
  content: "";
  width: 10px;
  height: 10px;
  border-radius: 0.25rem;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -5px 0 0 -5px;
  background: #fff;
}

.badge-dot-sm {
  width: 6px;
  height: 6px;
}

.btn .badge {
  margin-left: 8px;
}

.btn .badge-dot {
  position: absolute;
  border: #fff solid 2px;
  top: -5px;
  right: -5px;
  width: 11px;
  height: 11px;
}

.btn .badge-dot.badge-dot-lg {
  width: 14px;
  height: 14px;
}

.btn .badge-dot.badge-dot-sm {
  width: 8px;
  height: 8px;
  border-width: 1px;
}

.btn .badge-dot-inside {
  top: 10px;
  right: 10px;
}

.btn-sm .badge-dot-sm,
.btn-group-sm>.btn .badge-dot-sm {
  top: 1px;
  right: 4px;
}

.btn-sm .badge-dot,
.btn-group-sm>.btn .badge-dot {
  top: 0px;
  right: 2px;
}

.btn-sm .badge-dot-lg,
.btn-group-sm>.btn .badge-dot-lg {
  top: -3px;
  right: -2px;
}

.btn-sm .rounded-pill,
.btn-group-sm>.btn .rounded-pill {
  position: absolute;
  top: -4px;
  right: -4px;
}

a,
button,
.btn {
  outline: none !important;
}

.app-container {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  margin: 0;
}

.SidebarAnimation-appear {
  transform: translateX(-30px);
  opacity: 0;
}

.SidebarAnimation-appear.SidebarAnimation-appear-active {
  opacity: 1;
  transform: translateX(0);
  transition: all 0.4s linear;
}

.HeaderAnimation-appear {
  transform: translateY(-30px);
  opacity: 0;
}

.HeaderAnimation-appear.HeaderAnimation-appear-active {
  opacity: 1;
  transform: translateY(0);
  transition: all 0.4s linear;
}

.MainAnimation-appear {
  transform: translateY(-30px);
  opacity: 0;
}

.MainAnimation-appear.MainAnimation-appear-active {
  opacity: 1;
  transform: translateY(0);
  transition: all 0.4s linear;
}

.app-header {
  height: 60px;
  display: flex;
  align-items: center;
  align-content: center;
  position: relative;
  z-index: 10;
  transition: all 0.2s;
}

.app-header.header-shadow {
  box-shadow: 0 0.46875rem 2.1875rem rgba(8, 10, 37, 0.03), 0 0.9375rem 1.40625rem rgba(8, 10, 37, 0.03), 0 0.25rem 0.53125rem rgba(8, 10, 37, 0.05), 0 0.125rem 0.1875rem rgba(8, 10, 37, 0.03);
}

.app-header .app-header__content {
  display: flex;
  align-items: center;
  align-content: center;
  flex: 1;
  padding: 0 1.5rem;
  height: 60px;
}

.app-header .app-header__content .app-header-left {
  display: flex;
  align-items: center;
}

.app-header .app-header__content .app-header-right {
  align-items: center;
  display: flex;
  margin-left: auto;
}

.app-header .header-user-info>.widget-heading,
.app-header .header-user-info>.widget-subheading {
  white-space: nowrap;
}

.app-header .header-user-info>.widget-subheading {
  font-size: 0.8rem;
}

.app-header__logo {
  padding: 0 1.5rem;
  height: 60px;
  width: 280px;
  display: flex;
  align-items: center;
  transition: width 0.2s;
}

.app-header__logo .logo-src {
  height: 23px;
  width: 97px;
}

.app-header__menu,
.app-header__mobile-menu {
  display: none;
  padding: 0 1.5rem;
  height: 60px;
  align-items: center;
}

.fixed-header .app-header {
  position: fixed;
  width: 100%;
  top: 0;
}

.fixed-header .app-header .app-header__logo {
  visibility: visible;
}

.fixed-header .app-main {
  padding-top: 30px;
}

.fixed-header:not(.fixed-sidebar):not(.closed-sidebar) .app-sidebar .app-header__logo {
  visibility: hidden;
}

.header-dots {
  margin-left: auto;
  display: flex;
}

.header-dots>.dropdown {
  display: flex;
  align-content: center;
}

.header-dots .icon-wrapper-alt {
  margin: 0;
  height: 44px;
  width: 44px;
  text-align: center;
  overflow: visible;
}

.header-dots .icon-wrapper-alt .language-icon {
  border-radius: 30px;
  position: relative;
  z-index: 4;
  width: 28px;
  height: 28px;
  overflow: hidden;
  margin: 0 auto;
}

.header-dots .icon-wrapper-alt .language-icon img {
  position: relative;
  top: 50%;
  left: 50%;
  margin: -22px 0 0 -20px;
}

.header-dots .icon-wrapper-alt .icon-wrapper-bg {
  opacity: 0.1;
  transition: opacity 0.2s;
  border-radius: 40px;
}

.header-dots .icon-wrapper-alt svg {
  margin: 0 auto;
}

@-moz-document url-prefix() {
  .header-dots .icon-wrapper-alt svg {
    width: 50%;
  }
}

.header-dots .icon-wrapper-alt i {
  font-size: 1.3rem;
}

.header-dots .icon-wrapper-alt:hover {
  cursor: pointer;
}

.header-dots .icon-wrapper-alt:hover .icon-wrapper-bg {
  opacity: 0.2;
}

.header-dots .icon-wrapper-alt .badge-dot {
  top: 1px;
  right: 1px;
  border: 0;
}

.header-megamenu.nav>li>.nav-link {
  color: #6c757d;
  padding-left: 0.6666666667rem;
  padding-right: 0.6666666667rem;
}

.header-megamenu.nav>li>.nav-link .rounded-pill {
  padding: 5px 7px;
}

.header-megamenu.nav>li>.nav-link:hover {
  color: #343a40;
}

.header-megamenu.nav>li>.nav-link svg {
  margin-top: 1px;
}

.header-btn-lg {
  padding: 0 0 0 1.5rem;
  margin-left: 1.5rem;
  display: flex;
  align-items: center;
  position: relative;
}

.header-btn-lg::before {
  position: absolute;
  left: -1px;
  top: 50%;
  background: #dee2e6;
  width: 1px;
  height: 30px;
  margin-top: -15px;
  content: "";
}

.header-btn-lg .BurgerInner,
.header-btn-lg .BurgerInner::before,
.header-btn-lg .BurgerInner::after {
  background: rgba(255, 255, 255, .8) !important;
}

.app-header.header-text-light .app-header-left>.nav>li>.nav-link {
  color: rgba(255, 255, 255, 0.7);
}

.app-header.header-text-light .app-header-left>.nav>li>.nav-link .nav-link-icon {
  color: rgba(255, 255, 255, 0.8);
}

.app-header.header-text-light .app-header-left>.nav>li>.nav-link:hover {
  color: white;
}

.app-header.header-text-light .app-header-right .icon-wrapper-alt svg {
  fill: rgba(255, 255, 255, 0.7);
  transition: all 0.2s;
}

.app-header.header-text-light .app-header-right .icon-wrapper-alt .icon-wrapper-bg {
  background: rgba(255, 255, 255, 0.1) !important;
  transition: all 0.2s;
  opacity: 1;
}

.app-header.header-text-light .app-header-right .icon-wrapper-alt:hover svg {
  fill: rgba(255, 255, 255, 0.95);
}

.app-header.header-text-light .app-header-right .icon-wrapper-alt:hover .icon-wrapper-bg {
  background: rgba(255, 255, 255, 0.15) !important;
}

.app-header.header-text-light .app-header-right .icon-wrapper-alt .badge-dot {
  border-color: transparent;
}

.app-header.header-text-light .app-header-right>.header-btn-lg .widget-content-left .btn-group>.btn-link,
.app-header.header-text-light .app-header-right>.header-btn-lg .widget-heading,
.app-header.header-text-light .app-header-right>.header-btn-lg .widget-subheading {
  color: rgba(255, 255, 255, 0.8);
}

.app-header.header-text-light .app-header-right>.header-btn-lg .header-user-info>.btn-shadow {
  box-shadow: 0 0.125rem 0.625rem rgba(0, 0, 0, 0.1), 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.2);
}

.app-header.header-text-light .search-wrapper .input-holder .search-icon {
  background: rgba(0, 0, 0, 0.1);
}

.app-header.header-text-light .search-wrapper .input-holder .search-input::placeholder,
.app-header.header-text-light .search-wrapper .input-holder .search-input::-webkit-input-placeholder,
.app-header.header-text-light .search-wrapper .input-holder .search-input:-ms-input-placeholder,
.app-header.header-text-light .search-wrapper .input-holder .search-input:-moz-placeholder,
.app-header.header-text-light .search-wrapper .input-holder .search-input::-moz-placeholder {
  color: rgba(255, 255, 255, 0.5) !important;
}

.app-header.header-text-light .search-wrapper.active .input-holder {
  background: rgba(255, 255, 255, 0.1);
}

.app-header.header-text-light .search-wrapper.active .input-holder .search-input {
  color: rgba(255, 255, 255, 0.8);
}

.app-header.header-text-light .search-wrapper.active .input-holder .search-icon {
  background: rgba(255, 255, 255, 0.1);
}

.app-header.header-text-light .header-btn-lg::before {
  background: rgba(255, 255, 255, 0.2);
}

.app-header.header-text-light .header-btn-lg .BurgerInner,
.app-header.header-text-light .header-btn-lg .Burger.BurgerActive .BurgerInner,
.app-header.header-text-light .header-btn-lg .BurgerInner::before,
.app-header.header-text-light .header-btn-lg .BurgerInner::after,
.app-header.header-text-light .header__pane .BurgerInner,
.app-header.header-text-light .header__pane .Burger.BurgerActive .BurgerInner,
.app-header.header-text-light .header__pane .BurgerInner::before,
.app-header.header-text-light .header__pane .BurgerInner::after {
  background-color: rgba(255, 255, 255, 0.8) !important;
}

.app-header.header-text-light .search-wrapper .input-holder .search-icon span::after {
  border-color: rgba(255, 255, 255, 0.8);
}

.app-header.header-text-light .search-wrapper .btn-close::before,
.app-header.header-text-light .search-wrapper .btn-close::after,
.app-header.header-text-light .search-wrapper .input-holder .search-icon span::before {
  background: rgba(255, 255, 255, 0.8) !important;
}

.app-header.header-text-light .app-header__logo .logo-src {
  /* background: url("utils/images/logo.png"); */
}

.app-header.header-text-light .app-header__mobile-menu .BurgerInner,
.app-header.header-text-light .app-header__mobile-menu .BurgerInner::before,
.app-header.header-text-light .app-header__mobile-menu .BurgerInner::after {
  background: rgba(255, 255, 255, 0.9);
}

.app-header.header-text-dark .app-header-left>.nav>li>.nav-link {
  color: rgba(0, 0, 0, 0.7);
}

.app-header.header-text-dark .app-header-left>.nav>li>.nav-link .nav-link-icon {
  color: rgba(0, 0, 0, 0.8);
}

.app-header.header-text-dark .app-header-left>.nav>li>.nav-link:hover {
  color: black;
}

.app-header.header-text-dark .app-header-right .icon-wrapper-alt svg {
  fill: rgba(0, 0, 0, 0.7);
  transition: all 0.2s;
}

.app-header.header-text-dark .app-header-right .icon-wrapper-alt .icon-wrapper-bg {
  background: rgba(0, 0, 0, 0.1) !important;
  transition: all 0.2s;
  opacity: 1;
}

.app-header.header-text-dark .app-header-right .icon-wrapper-alt:hover svg {
  fill: rgba(0, 0, 0, 0.95);
}

.app-header.header-text-dark .app-header-right .icon-wrapper-alt:hover .icon-wrapper-bg {
  background: rgba(0, 0, 0, 0.15) !important;
}

.app-header.header-text-dark .app-header-right .icon-wrapper-alt .badge-dot {
  border-color: transparent;
}

.app-header.header-text-dark .app-header-right .widget-content-left>.btn-group>.btn-link,
.app-header.header-text-dark .app-header-right .widget-content-left .widget-heading,
.app-header.header-text-dark .app-header-right .widget-content-left .widget-subheading {
  color: rgba(0, 0, 0, 0.8);
}

.app-header.header-text-dark .search-wrapper .input-holder .search-icon {
  background: rgba(0, 0, 0, 0.1);
}

.app-header.header-text-dark .search-wrapper.active .input-holder {
  background: rgba(0, 0, 0, 0.1);
}

.app-header.header-text-dark .search-wrapper.active .input-holder .search-input {
  color: rgba(0, 0, 0, 0.8);
}

.app-header.header-text-dark .search-wrapper.active .input-holder .search-icon {
  background: rgba(0, 0, 0, 0.1);
}

.app-header.header-text-dark .header-btn-lg::before {
  background: rgba(0, 0, 0, 0.2);
}

.app-header.header-text-dark .header-btn-lg .BurgerInner,
.app-header.header-text-dark .header-btn-lg .Burger.is-active .BurgerInner,
.app-header.header-text-dark .header-btn-lg .BurgerInner::before,
.app-header.header-text-dark .header-btn-lg .BurgerInner::after,
.app-header.header-text-dark .header__pane .BurgerInner,
.app-header.header-text-dark .header__pane .Burger.is-active .BurgerInner,
.app-header.header-text-dark .header__pane .BurgerInner::before,
.app-header.header-text-dark .header__pane .BurgerInner::after {
  background-color: rgba(0, 0, 0, 0.8) !important;
}

.app-header.header-text-dark .search-wrapper .input-holder .search-icon span::after {
  border-color: rgba(0, 0, 0, 0.8);
}

.app-header.header-text-dark .search-wrapper .btn-close::before,
.app-header.header-text-dark .search-wrapper .btn-close::after,
.app-header.header-text-dark .search-wrapper .input-holder .search-icon span::before {
  background: rgba(0, 0, 0, 0.8);
}

.app-header.header-text-dark .app-header__logo .logo-src {
  /* background: url("utils/images/logo-inverse.png"); */
}

.app-sidebar {
  width: 280px;
  display: flex;
  z-index: 11;
  overflow: hidden;
  min-width: 280px;
  position: relative;
  flex: 0 0 280px;
  margin-top: -60px;
  padding-top: 60px;
  transition: all 0.2s;
}

.app-sidebar .app-sidebar__inner {
  padding: 2px 1.5rem 1.5rem;
}

.app-sidebar .scrollbar-container {
  z-index: 15;
  width: 100%;
}

.app-sidebar .app-sidebar-bg {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  opacity: 0.05;
  background-size: cover;
  z-index: 10;
}

.app-sidebar .app-header__logo {
  position: absolute;
  left: 0;
  top: 0;
  display: none;
  z-index: 11;
}

.app-sidebar.sidebar-shadow {
  box-shadow: 7px 0 60px rgba(0, 0, 0, 0.05);
}

.app-sidebar__heading {
  text-transform: uppercase;
  font-size: 0.8rem;
  margin: 0.75rem 0;
  font-weight: bold;
  color: #545cd8;
  white-space: nowrap;
  position: relative;
}

.sidebar-mobile-overlay {
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  background: #333;
  opacity: 0.6;
  left: 0;
  top: 0;
  z-index: 12;
}

.vertical-nav-menu {
  position: relative;
}

.vertical-nav-menu::after {
  content: " ";
  pointer-events: none;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
}

.vertical-nav-menu .metismenu-container,
.vertical-nav-menu .metismenu-item {
  margin: 0;
  padding: 0;
  position: relative;
}

.vertical-nav-menu .metismenu-container {
  list-style: none;
}

.vertical-nav-menu .metismenu-container:before {
  opacity: 0;
  transition: opacity 300ms;
}

.vertical-nav-menu .metismenu-container .metismenu-link {
  transition: transform 0.2s, height 300ms, color 300ms, background-color 300ms;
  display: block;
  line-height: 2.5rem;
  height: 2.5rem;
  padding: 0 1.5rem 0 45px;
  position: relative;
  border-radius: 0.25rem;
  color: #343a40;
  white-space: nowrap;
}

.vertical-nav-menu .metismenu-container .metismenu-link:hover {
  background: #e0f3ff;
  text-decoration: none;
}

.vertical-nav-menu .metismenu-container .metismenu-link:hover i.metismenu-icon {
  opacity: 0.6;
}

.vertical-nav-menu .metismenu-container .metismenu-link:hover i.metismenu-state-icon {
  opacity: 1;
}

.vertical-nav-menu .metismenu-container .metismenu-link.active {
  color: #545cd8;
  background: #e0f3ff;
}

.vertical-nav-menu .metismenu-container .metismenu-link.has-active-child,
.vertical-nav-menu .metismenu-container .metismenu-link.active {
  font-weight: bold;
}

.vertical-nav-menu .metismenu-container i.metismenu-state-icon,
.vertical-nav-menu .metismenu-container i.metismenu-icon {
  text-align: center;
  width: 34px;
  height: 34px;
  line-height: 34px;
  position: absolute;
  left: 5px;
  top: 50%;
  margin-top: -17px;
  font-size: 1.5rem;
  opacity: 0.3;
  transition: color 300ms;
}

.vertical-nav-menu .metismenu-container i.metismenu-state-icon {
  transition: transform 300ms;
  left: auto;
  right: 0;
}

.vertical-nav-menu .metismenu-container i.metismenu-state-icon.rotate-minus-90 {
  transform: rotate(-180deg);
}

.vertical-nav-menu .metismenu-container .metismenu-container {
  transition: padding 300ms;
}

.vertical-nav-menu .metismenu-container .metismenu-container.visible {
  padding: 0.5em 0 0 2rem;
}

.vertical-nav-menu .metismenu-container .metismenu-container.visible:before {
  content: "";
  height: 100%;
  opacity: 1;
  width: 3px;
  background: #e0f3ff;
  position: absolute;
  left: 20px;
  top: 0;
  border-radius: 15px;
}

.vertical-nav-menu .metismenu-container .metismenu-container.visible>.metismenu-item>.metismenu-link {
  height: 2.3em;
  line-height: 2.3em;
  color: #6c757d;
  margin: 0 0 3px;
}

.vertical-nav-menu .metismenu-container .metismenu-container.visible>.metismenu-item>.metismenu-link:hover {
  color: #545cd8;
}

.vertical-nav-menu .metismenu-container .metismenu-container.visible>.metismenu-item>.metismenu-link .metismenu-icon {
  display: none;
}

.vertical-nav-menu .metismenu-container .metismenu-container.visible>.metismenu-item>.metismenu-link.active {
  color: #545cd8;
  background: #e0f3ff;
}

.vertical-nav-menu .metismenu-container .metismenu-container .metismenu-item>.metismenu-link {
  height: 0;
  overflow: hidden;
}

.vertical-nav-menu .metismenu-container .metismenu-container .metismenu-link {
  padding-left: 1em;
}

.app-sidebar.sidebar-text-light {
  border-right: 0 !important;
}

.app-sidebar.sidebar-text-light .app-sidebar__heading {
  color: rgba(255, 255, 255, 0.6);
}

.app-sidebar.sidebar-text-light .app-sidebar__heading::before {
  background: rgba(255, 255, 255, 0.2) !important;
}

.app-sidebar.sidebar-text-light .metismenu .metismenu-container .metismenu-link {
  color: rgba(255, 255, 255, 0.7);
}

.app-sidebar.sidebar-text-light .metismenu .metismenu-container .metismenu-link:hover {
  color: white;
  background: rgba(255, 255, 255, 0.1);
}

.app-sidebar.sidebar-text-light .metismenu .metismenu-container .metismenu-link.active {
  background: rgba(255, 255, 255, 0.1);
}

.app-sidebar.sidebar-text-light .metismenu .metismenu-container.metismenu-container.visible .metismenu-item>.metismenu-link {
  color: rgba(255, 255, 255, 0.6);
}

.app-sidebar.sidebar-text-light .metismenu .metismenu-container.metismenu-container.visible .metismenu-item>.metismenu-link.active {
  background: rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 0.8);
}

.app-sidebar.sidebar-text-light .metismenu .metismenu-container.metismenu-container.visible .metismenu-item>.metismenu-link:hover {
  color: white;
}

.app-sidebar.sidebar-text-light .metismenu .metismenu-container.metismenu-container.visible::before {
  background: rgba(255, 255, 255, 0.3);
}

.app-sidebar.sidebar-text-light .metismenu .metismenu-container i.metismenu-state-icon,
.app-sidebar.sidebar-text-light .metismenu .metismenu-container i.metismenu-icon {
  opacity: 0.5;
}

.app-sidebar.sidebar-text-light .ps__thumb-y {
  background: rgba(255, 255, 255, 0.3);
}

.app-sidebar.sidebar-text-light .ps__rail-y:hover .ps__thumb-y {
  background: rgba(255, 255, 255, 0.2);
}

.app-sidebar.sidebar-text-light .app-header__logo .logo-src {
  /* background: url("utils/images/logo.png"); */
}

.app-sidebar.sidebar-text-light .app-header__logo .BurgerInner,
.app-sidebar.sidebar-text-light .app-header__logo .BurgerInner::before,
.app-sidebar.sidebar-text-light .app-header__logo .BurgerInner::after {
  background-color: rgba(255, 255, 255, 0.8);
}

.app-sidebar.sidebar-text-dark {
  border-right: 0 !important;
}

.app-sidebar.sidebar-text-dark .app-sidebar__heading {
  color: rgba(0, 0, 0, 0.6);
}

.app-sidebar.sidebar-text-dark .app-sidebar__heading::before {
  background: rgba(0, 0, 0, 0.5) !important;
}

.app-sidebar.sidebar-text-dark .metismenu .metismenu-container .metismenu-link {
  color: rgba(0, 0, 0, 0.7);
}

.app-sidebar.sidebar-text-dark .metismenu .metismenu-container .metismenu-link:hover {
  color: black;
  background: rgba(0, 0, 0, 0.1);
}

.app-sidebar.sidebar-text-dark .metismenu .metismenu-container .metismenu-link.active {
  background: rgba(0, 0, 0, 0.1);
}

.app-sidebar.sidebar-text-dark .metismenu .metismenu-container.metismenu-container.visible .metismenu-item>.metismenu-link {
  color: rgba(0, 0, 0, 0.6);
}

.app-sidebar.sidebar-text-dark .metismenu .metismenu-container.metismenu-container.visible .metismenu-item>.metismenu-link.active {
  background: rgba(0, 0, 0, 0.1);
}

.app-sidebar.sidebar-text-dark .metismenu .metismenu-container.metismenu-container.visible::before {
  background: rgba(0, 0, 0, 0.3);
}

.app-sidebar.sidebar-text-dark .metismenu .metismenu-container.metismenu-container i.metismenu-state-icon,
.app-sidebar.sidebar-text-dark .metismenu .metismenu-container.metismenu-container i.metismenu-icon {
  opacity: 0.5;
}

.app-sidebar.sidebar-text-dark .ps__thumb-y {
  background: rgba(0, 0, 0, 0.3);
}

.app-sidebar.sidebar-text-dark .ps__rail-y:hover .ps__thumb-y {
  background: rgba(0, 0, 0, 0.2);
}

.app-sidebar.sidebar-text-dark .app-header__logo .BurgerInner,
.app-sidebar.sidebar-text-dark .app-header__logo .BurgerInner::before,
.app-sidebar.sidebar-text-dark .app-header__logo .BurgerInner::after {
  background-color: rgba(0, 0, 0, 0.8);
}

.fixed-sidebar .app-sidebar {
  position: fixed;
  height: 100vh;
}

.fixed-sidebar .app-main .app-main__outer {
  z-index: 9;
  /* padding-left: 280px; */
}

.fixed-sidebar.fixed-header .app-sidebar .app-header__logo {
  display: none;
}

.fixed-sidebar:not(.fixed-header) .app-sidebar .app-header__logo {
  display: flex;
}

.fixed-sidebar:not(.fixed-header) .app-header {
  margin-left: 280px;
}

.fixed-sidebar:not(.fixed-header) .app-header .app-header__logo {
  display: none;
}

.fixed-sidebar.closed-sidebar:not(.fixed-header) .app-header {
  margin-left: 80px;
}

.fixed-sidebar.closed-sidebar:not(.fixed-header) .app-sidebar .app-header__logo {
  width: 80px;
  padding: 0;
}

.fixed-sidebar.closed-sidebar:not(.fixed-header) .app-sidebar .app-header__logo .logo-src {
  display: none;
}

.fixed-sidebar.closed-sidebar:not(.fixed-header) .app-sidebar .app-header__logo .header__pane {
  margin-right: auto;
}

.closed-sidebar .app-sidebar {
  transition: all 0.3s ease;
  width: 80px;
  min-width: 80px;
  flex: 0 0 80px;
  z-index: 13;
}

.closed-sidebar .app-sidebar .app-sidebar__inner .app-sidebar__heading {
  text-indent: -999em;
}

.closed-sidebar .app-sidebar .app-sidebar__inner .app-sidebar__heading::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 1px;
  background: #e0f3ff;
  text-indent: 1px;
}

.closed-sidebar .app-sidebar .app-sidebar__inner .metismenu-link {
  text-indent: -99rem;
  padding: 0;
}

.closed-sidebar .app-sidebar .app-sidebar__inner .metismenu-icon {
  text-indent: 0;
  left: 50%;
  margin-left: -17px;
}

.closed-sidebar .app-sidebar .app-sidebar__inner .metismenu-state-icon {
  visibility: hidden;
}

.closed-sidebar .app-sidebar .app-sidebar__inner .metismenu-container.visible {
  padding: 0;
}

.closed-sidebar .app-sidebar .app-sidebar__inner .metismenu-container.visible>.metismenu-item>.metismenu-link {
  height: 0;
}

.closed-sidebar .app-sidebar:hover {
  flex: 0 0 280px !important;
  width: 280px !important;
}

.closed-sidebar .app-sidebar:hover .app-sidebar__inner .app-sidebar__heading {
  text-indent: initial;
}

.closed-sidebar .app-sidebar:hover .app-sidebar__inner .app-sidebar__heading::before {
  display: none;
}

.closed-sidebar .app-sidebar:hover .app-sidebar__inner .metismenu-link {
  text-indent: initial;
  padding: 0 1.5rem 0 45px;
}

.closed-sidebar .app-sidebar:hover .app-sidebar__inner .metismenu-icon {
  text-indent: initial;
  left: 5px;
  margin-left: 0;
}

.closed-sidebar .app-sidebar:hover .app-sidebar__inner .metismenu-state-icon {
  visibility: visible;
}

.closed-sidebar .app-sidebar:hover .app-sidebar__inner .metismenu-container.visible {
  padding: 0.5em 0 0 2rem;
}

.closed-sidebar .app-sidebar:hover .app-sidebar__inner .metismenu-container.visible>.metismenu-item>.metismenu-link {
  height: 2.3em;
}

.closed-sidebar .app-sidebar:hover .app-sidebar__inner .metismenu-container .metismenu-container .metismenu-link {
  padding-left: 1em;
}

.closed-sidebar:not(.sidebar-mobile-open) .app-sidebar .scrollbar-container {
  position: static;
  height: auto;
  overflow: initial !important;
}

.closed-sidebar:not(.sidebar-mobile-open) .app-sidebar:hover .scrollbar-container {
  position: absolute;
  height: 100%;
  overflow: hidden !important;
}

.closed-sidebar:not(.closed-sidebar-mobile) .app-header .app-header__logo {
  width: 80px;
}

.closed-sidebar:not(.closed-sidebar-mobile) .app-header .app-header__logo .logo-src {
  display: none;
}

.closed-sidebar:not(.closed-sidebar-mobile) .app-header .app-header__logo .header__pane {
  margin-right: auto;
}

.closed-sidebar.fixed-sidebar .app-main__outer {
  padding-left: 80px;
}

.closed-sidebar.fixed-header:not(.fixed-sidebar) .app-sidebar .app-header__logo {
  visibility: hidden;
}

.closed-sidebar.closed-sidebar-mobile .app-sidebar .app-header__logo,
.closed-sidebar.closed-sidebar-mobile .app-header .app-header__logo {
  width: auto;
  display: flex;
}

.closed-sidebar.closed-sidebar-mobile .app-sidebar .app-header__logo .header__pane,
.closed-sidebar.closed-sidebar-mobile .app-header .app-header__logo .header__pane {
  display: none;
}

.closed-sidebar.closed-sidebar-mobile .app-sidebar .app-header__logo {
  display: flex;
  width: 80px;
  padding: 0 1.5rem !important;
}

.closed-sidebar.closed-sidebar-mobile .app-sidebar .app-header__logo .logo-src {
  display: block !important;
  margin: 0 auto;
  width: 21px;
}

.closed-sidebar.closed-sidebar-mobile .app-sidebar .app-header__logo .header__pane {
  display: none;
}

.closed-sidebar.closed-sidebar-mobile .app-sidebar:hover .app-header__logo {
  width: 280px;
}

.closed-sidebar.closed-sidebar-mobile .app-sidebar:hover .app-header__logo .logo-src {
  width: 97px;
  margin: 0;
}

.closed-sidebar.closed-sidebar-mobile .app-header {
  margin-left: 0 !important;
}

.closed-sidebar.fixed-footer .app-footer__inner {
  margin-left: 0 !important;
}

.app-main {
  z-index: 8;
  position: relative;
  display: flex;
}

.app-main .app-main__outer {
  flex: 1;
  flex-direction: column;
  display: flex;
  z-index: 12;
  width: 1px;
}

.app-main .app-main__inner {
  padding: 1.5rem 1.5rem 0;
  flex: 1;
}

.app-main .app-main__inner>.RRT__container>.RRT__panel {
  margin-top: 1.5rem;
}

.app-main .app-main__inner>.RRT__container>.body-tabs-layout .RRT__tab {
  margin-top: 0.2rem;
  color: #005800;
  font-weight: 600;
}

.body-tabs-shadow-btn .app-main__inner>.RRT__container>.RRT__panel {
  margin-top: 1rem;
}

.body-tabs-shadow-btn .app-main__inner>.RRT__container .body-tabs-layout {
  border: 0;
  background: transparent;
  position: relative;
  z-index: 7;
}

.body-tabs-shadow-btn .app-main__inner>.RRT__container .body-tabs-layout .RRT__showmore {
  margin-top: -13px;
}

.body-tabs-shadow-btn .app-main__inner>.RRT__container .body-tabs-layout .RRT__tab {
  margin-top: 0.5rem;
}

.body-tabs-shadow-btn .app-main__inner>.RRT__container .body-tabs-layout .RRT__tab--selected {
  color: #fff;
}

.table {
  --bs-table-bg: transparent;
  --bs-table-accent-bg: rgba(0, 0, 0, 0.03);
  --bs-table-striped-color: #495057;
  --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
  --bs-table-active-color: #495057;
  --bs-table-active-bg: #e0f3ff;
  --bs-table-hover-color: #495057;
  --bs-table-hover-bg: #e0f3ff;
  width: 100%;
  margin-bottom: 1rem;
  color: #495057;
  vertical-align: top;
  border-color: #e9ecef;
}

.table> :not(caption)>*>* {
  padding: 0.5rem 0.5rem;
  background-color: var(--bs-table-bg);
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}

.table>tbody {
  vertical-align: inherit;
}

.table>thead {
  vertical-align: bottom;
}

.table> :not(:last-child)> :last-child>* {
  border-bottom-color: currentColor;
}

.caption-top {
  caption-side: top;
}

.table-sm> :not(caption)>*>* {
  padding: 0.25rem 0.25rem;
}

.table-bordered> :not(caption)>* {
  border-width: 1px 0;
}

.table-bordered> :not(caption)>*>* {
  border-width: 0 1px;
}

.table-borderless> :not(caption)>*>* {
  border-bottom-width: 0;
}

.table-striped>tbody>tr:nth-of-type(odd) {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
}

.table-active {
  --bs-table-accent-bg: var(--bs-table-active-bg);
  color: var(--bs-table-active-color);
}

.table-hover>tbody>tr:hover {
  --bs-table-accent-bg: var(--bs-table-hover-bg);
  color: var(--bs-table-hover-color);
}

.table-primary {
  --bs-table-bg: #dddef7;
  --bs-table-striped-bg: #d2d3eb;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #c7c8de;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #cccde4;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #c7c8de;
}

.table-secondary {
  --bs-table-bg: #e2e3e5;
  --bs-table-striped-bg: #d7d8da;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #cbccce;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #d1d2d4;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #cbccce;
}

.table-success {
  --bs-table-bg: #d8f3e5;
  --bs-table-striped-bg: #cde7da;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #c2dbce;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c8e1d4;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #c2dbce;
}

.table-info {
  --bs-table-bg: #d6efff;
  --bs-table-striped-bg: #cbe3f2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #c1d7e6;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c6ddec;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #c1d7e6;
}

.table-warning {
  --bs-table-bg: #fdf1d3;
  --bs-table-striped-bg: #f0e5c8;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e4d9be;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #eadfc3;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #e4d9be;
}

.table-danger {
  --bs-table-bg: #f7d3dc;
  --bs-table-striped-bg: #ebc8d1;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #debec6;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e4c3cc;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #debec6;
}

.table-light {
  --bs-table-bg: #eeeeee;
  --bs-table-striped-bg: #e2e2e2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #d6d6d6;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: gainsboro;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #d6d6d6;
}

.table-dark {
  --bs-table-bg: #343a40;
  --bs-table-striped-bg: #3e444a;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #484e53;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #43494e;
  --bs-table-hover-color: #fff;
  color: #fff;
  border-color: #484e53;
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

.form-label {
  margin-bottom: 0.5rem;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  /* font-size: inherit; */
  line-height: 1.5;
  color: #000;
  font-size: 14px;
  font-weight: 600;
}

label.form-check-label {
  color: #000;
  font-weight: 600;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.1rem;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.968rem;
}

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #6c757d;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 0.88rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #005800;
  appearance: none;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

.form-control[type=file] {
  overflow: hidden;
}

.form-control[type=file]:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #bcbff0;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(84, 92, 216, 0.25);
}

.form-control::-webkit-date-and-time-value {
  height: 1.5em;
}

.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

.form-control::file-selector-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  margin-inline-end: 0.75rem;
  color: #495057;
  background-color: #eff9ff;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  transition: color 0.15s, background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
}

@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none;
  }
}

.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: #e3edf2;
}

.form-control::-webkit-file-upload-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  margin-inline-end: 0.75rem;
  color: #495057;
  background-color: #eff9ff;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  transition: color 0.15s, background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
}

@media (prefers-reduced-motion: reduce) {
  .form-control::-webkit-file-upload-button {
    transition: none;
  }
}

.form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
  background-color: #e3edf2;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: #495057;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}

.form-control-plaintext.form-control-sm,
.form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: calc(1.5em + (0.5rem + 2px));
  padding: 0.25rem 0.5rem;
  font-size: 0.968rem;
  border-radius: 0.2rem;
}

.form-control-sm::file-selector-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  margin-inline-end: 0.5rem;
}

.form-control-sm::-webkit-file-upload-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  margin-inline-end: 0.5rem;
}

.form-control-lg {
  min-height: calc(1.5em + (1rem + 2px));
  padding: 0.5rem 1rem;
  font-size: 1.1rem;
  border-radius: 0.3rem;
}

.form-control-lg::file-selector-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  margin-inline-end: 1rem;
}

.form-control-lg::-webkit-file-upload-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  margin-inline-end: 1rem;
}

textarea.form-control {
  min-height: calc(1.5em + (0.75rem + 2px));
}

textarea.form-control-sm {
  min-height: calc(1.5em + (0.5rem + 2px));
}

textarea.form-control-lg {
  min-height: calc(1.5em + (1rem + 2px));
}

.form-control-color {
  max-width: 3rem;
  height: auto;
  padding: 0.375rem;
}

.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.form-control-color::-moz-color-swatch {
  height: 1.5em;
  border-radius: 0.25rem;
}

.form-control-color::-webkit-color-swatch {
  height: 1.5em;
  border-radius: 0.25rem;
}

.form-select {
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 0.88rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}

.form-select:focus {
  border-color: #bcbff0;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(84, 92, 216, 0.25);
}

.form-select[multiple],
.form-select[size]:not([size="1"]) {
  padding-right: 0.75rem;
  background-image: none;
}

.form-select:disabled {
  background-color: #e9ecef;
}

.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}

.form-select-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.968rem;
}

.form-select-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.1rem;
}

.form-check {
  display: block;
  min-height: 1.32rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;
}

.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.form-check-input {
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0.25);
  appearance: none;
  color-adjust: exact;
}

.form-check-input[type=checkbox] {
  border-radius: 0.25em;
}

.form-check-input[type=radio] {
  border-radius: 50%;
}

.form-check-input:active {
  filter: brightness(90%);
}

.form-check-input:focus {
  border-color: #bcbff0;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(84, 92, 216, 0.25);
}

.form-check-input:checked {
  background-color: #545cd8;
  border-color: #545cd8;
}

.form-check-input:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}

.form-check-input:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}

.form-check-input[type=checkbox]:indeterminate {
  background-color: #545cd8;
  border-color: #545cd8;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}

.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}

.form-check-input[disabled]~.form-check-label,
.form-check-input:disabled~.form-check-label {
  opacity: 0.5;
}

.form-switch {
  padding-left: 2.5em;
}
.App {
  font-family: 'Ubuntu', sans-serif;
}

/* @charset "UTF-8"; */

.border-primary {
  border-color: #545cd8;
}

.border-secondary {
  border-color: #6c757d;
}

.border-success {
  border-color: #3ac47d;
}

.border-info {
  border-color: #30b1ff;
}

.border-warning {
  border-color: #f7b924;
}

.border-danger {
  border-color: #d92550;
}

.border-light {
  border-color: #eeeeee;
}

.border-dark {
  border-color: #343a40;
}

.border-focus {
  border-color: #444054;
}

.border-alternate {
  border-color: #83588a;
}

:root {
  --bs-blue: #86c6fb;
  --bs-indigo: #444054;
  --bs-purple: #83588a;
  --bs-pink: #f65ca2;
  --bs-red: #d92550;
  --bs-orange: #fd7e14;
  --bs-yellow: #f7b924;
  --bs-green: #3ac47d;
  --bs-teal: #16E0BD;
  --bs-cyan: #30b1ff;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-primary: #545cd8;
  --bs-secondary: #6c757d;
  --bs-success: #3ac47d;
  --bs-info: #30b1ff;
  --bs-warning: #f7b924;
  --bs-danger: #d92550;
  --bs-light: #eeeeee;
  --bs-dark: #343a40;
  --bs-focus: #444054;
  --bs-alternate: #83588a;
  --bs-font-sans-serif: "Calibri";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: var(--bs-font-sans-serif);
  font-size: 0.88rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #ffffff;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

hr {
  margin: 1rem 0;
  color: inherit;
  background-color: currentColor;
  border: 0;
  opacity: 0.25;
}

hr:not([size]) {
  height: 1px;
}

h6,
.h6,
h5,
.h5,
h4,
.h4,
h3,
.h3,
h2,
.h2,
h1,
.h1 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 400;
  line-height: 1.2;
}

h1,
.h1 {
  font-size: calc(1.345rem + 1.14vw);
}

@media (min-width: 1200px) {

  h1,
  .h1 {
    font-size: 2.2rem;
  }
}

h2,
.h2 {
  font-size: calc(1.301rem + 0.612vw);
}

@media (min-width: 1200px) {

  h2,
  .h2 {
    font-size: 1.76rem;
  }
}

h3,
.h3 {
  font-size: calc(1.279rem + 0.348vw);
}

@media (min-width: 1200px) {

  h3,
  .h3 {
    font-size: 1.54rem;
  }
}

h4,
.h4 {
  font-size: calc(1.257rem + 0.084vw);
}

@media (min-width: 1200px) {

  h4,
  .h4 {
    font-size: 1.32rem;
  }
}

h5,
.h5 {
  font-size: 1.1rem;
}

h6,
.h6 {
  font-size: 0.88rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-bs-original-title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small,
.small {
  font-size: 0.875em;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #545cd8;
  text-decoration: underline;
}

a:hover {
  color: #434aad;
}

a:not([href]):not([class]),
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
  direction: ltr
    /* rtl:ignore */
  ;
  unicode-bidi: bidi-override;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: #f65ca2;
  word-wrap: break-word;
}

a>code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 0.875em;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
}

kbd kbd {
  padding: 0;
  font-size: 1em;
  font-weight: 700;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #6c757d;
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

select:disabled {
  opacity: 1;
}

[list]::-webkit-calendar-picker-indicator {
  display: none;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}

@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}

legend+* {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: 1.1rem;
  font-weight: 300;
}

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 0.875em;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.1rem;
}

.blockquote> :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #6c757d;
}

.blockquote-footer::before {
  content: "  ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 0.875em;
  color: #6c757d;
}

.container,
.body-tabs.body-tabs-layout.body-tabs-big+.RRT__inkbar-wrapper,
.body-tabs.body-tabs-layout.body-tabs-big,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {

  .container-sm,
  .container,
  .body-tabs.body-tabs-layout.body-tabs-big+.RRT__inkbar-wrapper,
  .body-tabs.body-tabs-layout.body-tabs-big {
    max-width: 540px;
  }
}

@media (min-width: 768px) {

  .container-md,
  .container-sm,
  .container,
  .body-tabs.body-tabs-layout.body-tabs-big+.RRT__inkbar-wrapper,
  .body-tabs.body-tabs-layout.body-tabs-big {
    max-width: 720px;
  }
}

@media (min-width: 992px) {

  .container-lg,
  .container-md,
  .container-sm,
  .container,
  .body-tabs.body-tabs-layout.body-tabs-big+.RRT__inkbar-wrapper,
  .body-tabs.body-tabs-layout.body-tabs-big {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {

  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container,
  .body-tabs.body-tabs-layout.body-tabs-big+.RRT__inkbar-wrapper,
  .body-tabs.body-tabs-layout.body-tabs-big {
    max-width: 1140px;
  }
}

@media (min-width: 1400px) {

  .container-xxl,
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container,
  .body-tabs.body-tabs-layout.body-tabs-big+.RRT__inkbar-wrapper,
  .body-tabs.body-tabs-layout.body-tabs-big {
    max-width: 1320px;
  }
}

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(var(--bs-gutter-y) * -1);
  margin-right: calc(var(--bs-gutter-x) * -0.5);
  margin-left: calc(var(--bs-gutter-x) * -0.5);
}

.row>* {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto>* {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1>* {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2>* {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3>* {
  flex: 0 0 auto;
  width: 33.3333333333%;
}

.row-cols-4>* {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5>* {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6>* {
  flex: 0 0 auto;
  width: 16.6666666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }

  .row-cols-sm-auto>* {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-sm-1>* {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-sm-2>* {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-sm-3>* {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-sm-4>* {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-sm-5>* {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-sm-6>* {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
}

@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }

  .row-cols-md-auto>* {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-md-1>* {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-md-2>* {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-md-3>* {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-md-4>* {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-md-5>* {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-md-6>* {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
}

@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }

  .row-cols-lg-auto>* {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-lg-1>* {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-lg-2>* {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-lg-3>* {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-lg-4>* {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-lg-5>* {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-lg-6>* {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }

  .row-cols-xl-auto>* {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-xl-1>* {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-xl-2>* {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-xl-3>* {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-xl-4>* {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-xl-5>* {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-xl-6>* {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
}

@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%;
  }

  .row-cols-xxl-auto>* {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-xxl-1>* {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-xxl-2>* {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-xxl-3>* {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-xxl-4>* {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-xxl-5>* {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-xxl-6>* {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 3rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 3rem;
}

@media (min-width: 576px) {
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.33333333%;
  }

  .offset-sm-2 {
    margin-left: 16.66666667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.33333333%;
  }

  .offset-sm-5 {
    margin-left: 41.66666667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.33333333%;
  }

  .offset-sm-8 {
    margin-left: 66.66666667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.33333333%;
  }

  .offset-sm-11 {
    margin-left: 91.66666667%;
  }

  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0;
  }

  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0;
  }

  .g-sm-1,
  .gx-sm-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-sm-1,
  .gy-sm-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-sm-2,
  .gx-sm-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-sm-2,
  .gy-sm-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-sm-3,
  .gx-sm-3 {
    --bs-gutter-x: 1rem;
  }

  .g-sm-3,
  .gy-sm-3 {
    --bs-gutter-y: 1rem;
  }

  .g-sm-4,
  .gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-sm-4,
  .gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 3rem;
  }

  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 768px) {
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.33333333%;
  }

  .offset-md-2 {
    margin-left: 16.66666667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.33333333%;
  }

  .offset-md-5 {
    margin-left: 41.66666667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.33333333%;
  }

  .offset-md-8 {
    margin-left: 66.66666667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.33333333%;
  }

  .offset-md-11 {
    margin-left: 91.66666667%;
  }

  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0;
  }

  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0;
  }

  .g-md-1,
  .gx-md-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-md-1,
  .gy-md-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: 1rem;
  }

  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: 1rem;
  }

  .g-md-4,
  .gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-md-4,
  .gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 3rem;
  }

  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 992px) {
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.33333333%;
  }

  .offset-lg-2 {
    margin-left: 16.66666667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.33333333%;
  }

  .offset-lg-5 {
    margin-left: 41.66666667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.33333333%;
  }

  .offset-lg-8 {
    margin-left: 66.66666667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.33333333%;
  }

  .offset-lg-11 {
    margin-left: 91.66666667%;
  }

  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0;
  }

  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0;
  }

  .g-lg-1,
  .gx-lg-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-lg-1,
  .gy-lg-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 1rem;
  }

  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 1rem;
  }

  .g-lg-4,
  .gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-lg-4,
  .gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 3rem;
  }

  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 1200px) {
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.33333333%;
  }

  .offset-xl-2 {
    margin-left: 16.66666667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.33333333%;
  }

  .offset-xl-5 {
    margin-left: 41.66666667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.33333333%;
  }

  .offset-xl-8 {
    margin-left: 66.66666667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.33333333%;
  }

  .offset-xl-11 {
    margin-left: 91.66666667%;
  }

  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0;
  }

  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0;
  }

  .g-xl-1,
  .gx-xl-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-xl-1,
  .gy-xl-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-xl-2,
  .gx-xl-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-xl-2,
  .gy-xl-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-xl-3,
  .gx-xl-3 {
    --bs-gutter-x: 1rem;
  }

  .g-xl-3,
  .gy-xl-3 {
    --bs-gutter-y: 1rem;
  }

  .g-xl-4,
  .gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-xl-4,
  .gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 3rem;
  }

  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 1400px) {
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-xxl-0 {
    margin-left: 0;
  }

  .offset-xxl-1 {
    margin-left: 8.33333333%;
  }

  .offset-xxl-2 {
    margin-left: 16.66666667%;
  }

  .offset-xxl-3 {
    margin-left: 25%;
  }

  .offset-xxl-4 {
    margin-left: 33.33333333%;
  }

  .offset-xxl-5 {
    margin-left: 41.66666667%;
  }

  .offset-xxl-6 {
    margin-left: 50%;
  }

  .offset-xxl-7 {
    margin-left: 58.33333333%;
  }

  .offset-xxl-8 {
    margin-left: 66.66666667%;
  }

  .offset-xxl-9 {
    margin-left: 75%;
  }

  .offset-xxl-10 {
    margin-left: 83.33333333%;
  }

  .offset-xxl-11 {
    margin-left: 91.66666667%;
  }

  .g-xxl-0,
  .gx-xxl-0 {
    --bs-gutter-x: 0;
  }

  .g-xxl-0,
  .gy-xxl-0 {
    --bs-gutter-y: 0;
  }

  .g-xxl-1,
  .gx-xxl-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-xxl-1,
  .gy-xxl-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-xxl-2,
  .gx-xxl-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-xxl-2,
  .gy-xxl-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-xxl-3,
  .gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }

  .g-xxl-3,
  .gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }

  .g-xxl-4,
  .gx-xxl-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-xxl-4,
  .gy-xxl-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-xxl-5,
  .gx-xxl-5 {
    --bs-gutter-x: 3rem;
  }

  .g-xxl-5,
  .gy-xxl-5 {
    --bs-gutter-y: 3rem;
  }
}
/* Write you own custom component styles here */
button.btn {
    border-radius: 5px !important;
}
.bg-yellow{
  background-color: #f0b62e !important;
}

.bg-green{
  background-color: #70c939 !important;
}
.bg-red{
  background-color: #f2373a !important;
}
.bg-orange{
    background-color: #ed8936 !important;;
}
.btn.btn-pending {
  width: 200px !important;
  box-shadow: inset 0px 20px 6px rgba(255, 191, 0, 0.1607843137);
  background: rgba(255, 0, 0, 0.2196078431) !important;
  border: 1px solid #FF0000 !important;
  border-radius: 17px;
  opacity: 0.63;
  color: black;
}
button.btn.btn-danger.btn.btn-secondary {
  background-color: #f2373a !important;
  border: #f2373a !important;
}
.react-datepicker-wrapper {
  display: flex !important;
  padding: 0;
  border: 0;
}
.btn.btn-primary5 {
  background-color: #548235 !important;
}
.btn.btn-yellow{
  background-color: #f0b62e !important;
}
button.btn.bg-green.m-2.btn.btn-secondary {
  font-size: 0.95rem !important;
  padding: 10px;
}
.white-space{
  white-space: pre-line;  
}
.field-br {
  white-space: normal
}