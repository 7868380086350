// Layout Styles

@import "./dashboard_menu_style/index";
@import "./menu_style/index";
// #profile-pills-tab.nav-pills .nav-link {
//     color: #000;
//     background-color: transparent; 
//     padding: 10px 30px;
//     margin: 0px 20px;
// }
// #profile-pills-tab.nav-pills .nav-link.active {
//     color: var(--bs-white);
//     background: transparent;
//     color: #fff !important;
//     border-bottom: 45px solid #e01d8d;
//     border-left: 25px solid transparent;
//     border-right: 25px solid transparent; 
// }
#profile-pills-tab.nav-pills .nav-link {
    color: #000;
    background-color: #d9d9d9;
    padding: 10px 30px;
    margin: 0px 20px;
    width: 250px;
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
}
@media only screen and (max-width: 767px) {
#profile-pills-tab.nav-pills .nav-link {
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
}
}
@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (-webkit-min-device-pixel-ratio: 1) {
    #profile-pills-tab.nav-pills .nav-link {
        border-top-left-radius: 0px !important;
        border-top-right-radius: 0px !important;
    }
}
.iq-timeline0 li .timeline-dots {
    top: 0px;
    border:1px solid #434243 !important;
    background: linear-gradient(to bottom, #d62497 0%,#f22183 100%) !important;
    height: 35px !important;
    width: 35px !important;
    left: 2px !important;
    color: #fff !important;
}
.iq-timeline0:before{
    background-color: #000;
    width: 1px;
}